import { Bootstrap } from "made-this-ui";
import { AppProps } from "next/app";
import Head from "next/head";
import { queryClient } from "../lib/graphql";
import ejsingholmTheme from "../lib/theme";
function HomeApp({
  Component,
  pageProps
}: AppProps) {
  return <Bootstrap theme={ejsingholmTheme} queryClient={queryClient} data-sentry-element="Bootstrap" data-sentry-component="HomeApp" data-sentry-source-file="HomeApp.tsx">
      <Head data-sentry-element="Head" data-sentry-source-file="HomeApp.tsx">
        <title>Ejsingholm</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width, viewport-fit=cover" data-sentry-element="meta" data-sentry-source-file="HomeApp.tsx" />
      </Head>
      <Component {...pageProps} data-sentry-element="Component" data-sentry-source-file="HomeApp.tsx" />
    </Bootstrap>;
}
export default HomeApp;