import { HStack, StackProps, useTheme } from "@chakra-ui/react";
import { Kbd } from "admin/components/common/Kbd";
import { useAdminTranslation } from "admin/lib/useAdminTranslation";
export interface KbdNavigationProps extends StackProps {}
export const KbdNavigation = (props: KbdNavigationProps) => {
  const {
    t
  } = useAdminTranslation();
  const {
    icons: {
      IconArrowUp,
      IconArrowDown,
      IconEnterKey
    }
  } = useTheme();
  return <HStack spacing={1} justify="flex-end" {...props} data-sentry-element="HStack" data-sentry-component="KbdNavigation" data-sentry-source-file="KbdNavigation.tsx">
      <Kbd label={t("concepts.navigate")} data-sentry-element="Kbd" data-sentry-source-file="KbdNavigation.tsx">
        <IconArrowUp fontSize="xs" data-sentry-element="IconArrowUp" data-sentry-source-file="KbdNavigation.tsx" />
        <IconArrowDown fontSize="xs" data-sentry-element="IconArrowDown" data-sentry-source-file="KbdNavigation.tsx" />
      </Kbd>
      <Kbd label={t("concepts.open")} data-sentry-element="Kbd" data-sentry-source-file="KbdNavigation.tsx">
        <IconEnterKey fontSize="xs" data-sentry-element="IconEnterKey" data-sentry-source-file="KbdNavigation.tsx" />
      </Kbd>
      <Kbd label={t("concepts.close")} data-sentry-element="Kbd" data-sentry-source-file="KbdNavigation.tsx">Esc</Kbd>
    </HStack>;
};