import { Modal, ModalCloseButton, ModalContent, ModalOverlay } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { createContext, ReactNode, useContext, useMemo, useState } from "react";
import { Iframe } from "./Iframe";
export type IframeModalManagerContextValue = {
  open: (url: string) => void;
  close: () => void;
};
export const IframeModalManagerContext = createContext<IframeModalManagerContextValue>(null!);
export const useIframeModalManager = () => {
  return useContext(IframeModalManagerContext);
};
export const IframeModalManagerProvider = ({
  children
}: {
  children?: ReactNode;
}) => {
  const [url, setUrl] = useState<string | null>(null);
  const queryClient = useQueryClient();
  const value = useMemo(() => {
    return {
      open: (url: string) => {
        setUrl(url);
      },
      close: () => {
        queryClient.invalidateQueries();
        setUrl(null);
      }
    };
  }, [queryClient]);
  return <IframeModalManagerContext.Provider value={value} data-sentry-element="unknown" data-sentry-component="IframeModalManagerProvider" data-sentry-source-file="IframeModalManager.tsx">
      {url ? <IframeModal url={url} onClose={() => {
      queryClient.invalidateQueries();
      setUrl(null);
    }} /> : null}
      {children}
    </IframeModalManagerContext.Provider>;
};
const IframeModal = ({
  url,
  onClose
}: {
  url: string;
  onClose: () => void;
}) => {
  return <Modal isOpen={true} onClose={onClose} size="iframe" data-sentry-element="Modal" data-sentry-component="IframeModal" data-sentry-source-file="IframeModalManager.tsx">
      <ModalOverlay data-sentry-element="ModalOverlay" data-sentry-source-file="IframeModalManager.tsx" />
      <ModalContent data-sentry-element="ModalContent" data-sentry-source-file="IframeModalManager.tsx">
        <ModalCloseButton data-sentry-element="ModalCloseButton" data-sentry-source-file="IframeModalManager.tsx" />
        <Iframe src={url} mt="0" borderRadius="md" data-sentry-element="Iframe" data-sentry-source-file="IframeModalManager.tsx" />
      </ModalContent>
    </Modal>;
};