/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** An ISO 8601-encoded date */
  ISO8601Date: { input: any; output: any };
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: { input: any; output: any };
  /** Represents untyped JSON */
  JSON: { input: any; output: any };
};

export type Apartment = RecordInterface & {
  __typename?: "Apartment";
  activeServiceCasesCount: Scalars["Int"]["output"];
  address: Scalars["String"]["output"];
  archived?: Maybe<Scalars["Boolean"]["output"]>;
  cases: CaseList;
  city: Scalars["String"]["output"];
  createdAt: Scalars["ISO8601DateTime"]["output"];
  currentTenancy?: Maybe<Tenancy>;
  documents?: Maybe<Array<Document>>;
  id: Scalars["ID"]["output"];
  inactive?: Maybe<Scalars["Boolean"]["output"]>;
  inactiveDescription?: Maybe<Scalars["String"]["output"]>;
  inactiveEndDate?: Maybe<Scalars["String"]["output"]>;
  inactiveStartDate?: Maybe<Scalars["String"]["output"]>;
  key?: Maybe<Scalars["String"]["output"]>;
  messages: MessageList;
  nextTenancy?: Maybe<Tenancy>;
  postalCode: Scalars["String"]["output"];
  previousTenancy?: Maybe<Tenancy>;
  property: Property;
  publicId: Scalars["String"]["output"];
  tenancies: TenancyList;
  tenancy?: Maybe<Tenancy>;
  updatedAt: Scalars["ISO8601DateTime"]["output"];
};

export type ApartmentCasesArgs = {
  orderBy?: InputMaybe<CaseOrderByEnum>;
  where?: InputMaybe<CaseWhereInput>;
};

export type ApartmentMessagesArgs = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<MessageOrderByEnum>;
  where?: InputMaybe<MessageWhereInput>;
};

export type ApartmentTenancyArgs = {
  id: Scalars["ID"]["input"];
};

export type ApartmentList = {
  __typename?: "ApartmentList";
  count: Scalars["Int"]["output"];
  records: Array<Apartment>;
  total: Scalars["Int"]["output"];
};

export enum ApartmentOrderByEnum {
  AddressAsc = "address_ASC",
  AddressDesc = "address_DESC",
  CityAsc = "city_ASC",
  CityDesc = "city_DESC",
  CurrentTenantCprAsc = "currentTenantCpr_ASC",
  CurrentTenantCprDesc = "currentTenantCpr_DESC",
  CurrentTenantDepositAmountAsc = "currentTenantDepositAmount_ASC",
  CurrentTenantDepositAmountDesc = "currentTenantDepositAmount_DESC",
  CurrentTenantDepositDueDateAsc = "currentTenantDepositDueDate_ASC",
  CurrentTenantDepositDueDateDesc = "currentTenantDepositDueDate_DESC",
  CurrentTenantEmailAsc = "currentTenantEmail_ASC",
  CurrentTenantEmailDesc = "currentTenantEmail_DESC",
  CurrentTenantEndDateAsc = "currentTenantEndDate_ASC",
  CurrentTenantEndDateDesc = "currentTenantEndDate_DESC",
  CurrentTenantNameAsc = "currentTenantName_ASC",
  CurrentTenantNameDesc = "currentTenantName_DESC",
  CurrentTenantPhoneAsc = "currentTenantPhone_ASC",
  CurrentTenantPhoneDesc = "currentTenantPhone_DESC",
  CurrentTenantRequestedEndDateAsc = "currentTenantRequestedEndDate_ASC",
  CurrentTenantRequestedEndDateDesc = "currentTenantRequestedEndDate_DESC",
  CurrentTenantStartDateAsc = "currentTenantStartDate_ASC",
  CurrentTenantStartDateDesc = "currentTenantStartDate_DESC",
  KeyAsc = "key_ASC",
  KeyDesc = "key_DESC",
  NextTenantCprAsc = "nextTenantCpr_ASC",
  NextTenantCprDesc = "nextTenantCpr_DESC",
  NextTenantDepositAmountAsc = "nextTenantDepositAmount_ASC",
  NextTenantDepositAmountDesc = "nextTenantDepositAmount_DESC",
  NextTenantDepositDueDateAsc = "nextTenantDepositDueDate_ASC",
  NextTenantDepositDueDateDesc = "nextTenantDepositDueDate_DESC",
  NextTenantEmailAsc = "nextTenantEmail_ASC",
  NextTenantEmailDesc = "nextTenantEmail_DESC",
  NextTenantEndDateAsc = "nextTenantEndDate_ASC",
  NextTenantEndDateDesc = "nextTenantEndDate_DESC",
  NextTenantNameAsc = "nextTenantName_ASC",
  NextTenantNameDesc = "nextTenantName_DESC",
  NextTenantPhoneAsc = "nextTenantPhone_ASC",
  NextTenantPhoneDesc = "nextTenantPhone_DESC",
  NextTenantRequestedEndDateAsc = "nextTenantRequestedEndDate_ASC",
  NextTenantRequestedEndDateDesc = "nextTenantRequestedEndDate_DESC",
  NextTenantStartDateAsc = "nextTenantStartDate_ASC",
  NextTenantStartDateDesc = "nextTenantStartDate_DESC",
  OwnerAsc = "owner_ASC",
  OwnerDesc = "owner_DESC",
  PreviousTenantCprAsc = "previousTenantCpr_ASC",
  PreviousTenantCprDesc = "previousTenantCpr_DESC",
  PreviousTenantDepositAmountAsc = "previousTenantDepositAmount_ASC",
  PreviousTenantDepositAmountDesc = "previousTenantDepositAmount_DESC",
  PreviousTenantDepositDueDateAsc = "previousTenantDepositDueDate_ASC",
  PreviousTenantDepositDueDateDesc = "previousTenantDepositDueDate_DESC",
  PreviousTenantEmailAsc = "previousTenantEmail_ASC",
  PreviousTenantEmailDesc = "previousTenantEmail_DESC",
  PreviousTenantEndDateAsc = "previousTenantEndDate_ASC",
  PreviousTenantEndDateDesc = "previousTenantEndDate_DESC",
  PreviousTenantNameAsc = "previousTenantName_ASC",
  PreviousTenantNameDesc = "previousTenantName_DESC",
  PreviousTenantPhoneAsc = "previousTenantPhone_ASC",
  PreviousTenantPhoneDesc = "previousTenantPhone_DESC",
  PreviousTenantRequestedEndDateAsc = "previousTenantRequestedEndDate_ASC",
  PreviousTenantRequestedEndDateDesc = "previousTenantRequestedEndDate_DESC",
  PreviousTenantStartDateAsc = "previousTenantStartDate_ASC",
  PreviousTenantStartDateDesc = "previousTenantStartDate_DESC",
  PropertyAsc = "property_ASC",
  PropertyDesc = "property_DESC",
  PublicIdAsc = "publicId_ASC",
  PublicIdDesc = "publicId_DESC",
}

export type ApartmentWhereInput = {
  activeCases?: InputMaybe<Scalars["Boolean"]["input"]>;
  archived?: InputMaybe<Scalars["Boolean"]["input"]>;
  byOwner?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  byProperty?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  search?: InputMaybe<Scalars["String"]["input"]>;
};

export type BankAccount = {
  __typename?: "BankAccount";
  account?: Maybe<Scalars["String"]["output"]>;
  bankName?: Maybe<Scalars["String"]["output"]>;
  reg?: Maybe<Scalars["String"]["output"]>;
};

export type Case = RecordInterface & {
  __typename?: "Case";
  apartment?: Maybe<Apartment>;
  attention: CaseAttentionEnum;
  awaiting?: Maybe<Scalars["String"]["output"]>;
  calendarDate?: Maybe<Scalars["String"]["output"]>;
  calendarRange: DateRange;
  children: Array<Case>;
  company?: Maybe<Company>;
  completeBefore?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["ISO8601DateTime"]["output"];
  icon: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  inspection?: Maybe<Inspection>;
  name: Scalars["String"]["output"];
  parent?: Maybe<Case>;
  property?: Maybe<Property>;
  requester?: Maybe<User>;
  responsible?: Maybe<User>;
  scheduledDate?: Maybe<Scalars["String"]["output"]>;
  scheduledTime?: Maybe<Scalars["String"]["output"]>;
  shortName: Scalars["String"]["output"];
  startAfter?: Maybe<Scalars["String"]["output"]>;
  state: Scalars["String"]["output"];
  stateLastChangedAt?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  stateText: Scalars["String"]["output"];
  tenancy?: Maybe<Tenancy>;
  tenantWillAttend?: Maybe<Scalars["Boolean"]["output"]>;
  type: CaseTypeEnum;
  updatedAt: Scalars["ISO8601DateTime"]["output"];
};

export type CaseAttachment = {
  __typename?: "CaseAttachment";
  createdAt: Scalars["ISO8601DateTime"]["output"];
  fileName: Scalars["String"]["output"];
  fileSize: Scalars["Int"]["output"];
  fileType: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  isImage: Scalars["Boolean"]["output"];
  isPdf: Scalars["Boolean"]["output"];
  largeUrl: Scalars["String"]["output"];
  originalUrl: Scalars["String"]["output"];
  thumbUrl: Scalars["String"]["output"];
};

export enum CaseAttentionEnum {
  Critical = "critical",
  Important = "important",
  Normal = "normal",
  Success = "success",
}

export type CaseComment = {
  __typename?: "CaseComment";
  assets: Array<File>;
  createdAt: Scalars["ISO8601DateTime"]["output"];
  from: User;
  id: Scalars["ID"]["output"];
  message: Scalars["String"]["output"];
  readAt?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
};

export type CaseDefinition = {
  __typename?: "CaseDefinition";
  cases: CaseList;
  icon: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  states: Array<Scalars["String"]["output"]>;
  type: CaseTypeEnum;
};

export type CaseDefinitionCasesArgs = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<CaseOrderByEnum>;
  where?: InputMaybe<CaseWhereInput>;
};

export type CaseInterface = {
  apartment?: Maybe<Apartment>;
  attachments: Array<CaseAttachment>;
  attention: CaseAttentionEnum;
  awaiting?: Maybe<Scalars["String"]["output"]>;
  body: Scalars["String"]["output"];
  calendarDate?: Maybe<Scalars["String"]["output"]>;
  calendarRange: DateRange;
  comments: Array<CaseComment>;
  company?: Maybe<Company>;
  completeBefore?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["ISO8601DateTime"]["output"];
  icon: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  inspection?: Maybe<Inspection>;
  isArchived: Scalars["Boolean"]["output"];
  isAttending?: Maybe<Scalars["Boolean"]["output"]>;
  isService: Scalars["Boolean"]["output"];
  mayAddComment: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  parent?: Maybe<CaseInterface>;
  property?: Maybe<Property>;
  requester?: Maybe<User>;
  responsible?: Maybe<User>;
  scheduledDate?: Maybe<Scalars["String"]["output"]>;
  scheduledTime?: Maybe<Scalars["String"]["output"]>;
  serviceDate?: Maybe<Scalars["ISO8601Date"]["output"]>;
  serviceEmployee?: Maybe<Scalars["String"]["output"]>;
  serviceTime?: Maybe<Scalars["String"]["output"]>;
  shortName: Scalars["String"]["output"];
  startAfter?: Maybe<Scalars["String"]["output"]>;
  state: Scalars["String"]["output"];
  stateLastChangedAt?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  stateText: Scalars["String"]["output"];
  tenancy?: Maybe<Tenancy>;
  tenantWillAttend?: Maybe<Scalars["Boolean"]["output"]>;
  title: Scalars["String"]["output"];
  totalCommentsCount: Scalars["Int"]["output"];
  type: CaseTypeEnum;
  unreadCommentsCount: Scalars["Int"]["output"];
  updatedAt: Scalars["ISO8601DateTime"]["output"];
};

export type CaseList = {
  __typename?: "CaseList";
  count: Scalars["Int"]["output"];
  records: Array<CaseInterface>;
  total: Scalars["Int"]["output"];
};

export enum CaseOrderByEnum {
  ApartmentPublicIdAsc = "apartmentPublicId_ASC",
  ApartmentPublicIdDesc = "apartmentPublicId_DESC",
  ApartmentAsc = "apartment_ASC",
  ApartmentDesc = "apartment_DESC",
  AwaitingAsc = "awaiting_ASC",
  AwaitingDesc = "awaiting_DESC",
  CompleteBeforeAsc = "completeBefore_ASC",
  CompleteBeforeDesc = "completeBefore_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  OwnerAsc = "owner_ASC",
  OwnerDesc = "owner_DESC",
  PropertyAsc = "property_ASC",
  PropertyDesc = "property_DESC",
  RequesterAsc = "requester_ASC",
  RequesterDesc = "requester_DESC",
  ScheduledDateAsc = "scheduledDate_ASC",
  ScheduledDateDesc = "scheduledDate_DESC",
  StartAfterAsc = "startAfter_ASC",
  StartAfterDesc = "startAfter_DESC",
  StateAsc = "state_ASC",
  StateDesc = "state_DESC",
  TenancyEndDateAsc = "tenancyEndDate_ASC",
  TenancyEndDateDesc = "tenancyEndDate_DESC",
  TenancyStartDateAsc = "tenancyStartDate_ASC",
  TenancyStartDateDesc = "tenancyStartDate_DESC",
  TypeAsc = "type_ASC",
  TypeDesc = "type_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
}

export enum CaseTypeEnum {
  CleaningService = "cleaning_service",
  CreateTenancy = "create_tenancy",
  EndTenancy = "end_tenancy",
  FindTenancyAdministration = "find_tenancy_administration",
  MiscAdministration = "misc_administration",
  MiscService = "misc_service",
  MovingInService = "moving_in_service",
  MovingInTenancy = "moving_in_tenancy",
  MovingOutService = "moving_out_service",
  MovingOutTenancy = "moving_out_tenancy",
  MovingSettlementAdministration = "moving_settlement_administration",
  PreparationService = "preparation_service",
  RequestTenancy = "request_tenancy",
  StartTenancy = "start_tenancy",
  UtilityAdministration = "utility_administration",
}

export type CaseWhereAndInput = {
  and?: InputMaybe<Array<CaseWhereAndInput>>;
  archived?: InputMaybe<Scalars["Boolean"]["input"]>;
  awaiting?: InputMaybe<Scalars["Boolean"]["input"]>;
  byAttention?: InputMaybe<CaseAttentionEnum>;
  byState?: InputMaybe<Array<Scalars["String"]["input"]>>;
  byType?: InputMaybe<Array<Scalars["String"]["input"]>>;
  cancelled?: InputMaybe<Scalars["Boolean"]["input"]>;
  completed?: InputMaybe<Scalars["Boolean"]["input"]>;
  or?: InputMaybe<Array<CaseWhereOrInput>>;
};

export type CaseWhereInput = {
  allForCurrentUser?: InputMaybe<Scalars["Boolean"]["input"]>;
  and?: InputMaybe<Array<CaseWhereAndInput>>;
  archived?: InputMaybe<Scalars["Boolean"]["input"]>;
  awaiting?: InputMaybe<Scalars["Boolean"]["input"]>;
  awaitingForCurrentUser?: InputMaybe<Scalars["Boolean"]["input"]>;
  byApartment?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  byAttention?: InputMaybe<CaseAttentionEnum>;
  byParent?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  byProperty?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  byRequester?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  byResponsible?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  byState?: InputMaybe<Array<Scalars["String"]["input"]>>;
  byType?: InputMaybe<Array<Scalars["String"]["input"]>>;
  calendarDateRange?: InputMaybe<DateRangeInput>;
  cancelled?: InputMaybe<Scalars["Boolean"]["input"]>;
  commentsForCurrentUser?: InputMaybe<Scalars["Boolean"]["input"]>;
  completed?: InputMaybe<Scalars["Boolean"]["input"]>;
  criticalForCurrentUser?: InputMaybe<Scalars["Boolean"]["input"]>;
  flaggedForCurrentUser?: InputMaybe<Scalars["Boolean"]["input"]>;
  importantForCurrentUser?: InputMaybe<Scalars["Boolean"]["input"]>;
  newForCurrentUser?: InputMaybe<Scalars["Boolean"]["input"]>;
  notAwaitingForCurrentUser?: InputMaybe<Scalars["Boolean"]["input"]>;
  or?: InputMaybe<Array<CaseWhereOrInput>>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  withTenancy?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CaseWhereOrInput = {
  and?: InputMaybe<Array<CaseWhereAndInput>>;
  archived?: InputMaybe<Scalars["Boolean"]["input"]>;
  awaiting?: InputMaybe<Scalars["Boolean"]["input"]>;
  byAttention?: InputMaybe<CaseAttentionEnum>;
  byState?: InputMaybe<Array<Scalars["String"]["input"]>>;
  byType?: InputMaybe<Array<Scalars["String"]["input"]>>;
  cancelled?: InputMaybe<Scalars["Boolean"]["input"]>;
  completed?: InputMaybe<Scalars["Boolean"]["input"]>;
  or?: InputMaybe<Array<CaseWhereOrInput>>;
};

export type CleaningService = CaseInterface & {
  __typename?: "CleaningService";
  apartment?: Maybe<Apartment>;
  attachments: Array<CaseAttachment>;
  attention: CaseAttentionEnum;
  awaiting?: Maybe<Scalars["String"]["output"]>;
  body: Scalars["String"]["output"];
  calendarDate?: Maybe<Scalars["String"]["output"]>;
  calendarRange: DateRange;
  comments: Array<CaseComment>;
  company?: Maybe<Company>;
  completeBefore?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["ISO8601DateTime"]["output"];
  icon: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  inspection?: Maybe<Inspection>;
  isArchived: Scalars["Boolean"]["output"];
  isAttending?: Maybe<Scalars["Boolean"]["output"]>;
  isService: Scalars["Boolean"]["output"];
  mayAddComment: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  parent?: Maybe<CaseInterface>;
  property?: Maybe<Property>;
  requester?: Maybe<User>;
  responsible?: Maybe<User>;
  scheduledDate?: Maybe<Scalars["String"]["output"]>;
  scheduledTime?: Maybe<Scalars["String"]["output"]>;
  serviceDate?: Maybe<Scalars["ISO8601Date"]["output"]>;
  serviceEmployee?: Maybe<Scalars["String"]["output"]>;
  serviceTime?: Maybe<Scalars["String"]["output"]>;
  shortName: Scalars["String"]["output"];
  startAfter?: Maybe<Scalars["String"]["output"]>;
  state: Scalars["String"]["output"];
  stateLastChangedAt?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  stateText: Scalars["String"]["output"];
  tenancy?: Maybe<Tenancy>;
  tenantWillAttend?: Maybe<Scalars["Boolean"]["output"]>;
  title: Scalars["String"]["output"];
  totalCommentsCount: Scalars["Int"]["output"];
  type: CaseTypeEnum;
  unreadCommentsCount: Scalars["Int"]["output"];
  updatedAt: Scalars["ISO8601DateTime"]["output"];
};

export type Company = RecordInterface & {
  __typename?: "Company";
  address?: Maybe<Scalars["String"]["output"]>;
  city?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["ISO8601DateTime"]["output"];
  email?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  isAdministrator: Scalars["Boolean"]["output"];
  isOwner: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  phone?: Maybe<Scalars["String"]["output"]>;
  postalCode?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["ISO8601DateTime"]["output"];
  vat?: Maybe<Scalars["String"]["output"]>;
};

export type CompanyList = {
  __typename?: "CompanyList";
  count: Scalars["Int"]["output"];
  records: Array<Company>;
  total: Scalars["Int"]["output"];
};

export enum CompanyOrderByEnum {
  AddressAsc = "address_ASC",
  AddressDesc = "address_DESC",
  CityAsc = "city_ASC",
  CityDesc = "city_DESC",
  EmailAsc = "email_ASC",
  EmailDesc = "email_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  PhoneAsc = "phone_ASC",
  PhoneDesc = "phone_DESC",
  VatAsc = "vat_ASC",
  VatDesc = "vat_DESC",
}

export type CompanyWhereByPropertyServiceInput = {
  property: Scalars["ID"]["input"];
  service: Scalars["String"]["input"];
};

export type CompanyWhereInput = {
  administrators?: InputMaybe<Scalars["Boolean"]["input"]>;
  byPropertyService?: InputMaybe<CompanyWhereByPropertyServiceInput>;
  search?: InputMaybe<Scalars["String"]["input"]>;
};

export type CreateTenancy = CaseInterface & {
  __typename?: "CreateTenancy";
  apartment?: Maybe<Apartment>;
  attachments: Array<CaseAttachment>;
  attention: CaseAttentionEnum;
  awaiting?: Maybe<Scalars["String"]["output"]>;
  body: Scalars["String"]["output"];
  calendarDate?: Maybe<Scalars["String"]["output"]>;
  calendarRange: DateRange;
  comments: Array<CaseComment>;
  company?: Maybe<Company>;
  completeBefore?: Maybe<Scalars["String"]["output"]>;
  contract?: Maybe<Document>;
  createdAt: Scalars["ISO8601DateTime"]["output"];
  depositAmount?: Maybe<Scalars["Int"]["output"]>;
  endDate?: Maybe<Scalars["ISO8601Date"]["output"]>;
  icon: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  inspection?: Maybe<Inspection>;
  intervalAmount?: Maybe<Scalars["Int"]["output"]>;
  isArchived: Scalars["Boolean"]["output"];
  isAttending?: Maybe<Scalars["Boolean"]["output"]>;
  isService: Scalars["Boolean"]["output"];
  mayAddComment: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  parent?: Maybe<CaseInterface>;
  property?: Maybe<Property>;
  requester?: Maybe<User>;
  requiresCprNumber: Scalars["Boolean"]["output"];
  responsible?: Maybe<User>;
  scheduledDate?: Maybe<Scalars["String"]["output"]>;
  scheduledTime?: Maybe<Scalars["String"]["output"]>;
  serviceDate?: Maybe<Scalars["ISO8601Date"]["output"]>;
  serviceEmployee?: Maybe<Scalars["String"]["output"]>;
  serviceTime?: Maybe<Scalars["String"]["output"]>;
  shortName: Scalars["String"]["output"];
  startAfter?: Maybe<Scalars["String"]["output"]>;
  startDate: Scalars["ISO8601Date"]["output"];
  state: Scalars["String"]["output"];
  stateLastChangedAt?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  stateText: Scalars["String"]["output"];
  tenancy?: Maybe<Tenancy>;
  tenantWillAttend?: Maybe<Scalars["Boolean"]["output"]>;
  title: Scalars["String"]["output"];
  totalCommentsCount: Scalars["Int"]["output"];
  type: CaseTypeEnum;
  unreadCommentsCount: Scalars["Int"]["output"];
  updatedAt: Scalars["ISO8601DateTime"]["output"];
};

export type DateRange = {
  __typename?: "DateRange";
  from?: Maybe<Scalars["String"]["output"]>;
  to?: Maybe<Scalars["String"]["output"]>;
};

export type DateRangeInput = {
  from: Scalars["String"]["input"];
  to?: InputMaybe<Scalars["String"]["input"]>;
};

/** Autogenerated return type of DeleteInspectionAnswerMutation. */
export type DeleteInspectionAnswerMutationPayload = {
  __typename?: "DeleteInspectionAnswerMutationPayload";
  errors: Array<Scalars["String"]["output"]>;
};

/** Autogenerated return type of DeletePaymentMutation. */
export type DeletePaymentMutationPayload = {
  __typename?: "DeletePaymentMutationPayload";
  errors: Array<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
};

/** Autogenerated return type of DeleteTextMacroMutation. */
export type DeleteTextMacroMutationPayload = {
  __typename?: "DeleteTextMacroMutationPayload";
  errors: Array<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
};

export type Document = {
  __typename?: "Document";
  createdAt: Scalars["ISO8601DateTime"]["output"];
  id: Scalars["ID"]["output"];
  title: Scalars["String"]["output"];
  type: DocumentTypeEnum;
  url: Scalars["String"]["output"];
};

export enum DocumentTypeEnum {
  Contract = "contract",
  Generic = "generic",
  Inspection = "inspection",
  Photo = "photo",
}

export type EconomicDebtor = {
  __typename?: "EconomicDebtor";
  address?: Maybe<Scalars["String"]["output"]>;
  addressText?: Maybe<Scalars["String"]["output"]>;
  city?: Maybe<Scalars["String"]["output"]>;
  country?: Maybe<Scalars["String"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  externalId: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  postalCode?: Maybe<Scalars["String"]["output"]>;
};

export type EconomicDebtorList = {
  __typename?: "EconomicDebtorList";
  count: Scalars["Int"]["output"];
  records: Array<EconomicDebtor>;
  total: Scalars["Int"]["output"];
};

export type EconomicDebtorWhereInput = {
  byName?: InputMaybe<Scalars["ID"]["input"]>;
  byTenancy?: InputMaybe<Scalars["ID"]["input"]>;
  withSubscriptionId?: InputMaybe<Scalars["ID"]["input"]>;
};

/** Autogenerated return type of EconomicResyncMutation. */
export type EconomicResyncMutationPayload = {
  __typename?: "EconomicResyncMutationPayload";
  success: Scalars["Boolean"]["output"];
};

export type EconomicSubscriber = {
  __typename?: "EconomicSubscriber";
  canceledAt?: Maybe<Scalars["ISO8601Date"]["output"]>;
  debtor: EconomicDebtor;
  id: Scalars["ID"]["output"];
  intervalEndAt?: Maybe<Scalars["ISO8601Date"]["output"]>;
  intervalStartAt?: Maybe<Scalars["ISO8601Date"]["output"]>;
  price: Scalars["Float"]["output"];
  signedUpAt?: Maybe<Scalars["ISO8601Date"]["output"]>;
  subscription: EconomicSubscription;
};

export type EconomicSubscriberList = {
  __typename?: "EconomicSubscriberList";
  count: Scalars["Int"]["output"];
  records: Array<EconomicSubscriber>;
  total: Scalars["Int"]["output"];
};

export type EconomicSubscriberWhereInput = {
  byTenancy?: InputMaybe<Scalars["ID"]["input"]>;
};

export type EconomicSubscription = {
  __typename?: "EconomicSubscription";
  id: Scalars["ID"]["output"];
  intervalType?: Maybe<EconomicSubscriptionIntervalEnum>;
  name: Scalars["String"]["output"];
  subscribers: Array<EconomicSubscriber>;
};

export enum EconomicSubscriptionIntervalEnum {
  /** Eight-weekly interval */
  EightWeeks = "EightWeeks",
  /** Penta-yearly interval */
  FiveYears = "FiveYears",
  /** Four-weekly interval */
  FourWeeks = "FourWeeks",
  /** Quad-yearly interval */
  FourYears = "FourYears",
  /** Half-yearly interval */
  HalfYear = "HalfYear",
  /** Monthly interval */
  Month = "Month",
  /** Quarterly interval */
  Quarter = "Quarter",
  /** Tri-yearly interval */
  ThreeYears = "ThreeYears",
  /** Bi-monthly interval */
  TwoMonths = "TwoMonths",
  /** Bi-weekly interval */
  TwoWeeks = "TwoWeeks",
  /** Bi-yearly interval */
  TwoYears = "TwoYears",
  /** Weekly interval */
  Week = "Week",
  /** Yearly interval */
  Year = "Year",
}

export type EconomicSubscriptionList = {
  __typename?: "EconomicSubscriptionList";
  count: Scalars["Int"]["output"];
  records: Array<EconomicSubscription>;
  total: Scalars["Int"]["output"];
};

export type EconomicSubscriptionWhereInput = {
  byName?: InputMaybe<Scalars["String"]["input"]>;
  byOwner?: InputMaybe<Scalars["ID"]["input"]>;
};

export type EndTenancy = CaseInterface & {
  __typename?: "EndTenancy";
  apartment?: Maybe<Apartment>;
  attachments: Array<CaseAttachment>;
  attention: CaseAttentionEnum;
  awaiting?: Maybe<Scalars["String"]["output"]>;
  body: Scalars["String"]["output"];
  calendarDate?: Maybe<Scalars["String"]["output"]>;
  calendarRange: DateRange;
  comment?: Maybe<Scalars["String"]["output"]>;
  comments: Array<CaseComment>;
  company?: Maybe<Company>;
  completeBefore?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["ISO8601DateTime"]["output"];
  icon: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  inspection?: Maybe<Inspection>;
  isArchived: Scalars["Boolean"]["output"];
  isAttending?: Maybe<Scalars["Boolean"]["output"]>;
  isService: Scalars["Boolean"]["output"];
  mayAddComment: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  parent?: Maybe<CaseInterface>;
  property?: Maybe<Property>;
  requester?: Maybe<User>;
  responsible?: Maybe<User>;
  scheduledDate?: Maybe<Scalars["String"]["output"]>;
  scheduledTime?: Maybe<Scalars["String"]["output"]>;
  serviceDate?: Maybe<Scalars["ISO8601Date"]["output"]>;
  serviceEmployee?: Maybe<Scalars["String"]["output"]>;
  serviceTime?: Maybe<Scalars["String"]["output"]>;
  shortName: Scalars["String"]["output"];
  startAfter?: Maybe<Scalars["String"]["output"]>;
  state: Scalars["String"]["output"];
  stateLastChangedAt?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  stateText: Scalars["String"]["output"];
  tenancy?: Maybe<Tenancy>;
  tenantWillAttend?: Maybe<Scalars["Boolean"]["output"]>;
  terms: TenancyTerms;
  title: Scalars["String"]["output"];
  totalCommentsCount: Scalars["Int"]["output"];
  type: CaseTypeEnum;
  unreadCommentsCount: Scalars["Int"]["output"];
  updatedAt: Scalars["ISO8601DateTime"]["output"];
};

/** Autogenerated return type of EndTenancyMutation. */
export type EndTenancyMutationPayload = {
  __typename?: "EndTenancyMutationPayload";
  case?: Maybe<EndTenancy>;
  errors?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type File = {
  __typename?: "File";
  createdAt: Scalars["ISO8601DateTime"]["output"];
  fileName: Scalars["String"]["output"];
  fileSize: Scalars["Int"]["output"];
  fileType: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  url: Scalars["String"]["output"];
};

export type FindTenancyAdministration = CaseInterface & {
  __typename?: "FindTenancyAdministration";
  apartment?: Maybe<Apartment>;
  attachments: Array<CaseAttachment>;
  attention: CaseAttentionEnum;
  awaiting?: Maybe<Scalars["String"]["output"]>;
  body: Scalars["String"]["output"];
  calendarDate?: Maybe<Scalars["String"]["output"]>;
  calendarRange: DateRange;
  comments: Array<CaseComment>;
  company?: Maybe<Company>;
  completeBefore?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["ISO8601DateTime"]["output"];
  icon: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  inspection?: Maybe<Inspection>;
  isArchived: Scalars["Boolean"]["output"];
  isAttending?: Maybe<Scalars["Boolean"]["output"]>;
  isService: Scalars["Boolean"]["output"];
  mayAddComment: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  parent?: Maybe<CaseInterface>;
  property?: Maybe<Property>;
  requester?: Maybe<User>;
  responsible?: Maybe<User>;
  scheduledDate?: Maybe<Scalars["String"]["output"]>;
  scheduledTime?: Maybe<Scalars["String"]["output"]>;
  serviceDate?: Maybe<Scalars["ISO8601Date"]["output"]>;
  serviceEmployee?: Maybe<Scalars["String"]["output"]>;
  serviceTime?: Maybe<Scalars["String"]["output"]>;
  shortName: Scalars["String"]["output"];
  startAfter?: Maybe<Scalars["String"]["output"]>;
  state: Scalars["String"]["output"];
  stateLastChangedAt?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  stateText: Scalars["String"]["output"];
  tenancy?: Maybe<Tenancy>;
  tenantWillAttend?: Maybe<Scalars["Boolean"]["output"]>;
  title: Scalars["String"]["output"];
  totalCommentsCount: Scalars["Int"]["output"];
  type: CaseTypeEnum;
  unreadCommentsCount: Scalars["Int"]["output"];
  updatedAt: Scalars["ISO8601DateTime"]["output"];
};

export type Inspection = {
  __typename?: "Inspection";
  answers: Array<InspectionAnswer>;
  createdAt: Scalars["ISO8601DateTime"]["output"];
  updatedAt: Scalars["ISO8601DateTime"]["output"];
};

export type InspectionAnswer = {
  __typename?: "InspectionAnswer";
  area: InspectionArea;
  note?: Maybe<Scalars["String"]["output"]>;
  rating?: Maybe<Scalars["Int"]["output"]>;
  subject: InspectionSubject;
};

export type InspectionArea = {
  __typename?: "InspectionArea";
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
};

export type InspectionSubject = {
  __typename?: "InspectionSubject";
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
};

export type InterestListEntry = RecordInterface & {
  __typename?: "InterestListEntry";
  createdAt: Scalars["ISO8601DateTime"]["output"];
  email: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  phone: Scalars["String"]["output"];
  properties: Array<Property>;
  updatedAt: Scalars["ISO8601DateTime"]["output"];
};

export type InterestListEntryList = {
  __typename?: "InterestListEntryList";
  count: Scalars["Int"]["output"];
  records: Array<InterestListEntry>;
  total: Scalars["Int"]["output"];
};

export enum InterestListEntryOrderByEnum {
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  EmailAsc = "email_ASC",
  EmailDesc = "email_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  PhoneAsc = "phone_ASC",
  PhoneDesc = "phone_DESC",
}

export type InterestListEntryWhereInput = {
  byProperty?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  search?: InputMaybe<Scalars["String"]["input"]>;
};

export type Message = RecordInterface & {
  __typename?: "Message";
  apartment?: Maybe<Scalars["String"]["output"]>;
  author: Scalars["String"]["output"];
  authorId: Scalars["String"]["output"];
  body: Scalars["String"]["output"];
  companies: Array<Company>;
  company?: Maybe<Company>;
  createdAt: Scalars["ISO8601DateTime"]["output"];
  emailsSentAt?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  id: Scalars["ID"]["output"];
  property: Property;
  recipients: UserList;
  smsSentAt?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  title: Scalars["String"]["output"];
  updatedAt: Scalars["ISO8601DateTime"]["output"];
};

export type MessageList = {
  __typename?: "MessageList";
  count: Scalars["Int"]["output"];
  records: Array<Message>;
  total: Scalars["Int"]["output"];
};

export enum MessageOrderByEnum {
  AuthorAsc = "author_ASC",
  AuthorDesc = "author_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  ExpireAtAsc = "expireAt_ASC",
  ExpireAtDesc = "expireAt_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
}

export type MessageWhereInput = {
  byId?: InputMaybe<Scalars["ID"]["input"]>;
  byProperty?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  search?: InputMaybe<Scalars["String"]["input"]>;
};

export type MiscAdministration = CaseInterface & {
  __typename?: "MiscAdministration";
  apartment?: Maybe<Apartment>;
  attachments: Array<CaseAttachment>;
  attention: CaseAttentionEnum;
  awaiting?: Maybe<Scalars["String"]["output"]>;
  body: Scalars["String"]["output"];
  calendarDate?: Maybe<Scalars["String"]["output"]>;
  calendarRange: DateRange;
  comments: Array<CaseComment>;
  company?: Maybe<Company>;
  completeBefore?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["ISO8601DateTime"]["output"];
  icon: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  inspection?: Maybe<Inspection>;
  isArchived: Scalars["Boolean"]["output"];
  isAttending?: Maybe<Scalars["Boolean"]["output"]>;
  isService: Scalars["Boolean"]["output"];
  mayAddComment: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  parent?: Maybe<CaseInterface>;
  property?: Maybe<Property>;
  requester?: Maybe<User>;
  responsible?: Maybe<User>;
  scheduledDate?: Maybe<Scalars["String"]["output"]>;
  scheduledTime?: Maybe<Scalars["String"]["output"]>;
  serviceDate?: Maybe<Scalars["ISO8601Date"]["output"]>;
  serviceEmployee?: Maybe<Scalars["String"]["output"]>;
  serviceTime?: Maybe<Scalars["String"]["output"]>;
  shortName: Scalars["String"]["output"];
  startAfter?: Maybe<Scalars["String"]["output"]>;
  state: Scalars["String"]["output"];
  stateLastChangedAt?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  stateText: Scalars["String"]["output"];
  tenancy?: Maybe<Tenancy>;
  tenantWillAttend?: Maybe<Scalars["Boolean"]["output"]>;
  title: Scalars["String"]["output"];
  totalCommentsCount: Scalars["Int"]["output"];
  type: CaseTypeEnum;
  unreadCommentsCount: Scalars["Int"]["output"];
  updatedAt: Scalars["ISO8601DateTime"]["output"];
};

export type MiscService = CaseInterface & {
  __typename?: "MiscService";
  apartment?: Maybe<Apartment>;
  attachments: Array<CaseAttachment>;
  attention: CaseAttentionEnum;
  awaiting?: Maybe<Scalars["String"]["output"]>;
  body: Scalars["String"]["output"];
  calendarDate?: Maybe<Scalars["String"]["output"]>;
  calendarRange: DateRange;
  comments: Array<CaseComment>;
  company?: Maybe<Company>;
  completeBefore?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["ISO8601DateTime"]["output"];
  icon: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  inspection?: Maybe<Inspection>;
  isArchived: Scalars["Boolean"]["output"];
  isAttending?: Maybe<Scalars["Boolean"]["output"]>;
  isService: Scalars["Boolean"]["output"];
  mayAddComment: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  parent?: Maybe<CaseInterface>;
  property?: Maybe<Property>;
  requester?: Maybe<User>;
  responsible?: Maybe<User>;
  scheduledDate?: Maybe<Scalars["String"]["output"]>;
  scheduledTime?: Maybe<Scalars["String"]["output"]>;
  serviceDate?: Maybe<Scalars["ISO8601Date"]["output"]>;
  serviceEmployee?: Maybe<Scalars["String"]["output"]>;
  serviceTime?: Maybe<Scalars["String"]["output"]>;
  shortName: Scalars["String"]["output"];
  startAfter?: Maybe<Scalars["String"]["output"]>;
  state: Scalars["String"]["output"];
  stateLastChangedAt?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  stateText: Scalars["String"]["output"];
  tenancy?: Maybe<Tenancy>;
  tenantWillAttend?: Maybe<Scalars["Boolean"]["output"]>;
  title: Scalars["String"]["output"];
  totalCommentsCount: Scalars["Int"]["output"];
  type: CaseTypeEnum;
  unreadCommentsCount: Scalars["Int"]["output"];
  updatedAt: Scalars["ISO8601DateTime"]["output"];
};

export type MovingInService = CaseInterface & {
  __typename?: "MovingInService";
  apartment?: Maybe<Apartment>;
  attachments: Array<CaseAttachment>;
  attention: CaseAttentionEnum;
  awaiting?: Maybe<Scalars["String"]["output"]>;
  body: Scalars["String"]["output"];
  calendarDate?: Maybe<Scalars["String"]["output"]>;
  calendarRange: DateRange;
  comments: Array<CaseComment>;
  company?: Maybe<Company>;
  completeBefore?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["ISO8601DateTime"]["output"];
  icon: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  inspection?: Maybe<Inspection>;
  isArchived: Scalars["Boolean"]["output"];
  isAttending?: Maybe<Scalars["Boolean"]["output"]>;
  isService: Scalars["Boolean"]["output"];
  mayAddComment: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  parent?: Maybe<CaseInterface>;
  property?: Maybe<Property>;
  requester?: Maybe<User>;
  responsible?: Maybe<User>;
  scheduledDate?: Maybe<Scalars["String"]["output"]>;
  scheduledTime?: Maybe<Scalars["String"]["output"]>;
  serviceDate?: Maybe<Scalars["ISO8601Date"]["output"]>;
  serviceEmployee?: Maybe<Scalars["String"]["output"]>;
  serviceTime?: Maybe<Scalars["String"]["output"]>;
  shortName: Scalars["String"]["output"];
  startAfter?: Maybe<Scalars["String"]["output"]>;
  state: Scalars["String"]["output"];
  stateLastChangedAt?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  stateText: Scalars["String"]["output"];
  tenancy?: Maybe<Tenancy>;
  tenantWillAttend?: Maybe<Scalars["Boolean"]["output"]>;
  title: Scalars["String"]["output"];
  totalCommentsCount: Scalars["Int"]["output"];
  type: CaseTypeEnum;
  unreadCommentsCount: Scalars["Int"]["output"];
  updatedAt: Scalars["ISO8601DateTime"]["output"];
};

export type MovingInTenancy = CaseInterface & {
  __typename?: "MovingInTenancy";
  apartment?: Maybe<Apartment>;
  attachments: Array<CaseAttachment>;
  attention: CaseAttentionEnum;
  awaiting?: Maybe<Scalars["String"]["output"]>;
  body: Scalars["String"]["output"];
  calendarDate?: Maybe<Scalars["String"]["output"]>;
  calendarRange: DateRange;
  comments: Array<CaseComment>;
  company?: Maybe<Company>;
  completeBefore?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["ISO8601DateTime"]["output"];
  icon: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  inspection?: Maybe<Inspection>;
  isArchived: Scalars["Boolean"]["output"];
  isAttending?: Maybe<Scalars["Boolean"]["output"]>;
  isService: Scalars["Boolean"]["output"];
  mayAddComment: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  parent?: Maybe<CaseInterface>;
  property?: Maybe<Property>;
  requester?: Maybe<User>;
  responsible?: Maybe<User>;
  scheduledDate?: Maybe<Scalars["String"]["output"]>;
  scheduledTime?: Maybe<Scalars["String"]["output"]>;
  serviceDate?: Maybe<Scalars["ISO8601Date"]["output"]>;
  serviceEmployee?: Maybe<Scalars["String"]["output"]>;
  serviceTime?: Maybe<Scalars["String"]["output"]>;
  shortName: Scalars["String"]["output"];
  startAfter?: Maybe<Scalars["String"]["output"]>;
  state: Scalars["String"]["output"];
  stateLastChangedAt?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  stateText: Scalars["String"]["output"];
  tenancy?: Maybe<Tenancy>;
  tenantWillAttend?: Maybe<Scalars["Boolean"]["output"]>;
  title: Scalars["String"]["output"];
  totalCommentsCount: Scalars["Int"]["output"];
  type: CaseTypeEnum;
  unreadCommentsCount: Scalars["Int"]["output"];
  updatedAt: Scalars["ISO8601DateTime"]["output"];
};

export type MovingOutService = CaseInterface & {
  __typename?: "MovingOutService";
  apartment?: Maybe<Apartment>;
  attachments: Array<CaseAttachment>;
  attention: CaseAttentionEnum;
  awaiting?: Maybe<Scalars["String"]["output"]>;
  body: Scalars["String"]["output"];
  calendarDate?: Maybe<Scalars["String"]["output"]>;
  calendarRange: DateRange;
  comments: Array<CaseComment>;
  company?: Maybe<Company>;
  completeBefore?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["ISO8601DateTime"]["output"];
  icon: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  inspection?: Maybe<Inspection>;
  isArchived: Scalars["Boolean"]["output"];
  isAttending?: Maybe<Scalars["Boolean"]["output"]>;
  isService: Scalars["Boolean"]["output"];
  mayAddComment: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  parent?: Maybe<CaseInterface>;
  property?: Maybe<Property>;
  requester?: Maybe<User>;
  responsible?: Maybe<User>;
  scheduledDate?: Maybe<Scalars["String"]["output"]>;
  scheduledTime?: Maybe<Scalars["String"]["output"]>;
  serviceDate?: Maybe<Scalars["ISO8601Date"]["output"]>;
  serviceEmployee?: Maybe<Scalars["String"]["output"]>;
  serviceTime?: Maybe<Scalars["String"]["output"]>;
  shortName: Scalars["String"]["output"];
  startAfter?: Maybe<Scalars["String"]["output"]>;
  state: Scalars["String"]["output"];
  stateLastChangedAt?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  stateText: Scalars["String"]["output"];
  tenancy?: Maybe<Tenancy>;
  tenantWillAttend?: Maybe<Scalars["Boolean"]["output"]>;
  title: Scalars["String"]["output"];
  totalCommentsCount: Scalars["Int"]["output"];
  type: CaseTypeEnum;
  unreadCommentsCount: Scalars["Int"]["output"];
  updatedAt: Scalars["ISO8601DateTime"]["output"];
};

export type MovingOutTenancy = CaseInterface & {
  __typename?: "MovingOutTenancy";
  apartment?: Maybe<Apartment>;
  attachments: Array<CaseAttachment>;
  attention: CaseAttentionEnum;
  awaiting?: Maybe<Scalars["String"]["output"]>;
  body: Scalars["String"]["output"];
  calendarDate?: Maybe<Scalars["String"]["output"]>;
  calendarRange: DateRange;
  comments: Array<CaseComment>;
  company?: Maybe<Company>;
  completeBefore?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["ISO8601DateTime"]["output"];
  icon: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  inspection?: Maybe<Inspection>;
  isArchived: Scalars["Boolean"]["output"];
  isAttending?: Maybe<Scalars["Boolean"]["output"]>;
  isService: Scalars["Boolean"]["output"];
  mayAddComment: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  parent?: Maybe<CaseInterface>;
  property?: Maybe<Property>;
  requester?: Maybe<User>;
  responsible?: Maybe<User>;
  scheduledDate?: Maybe<Scalars["String"]["output"]>;
  scheduledTime?: Maybe<Scalars["String"]["output"]>;
  serviceDate?: Maybe<Scalars["ISO8601Date"]["output"]>;
  serviceEmployee?: Maybe<Scalars["String"]["output"]>;
  serviceTime?: Maybe<Scalars["String"]["output"]>;
  shortName: Scalars["String"]["output"];
  startAfter?: Maybe<Scalars["String"]["output"]>;
  state: Scalars["String"]["output"];
  stateLastChangedAt?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  stateText: Scalars["String"]["output"];
  tenancy?: Maybe<Tenancy>;
  tenantWillAttend?: Maybe<Scalars["Boolean"]["output"]>;
  title: Scalars["String"]["output"];
  totalCommentsCount: Scalars["Int"]["output"];
  type: CaseTypeEnum;
  unreadCommentsCount: Scalars["Int"]["output"];
  updatedAt: Scalars["ISO8601DateTime"]["output"];
};

export type MovingSettlementAdministration = CaseInterface & {
  __typename?: "MovingSettlementAdministration";
  apartment?: Maybe<Apartment>;
  attachments: Array<CaseAttachment>;
  attention: CaseAttentionEnum;
  awaiting?: Maybe<Scalars["String"]["output"]>;
  body: Scalars["String"]["output"];
  calendarDate?: Maybe<Scalars["String"]["output"]>;
  calendarRange: DateRange;
  comments: Array<CaseComment>;
  company?: Maybe<Company>;
  completeBefore?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["ISO8601DateTime"]["output"];
  icon: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  inspection?: Maybe<Inspection>;
  isArchived: Scalars["Boolean"]["output"];
  isAttending?: Maybe<Scalars["Boolean"]["output"]>;
  isService: Scalars["Boolean"]["output"];
  mayAddComment: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  parent?: Maybe<CaseInterface>;
  property?: Maybe<Property>;
  requester?: Maybe<User>;
  responsible?: Maybe<User>;
  scheduledDate?: Maybe<Scalars["String"]["output"]>;
  scheduledTime?: Maybe<Scalars["String"]["output"]>;
  serviceDate?: Maybe<Scalars["ISO8601Date"]["output"]>;
  serviceEmployee?: Maybe<Scalars["String"]["output"]>;
  serviceTime?: Maybe<Scalars["String"]["output"]>;
  shortName: Scalars["String"]["output"];
  startAfter?: Maybe<Scalars["String"]["output"]>;
  state: Scalars["String"]["output"];
  stateLastChangedAt?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  stateText: Scalars["String"]["output"];
  tenancy?: Maybe<Tenancy>;
  tenantWillAttend?: Maybe<Scalars["Boolean"]["output"]>;
  title: Scalars["String"]["output"];
  totalCommentsCount: Scalars["Int"]["output"];
  type: CaseTypeEnum;
  unreadCommentsCount: Scalars["Int"]["output"];
  updatedAt: Scalars["ISO8601DateTime"]["output"];
};

export type Mutation = {
  __typename?: "Mutation";
  deleteInspectionAnswer?: Maybe<DeleteInspectionAnswerMutationPayload>;
  deletePayment?: Maybe<DeletePaymentMutationPayload>;
  deleteTextMacro?: Maybe<DeleteTextMacroMutationPayload>;
  economicResync?: Maybe<EconomicResyncMutationPayload>;
  lookupUser?: Maybe<User>;
  personalAccessTokenCreate?: Maybe<PersonalAccessTokenCreateMutationPayload>;
  personalAccessTokenDelete?: Maybe<PersonalAccessTokenDeleteMutationPayload>;
  phoneConfirm?: Maybe<PhoneConfirmMutationPayload>;
  saveInspectionAnswer?: Maybe<SaveInspectionAnswerMutationPayload>;
  savePayment?: Maybe<SavePaymentMutationPayload>;
  saveTextMacro?: Maybe<SaveTextMacroMutationPayload>;
  shortcutCreate?: Maybe<ShortcutCreateMutationPayload>;
  shortcutDelete?: Maybe<ShortcutDeleteMutationPayload>;
  shortcutUpdate?: Maybe<ShortcutUpdatePayload>;
  tenancyDebtorLink?: Maybe<TenancyDebtorLinkMutationPayload>;
  tenancyDebtorUnlink?: Maybe<TenancyDebtorUnlinkMutationPayload>;
  tenancyEnd?: Maybe<EndTenancyMutationPayload>;
  tenancyNoteCreate?: Maybe<TenancyNoteCreateMutationPayload>;
  tenancyNoteDelete?: Maybe<TenancyNoteDeleteMutationPayload>;
  tenancyNoteUpdate?: Maybe<TenancyNoteUpdateMutationPayload>;
  tenancyRemove?: Maybe<TenancyRemoveMutationPayload>;
  tenancyResidentCreate?: Maybe<TenancyResidentCreateMutationPayload>;
  tenancyResidentRemove?: Maybe<TenancyResidentRemoveMutationPayload>;
  tenancyResidentUpdate?: Maybe<TenancyResidentUpdateMutationPayload>;
  tenancyRoleCreate?: Maybe<TenancyRoleCreateMutationPayload>;
  tenancyRoleRemove?: Maybe<TenancyRoleRemoveMutationPayload>;
  tenancyRoleUpdate?: Maybe<TenancyRoleUpdateMutationPayload>;
  tenancyUpdate?: Maybe<TenancyUpdateMutationPayload>;
  toggleCaseFlag?: Maybe<ToggleCaseFlagMutationPayload>;
  updateExternalUser?: Maybe<UpdateExternalUserMutationPayload>;
};

export type MutationDeleteInspectionAnswerArgs = {
  areaId: Scalars["ID"]["input"];
  caseId: Scalars["ID"]["input"];
  subjectId: Scalars["ID"]["input"];
};

export type MutationDeletePaymentArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteTextMacroArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationEconomicResyncArgs = {
  byTenancy?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationLookupUserArgs = {
  email: Scalars["String"]["input"];
};

export type MutationPersonalAccessTokenCreateArgs = {
  name: Scalars["String"]["input"];
};

export type MutationPersonalAccessTokenDeleteArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationPhoneConfirmArgs = {
  userId: Scalars["ID"]["input"];
};

export type MutationSaveInspectionAnswerArgs = {
  areaId: Scalars["ID"]["input"];
  caseId: Scalars["ID"]["input"];
  note?: InputMaybe<Scalars["String"]["input"]>;
  position: Scalars["Int"]["input"];
  rating: Scalars["Int"]["input"];
  subjectId: Scalars["ID"]["input"];
};

export type MutationSavePaymentArgs = {
  amount: Scalars["Float"]["input"];
  endDate: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["ID"]["input"]>;
  reference?: InputMaybe<Scalars["String"]["input"]>;
  startDate: Scalars["String"]["input"];
  tenancyId: Scalars["ID"]["input"];
};

export type MutationSaveTextMacroArgs = {
  available: Scalars["String"]["input"];
  content: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["Int"]["input"]>;
  name: Scalars["String"]["input"];
};

export type MutationShortcutCreateArgs = {
  attributes: ShortcutAttributesInput;
};

export type MutationShortcutDeleteArgs = {
  shortcutId: Scalars["ID"]["input"];
};

export type MutationShortcutUpdateArgs = {
  attributes: ShortcutAttributesInput;
  shortcutId: Scalars["ID"]["input"];
};

export type MutationTenancyDebtorLinkArgs = {
  debtorId: Scalars["ID"]["input"];
  tenancyId: Scalars["ID"]["input"];
};

export type MutationTenancyDebtorUnlinkArgs = {
  tenancyId: Scalars["ID"]["input"];
};

export type MutationTenancyEndArgs = {
  comment?: InputMaybe<Scalars["String"]["input"]>;
  endDate: Scalars["ISO8601Date"]["input"];
  tenancyId: Scalars["ID"]["input"];
};

export type MutationTenancyNoteCreateArgs = {
  attachments?: InputMaybe<Array<Scalars["String"]["input"]>>;
  content: Scalars["String"]["input"];
  tenancyId: Scalars["ID"]["input"];
};

export type MutationTenancyNoteDeleteArgs = {
  noteId: Scalars["ID"]["input"];
  tenancyId: Scalars["ID"]["input"];
};

export type MutationTenancyNoteUpdateArgs = {
  attachments?: InputMaybe<Array<Scalars["String"]["input"]>>;
  attachmentsToRemove?: InputMaybe<Array<Scalars["String"]["input"]>>;
  content?: InputMaybe<Scalars["String"]["input"]>;
  noteId: Scalars["ID"]["input"];
  tenancyId: Scalars["ID"]["input"];
};

export type MutationTenancyRemoveArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationTenancyResidentCreateArgs = {
  email?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  tenancyId: Scalars["ID"]["input"];
};

export type MutationTenancyResidentRemoveArgs = {
  residentId: Scalars["ID"]["input"];
};

export type MutationTenancyResidentUpdateArgs = {
  email?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  residentId: Scalars["ID"]["input"];
};

export type MutationTenancyRoleCreateArgs = {
  email?: InputMaybe<Scalars["String"]["input"]>;
  isPrimary?: InputMaybe<Scalars["Boolean"]["input"]>;
  isResident?: InputMaybe<Scalars["Boolean"]["input"]>;
  locale?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  tenancyId: Scalars["ID"]["input"];
  userId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationTenancyRoleRemoveArgs = {
  tenancyRoleId: Scalars["ID"]["input"];
};

export type MutationTenancyRoleUpdateArgs = {
  isPrimary?: InputMaybe<Scalars["Boolean"]["input"]>;
  isResident?: InputMaybe<Scalars["Boolean"]["input"]>;
  tenancyRoleId: Scalars["ID"]["input"];
};

export type MutationTenancyUpdateArgs = {
  depositAmount: Scalars["Float"]["input"];
  endDate?: InputMaybe<Scalars["ISO8601Date"]["input"]>;
  endTenancyTerms: Scalars["String"]["input"];
  intervalAmount: Scalars["Float"]["input"];
  startDate: Scalars["ISO8601Date"]["input"];
  tenancyId: Scalars["ID"]["input"];
};

export type MutationToggleCaseFlagArgs = {
  caseId: Scalars["ID"]["input"];
  flagged?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationUpdateExternalUserArgs = {
  email?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  userId: Scalars["ID"]["input"];
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: "PageInfo";
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]["output"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"]["output"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"]["output"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]["output"]>;
};

export type PartnerProduct = {
  __typename?: "PartnerProduct";
  name: Scalars["String"]["output"];
  partner: Scalars["String"]["output"];
};

export type Payment = {
  __typename?: "Payment";
  amount: Scalars["Float"]["output"];
  endDate: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  reference?: Maybe<Scalars["String"]["output"]>;
  startDate: Scalars["String"]["output"];
};

export type PersonalAccessToken = {
  __typename?: "PersonalAccessToken";
  createdAt: Scalars["ISO8601DateTime"]["output"];
  expiresAt?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  token?: Maybe<Scalars["String"]["output"]>;
};

/** The connection type for PersonalAccessToken. */
export type PersonalAccessTokenConnection = {
  __typename?: "PersonalAccessTokenConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PersonalAccessTokenEdge>>>;
  /** A list of nodes. */
  nodes: Array<PersonalAccessToken>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars["Int"]["output"]>;
};

/** Autogenerated return type of PersonalAccessTokenCreateMutation. */
export type PersonalAccessTokenCreateMutationPayload = {
  __typename?: "PersonalAccessTokenCreateMutationPayload";
  errors?: Maybe<Array<Scalars["String"]["output"]>>;
  personalAccessToken?: Maybe<PersonalAccessToken>;
};

/** Autogenerated return type of PersonalAccessTokenDeleteMutation. */
export type PersonalAccessTokenDeleteMutationPayload = {
  __typename?: "PersonalAccessTokenDeleteMutationPayload";
  errors: Array<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
};

/** An edge in a connection. */
export type PersonalAccessTokenEdge = {
  __typename?: "PersonalAccessTokenEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node?: Maybe<PersonalAccessToken>;
};

/** Autogenerated return type of PhoneConfirmMutation. */
export type PhoneConfirmMutationPayload = {
  __typename?: "PhoneConfirmMutationPayload";
  errors: Array<Scalars["String"]["output"]>;
  user?: Maybe<User>;
};

export type PreparationService = CaseInterface & {
  __typename?: "PreparationService";
  apartment?: Maybe<Apartment>;
  attachments: Array<CaseAttachment>;
  attention: CaseAttentionEnum;
  awaiting?: Maybe<Scalars["String"]["output"]>;
  body: Scalars["String"]["output"];
  calendarDate?: Maybe<Scalars["String"]["output"]>;
  calendarRange: DateRange;
  comments: Array<CaseComment>;
  company?: Maybe<Company>;
  completeBefore?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["ISO8601DateTime"]["output"];
  icon: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  inspection?: Maybe<Inspection>;
  isArchived: Scalars["Boolean"]["output"];
  isAttending?: Maybe<Scalars["Boolean"]["output"]>;
  isService: Scalars["Boolean"]["output"];
  mayAddComment: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  parent?: Maybe<CaseInterface>;
  property?: Maybe<Property>;
  requester?: Maybe<User>;
  responsible?: Maybe<User>;
  scheduledDate?: Maybe<Scalars["String"]["output"]>;
  scheduledTime?: Maybe<Scalars["String"]["output"]>;
  serviceDate?: Maybe<Scalars["ISO8601Date"]["output"]>;
  serviceEmployee?: Maybe<Scalars["String"]["output"]>;
  serviceTime?: Maybe<Scalars["String"]["output"]>;
  shortName: Scalars["String"]["output"];
  startAfter?: Maybe<Scalars["String"]["output"]>;
  state: Scalars["String"]["output"];
  stateLastChangedAt?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  stateText: Scalars["String"]["output"];
  tenancy?: Maybe<Tenancy>;
  tenantWillAttend?: Maybe<Scalars["Boolean"]["output"]>;
  title: Scalars["String"]["output"];
  totalCommentsCount: Scalars["Int"]["output"];
  type: CaseTypeEnum;
  unreadCommentsCount: Scalars["Int"]["output"];
  updatedAt: Scalars["ISO8601DateTime"]["output"];
};

export type Property = RecordInterface & {
  __typename?: "Property";
  activeServiceCasesCount: Scalars["Int"]["output"];
  administrator: Company;
  createdAt: Scalars["ISO8601DateTime"]["output"];
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  owner: Company;
  updatedAt: Scalars["ISO8601DateTime"]["output"];
};

export type PropertyList = {
  __typename?: "PropertyList";
  count: Scalars["Int"]["output"];
  records: Array<Property>;
  total: Scalars["Int"]["output"];
};

export enum PropertyOrderByEnum {
  AdministratorAsc = "administrator_ASC",
  AdministratorDesc = "administrator_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  OwnerAsc = "owner_ASC",
  OwnerDesc = "owner_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
}

export type PropertyWhereInput = {
  archived?: InputMaybe<Scalars["Boolean"]["input"]>;
  byAdministrator?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  byId?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  byOwner?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  search?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query = {
  __typename?: "Query";
  apartment?: Maybe<Apartment>;
  apartments: ApartmentList;
  case?: Maybe<CaseInterface>;
  caseDefinitions: Array<CaseDefinition>;
  cases: CaseList;
  companies: CompanyList;
  currentUser?: Maybe<User>;
  economicDebtors: EconomicDebtorList;
  economicSubscribers: EconomicSubscriberList;
  economicSubscription?: Maybe<EconomicSubscription>;
  economicSubscriptions: EconomicSubscriptionList;
  interestListEntries: InterestListEntryList;
  properties: PropertyList;
  teamMembers: UserList;
  tenancies: TenancyList;
  tenancy?: Maybe<Tenancy>;
  textMacros: Array<TextMacro>;
};

export type QueryApartmentArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryApartmentsArgs = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<ApartmentOrderByEnum>;
  where?: InputMaybe<ApartmentWhereInput>;
};

export type QueryCaseArgs = {
  caseId: Scalars["ID"]["input"];
};

export type QueryCasesArgs = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<CaseOrderByEnum>;
  where?: InputMaybe<CaseWhereInput>;
};

export type QueryCompaniesArgs = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<CompanyOrderByEnum>;
  where?: InputMaybe<CompanyWhereInput>;
};

export type QueryEconomicDebtorsArgs = {
  where?: InputMaybe<EconomicDebtorWhereInput>;
};

export type QueryEconomicSubscribersArgs = {
  where?: InputMaybe<EconomicSubscriberWhereInput>;
};

export type QueryEconomicSubscriptionArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryEconomicSubscriptionsArgs = {
  where?: InputMaybe<EconomicSubscriptionWhereInput>;
};

export type QueryInterestListEntriesArgs = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<InterestListEntryOrderByEnum>;
  where?: InputMaybe<InterestListEntryWhereInput>;
};

export type QueryPropertiesArgs = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<PropertyOrderByEnum>;
  where?: InputMaybe<PropertyWhereInput>;
};

export type QueryTeamMembersArgs = {
  where?: InputMaybe<TeamMemberWhereInput>;
};

export type QueryTenanciesArgs = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<TenancyOrderByEnum>;
  where?: InputMaybe<TenancyWhereInput>;
};

export type QueryTenancyArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryTextMacrosArgs = {
  available: Array<Scalars["String"]["input"]>;
};

export type RecordInterface = {
  createdAt: Scalars["ISO8601DateTime"]["output"];
  id: Scalars["ID"]["output"];
  updatedAt: Scalars["ISO8601DateTime"]["output"];
};

export type RequestTenancy = CaseInterface & {
  __typename?: "RequestTenancy";
  apartment?: Maybe<Apartment>;
  attachments: Array<CaseAttachment>;
  attention: CaseAttentionEnum;
  awaiting?: Maybe<Scalars["String"]["output"]>;
  body: Scalars["String"]["output"];
  calendarDate?: Maybe<Scalars["String"]["output"]>;
  calendarRange: DateRange;
  comment?: Maybe<Scalars["String"]["output"]>;
  comments: Array<CaseComment>;
  company?: Maybe<Company>;
  completeBefore?: Maybe<Scalars["String"]["output"]>;
  createTenancyCases: Array<CreateTenancy>;
  createdAt: Scalars["ISO8601DateTime"]["output"];
  endDate?: Maybe<Scalars["ISO8601Date"]["output"]>;
  icon: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  inspection?: Maybe<Inspection>;
  isArchived: Scalars["Boolean"]["output"];
  isAttending?: Maybe<Scalars["Boolean"]["output"]>;
  isService: Scalars["Boolean"]["output"];
  mayAddComment: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  numberOfResidents: Scalars["Int"]["output"];
  parent?: Maybe<CaseInterface>;
  partnerProduct?: Maybe<PartnerProduct>;
  property?: Maybe<Property>;
  requester?: Maybe<User>;
  residents: Array<Resident>;
  responsible?: Maybe<User>;
  scheduledDate?: Maybe<Scalars["String"]["output"]>;
  scheduledTime?: Maybe<Scalars["String"]["output"]>;
  serviceDate?: Maybe<Scalars["ISO8601Date"]["output"]>;
  serviceEmployee?: Maybe<Scalars["String"]["output"]>;
  serviceTime?: Maybe<Scalars["String"]["output"]>;
  shortName: Scalars["String"]["output"];
  startAfter?: Maybe<Scalars["String"]["output"]>;
  startDate: Scalars["ISO8601Date"]["output"];
  state: Scalars["String"]["output"];
  stateLastChangedAt?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  stateText: Scalars["String"]["output"];
  tenancy?: Maybe<Tenancy>;
  tenantWillAttend?: Maybe<Scalars["Boolean"]["output"]>;
  title: Scalars["String"]["output"];
  totalCommentsCount: Scalars["Int"]["output"];
  type: CaseTypeEnum;
  unreadCommentsCount: Scalars["Int"]["output"];
  updatedAt: Scalars["ISO8601DateTime"]["output"];
};

export type Resident = {
  __typename?: "Resident";
  email?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["ID"]["output"]>;
  name: Scalars["String"]["output"];
  phone?: Maybe<Scalars["String"]["output"]>;
};

/** Autogenerated return type of SaveInspectionAnswerMutation. */
export type SaveInspectionAnswerMutationPayload = {
  __typename?: "SaveInspectionAnswerMutationPayload";
  errors: Array<Scalars["String"]["output"]>;
  result?: Maybe<InspectionAnswer>;
};

/** Autogenerated return type of SavePaymentMutation. */
export type SavePaymentMutationPayload = {
  __typename?: "SavePaymentMutationPayload";
  errors: Array<Scalars["String"]["output"]>;
  payment?: Maybe<Payment>;
};

/** Autogenerated return type of SaveTextMacroMutation. */
export type SaveTextMacroMutationPayload = {
  __typename?: "SaveTextMacroMutationPayload";
  errors: Array<Scalars["String"]["output"]>;
  textMacro?: Maybe<TextMacro>;
};

export type Shortcut = {
  __typename?: "Shortcut";
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  state: Scalars["JSON"]["output"];
  type: Scalars["String"]["output"];
};

export type ShortcutAttributesInput = {
  name: Scalars["String"]["input"];
  state: Scalars["JSON"]["input"];
  type: Scalars["String"]["input"];
};

/** The connection type for Shortcut. */
export type ShortcutConnection = {
  __typename?: "ShortcutConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ShortcutEdge>>>;
  /** A list of nodes. */
  nodes: Array<Shortcut>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars["Int"]["output"]>;
};

/** Autogenerated return type of ShortcutCreateMutation. */
export type ShortcutCreateMutationPayload = {
  __typename?: "ShortcutCreateMutationPayload";
  errors?: Maybe<Array<Scalars["String"]["output"]>>;
  shortcut?: Maybe<Shortcut>;
};

/** Autogenerated return type of ShortcutDeleteMutation. */
export type ShortcutDeleteMutationPayload = {
  __typename?: "ShortcutDeleteMutationPayload";
  errors?: Maybe<Array<Scalars["String"]["output"]>>;
  shortcutId?: Maybe<Scalars["ID"]["output"]>;
};

/** An edge in a connection. */
export type ShortcutEdge = {
  __typename?: "ShortcutEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node?: Maybe<Shortcut>;
};

/** Autogenerated return type of ShortcutUpdate. */
export type ShortcutUpdatePayload = {
  __typename?: "ShortcutUpdatePayload";
  errors?: Maybe<Array<Scalars["String"]["output"]>>;
  shortcut?: Maybe<Shortcut>;
};

export type ShortcutWhereInput = {
  query?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
};

export type TeamMemberWhereInput = {
  caseWorkers?: InputMaybe<Scalars["Boolean"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
};

export type Tenancy = RecordInterface & {
  __typename?: "Tenancy";
  apartment: Apartment;
  cases: CaseList;
  contractCase?: Maybe<CreateTenancy>;
  createdAt: Scalars["ISO8601DateTime"]["output"];
  depositAmount?: Maybe<Scalars["Int"]["output"]>;
  depositDueDate?: Maybe<Scalars["String"]["output"]>;
  description: Scalars["String"]["output"];
  documents?: Maybe<Array<Document>>;
  economic: TenancyEconomic;
  electricityAmount?: Maybe<Scalars["Int"]["output"]>;
  endDate?: Maybe<Scalars["String"]["output"]>;
  endTenancyTerms?: Maybe<TenancyTerms>;
  endTenancyTermsCondition?: Maybe<Scalars["String"]["output"]>;
  expectedMovingInCases: Array<CaseDefinition>;
  expectedMovingOutCases: Array<CaseDefinition>;
  hasEndTenancyCase: Scalars["Boolean"]["output"];
  heatingAmount?: Maybe<Scalars["Int"]["output"]>;
  id: Scalars["ID"]["output"];
  intervalAmount?: Maybe<Scalars["Int"]["output"]>;
  intervalAmountType: TenancyIntervalAmountTypeEnum;
  lastPaymentDate?: Maybe<Scalars["String"]["output"]>;
  movingInCase?: Maybe<Case>;
  movingOutCase?: Maybe<Case>;
  notes: TenancyNoteList;
  paymentAttention: TenancyPaymentAttentionEnum;
  payments: Array<Payment>;
  removable: Scalars["Boolean"]["output"];
  requestedEndDate?: Maybe<Scalars["String"]["output"]>;
  residents: Array<Resident>;
  roles: Array<TenancyRole>;
  startDate: Scalars["String"]["output"];
  updatedAt: Scalars["ISO8601DateTime"]["output"];
  user: User;
  waterAmount?: Maybe<Scalars["Int"]["output"]>;
};

export type TenancyCasesArgs = {
  orderBy?: InputMaybe<CaseOrderByEnum>;
  where?: InputMaybe<CaseWhereInput>;
};

export type TenancyEndTenancyTermsArgs = {
  endDate?: InputMaybe<Scalars["ISO8601Date"]["input"]>;
};

/** Autogenerated return type of TenancyDebtorLinkMutation. */
export type TenancyDebtorLinkMutationPayload = {
  __typename?: "TenancyDebtorLinkMutationPayload";
  errors?: Maybe<Array<Scalars["String"]["output"]>>;
  isLinked?: Maybe<Scalars["Boolean"]["output"]>;
};

/** Autogenerated return type of TenancyDebtorUnlinkMutation. */
export type TenancyDebtorUnlinkMutationPayload = {
  __typename?: "TenancyDebtorUnlinkMutationPayload";
  errors: Array<Scalars["String"]["output"]>;
  isUnlinked: Scalars["Boolean"]["output"];
};

export type TenancyEconomic = {
  __typename?: "TenancyEconomic";
  advanceElectricityAmount?: Maybe<Scalars["Float"]["output"]>;
  advanceElectricityIntervalEndAt?: Maybe<Scalars["ISO8601Date"]["output"]>;
  advanceElectricityIntervalStartAt?: Maybe<Scalars["ISO8601Date"]["output"]>;
  advanceElectricitySignedUpAt?: Maybe<Scalars["ISO8601Date"]["output"]>;
  advanceHeatingAmount?: Maybe<Scalars["Float"]["output"]>;
  advanceHeatingIntervalEndAt?: Maybe<Scalars["ISO8601Date"]["output"]>;
  advanceHeatingIntervalStartAt?: Maybe<Scalars["ISO8601Date"]["output"]>;
  advanceHeatingSignedUpAt?: Maybe<Scalars["ISO8601Date"]["output"]>;
  advanceWasteWaterAmount?: Maybe<Scalars["Float"]["output"]>;
  advanceWasteWaterIntervalEndAt?: Maybe<Scalars["ISO8601Date"]["output"]>;
  advanceWasteWaterIntervalStartAt?: Maybe<Scalars["ISO8601Date"]["output"]>;
  advanceWasteWaterSignedUpAt?: Maybe<Scalars["ISO8601Date"]["output"]>;
  advanceWaterAmount?: Maybe<Scalars["Float"]["output"]>;
  advanceWaterIntervalEndAt?: Maybe<Scalars["ISO8601Date"]["output"]>;
  advanceWaterIntervalStartAt?: Maybe<Scalars["ISO8601Date"]["output"]>;
  advanceWaterSignedUpAt?: Maybe<Scalars["ISO8601Date"]["output"]>;
  commercialRentAmount?: Maybe<Scalars["Float"]["output"]>;
  commercialRentIntervalEndAt?: Maybe<Scalars["ISO8601Date"]["output"]>;
  commercialRentIntervalStartAt?: Maybe<Scalars["ISO8601Date"]["output"]>;
  commercialRentSignedUpAt?: Maybe<Scalars["ISO8601Date"]["output"]>;
  companySyncedAt?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  debtor?: Maybe<EconomicDebtor>;
  deposit?: Maybe<Scalars["Float"]["output"]>;
  depositAmount?: Maybe<Scalars["Float"]["output"]>;
  depositIntervalEndAt?: Maybe<Scalars["ISO8601Date"]["output"]>;
  depositIntervalStartAt?: Maybe<Scalars["ISO8601Date"]["output"]>;
  depositSignedUpAt?: Maybe<Scalars["ISO8601Date"]["output"]>;
  otherAmount?: Maybe<Scalars["Float"]["output"]>;
  otherIntervalEndAt?: Maybe<Scalars["ISO8601Date"]["output"]>;
  otherIntervalStartAt?: Maybe<Scalars["ISO8601Date"]["output"]>;
  otherSignedUpAt?: Maybe<Scalars["ISO8601Date"]["output"]>;
  parkingAmount?: Maybe<Scalars["Float"]["output"]>;
  parkingIntervalEndAt?: Maybe<Scalars["ISO8601Date"]["output"]>;
  parkingIntervalStartAt?: Maybe<Scalars["ISO8601Date"]["output"]>;
  parkingSignedUpAt?: Maybe<Scalars["ISO8601Date"]["output"]>;
  prepaidRent?: Maybe<Scalars["Float"]["output"]>;
  regulationDepositAmount?: Maybe<Scalars["Float"]["output"]>;
  regulationDepositIntervalEndAt?: Maybe<Scalars["ISO8601Date"]["output"]>;
  regulationDepositIntervalStartAt?: Maybe<Scalars["ISO8601Date"]["output"]>;
  regulationDepositSignedUpAt?: Maybe<Scalars["ISO8601Date"]["output"]>;
  regulationPrepaidRentAmount?: Maybe<Scalars["Float"]["output"]>;
  regulationPrepaidRentIntervalEndAt?: Maybe<Scalars["ISO8601Date"]["output"]>;
  regulationPrepaidRentIntervalStartAt?: Maybe<
    Scalars["ISO8601Date"]["output"]
  >;
  regulationPrepaidRentSignedUpAt?: Maybe<Scalars["ISO8601Date"]["output"]>;
  rentAmount?: Maybe<Scalars["Float"]["output"]>;
  rentIntervalEndAt?: Maybe<Scalars["ISO8601Date"]["output"]>;
  rentIntervalStartAt?: Maybe<Scalars["ISO8601Date"]["output"]>;
  rentReductionAmount?: Maybe<Scalars["Float"]["output"]>;
  rentReductionIntervalEndAt?: Maybe<Scalars["ISO8601Date"]["output"]>;
  rentReductionIntervalStartAt?: Maybe<Scalars["ISO8601Date"]["output"]>;
  rentReductionSignedUpAt?: Maybe<Scalars["ISO8601Date"]["output"]>;
  rentSignedUpAt?: Maybe<Scalars["ISO8601Date"]["output"]>;
  settlementElectricityAmount?: Maybe<Scalars["Float"]["output"]>;
  settlementElectricityIntervalEndAt?: Maybe<Scalars["ISO8601Date"]["output"]>;
  settlementElectricityIntervalStartAt?: Maybe<
    Scalars["ISO8601Date"]["output"]
  >;
  settlementElectricitySignedUpAt?: Maybe<Scalars["ISO8601Date"]["output"]>;
  settlementHeatingAmount?: Maybe<Scalars["Float"]["output"]>;
  settlementHeatingIntervalEndAt?: Maybe<Scalars["ISO8601Date"]["output"]>;
  settlementHeatingIntervalStartAt?: Maybe<Scalars["ISO8601Date"]["output"]>;
  settlementHeatingSignedUpAt?: Maybe<Scalars["ISO8601Date"]["output"]>;
  settlementWasteWaterAmount?: Maybe<Scalars["Float"]["output"]>;
  settlementWasteWaterIntervalEndAt?: Maybe<Scalars["ISO8601Date"]["output"]>;
  settlementWasteWaterIntervalStartAt?: Maybe<Scalars["ISO8601Date"]["output"]>;
  settlementWasteWaterSignedUpAt?: Maybe<Scalars["ISO8601Date"]["output"]>;
  settlementWaterAmount?: Maybe<Scalars["Float"]["output"]>;
  settlementWaterIntervalEndAt?: Maybe<Scalars["ISO8601Date"]["output"]>;
  settlementWaterIntervalStartAt?: Maybe<Scalars["ISO8601Date"]["output"]>;
  settlementWaterSignedUpAt?: Maybe<Scalars["ISO8601Date"]["output"]>;
  subscription?: Maybe<TenancyEconomicSubscription>;
  subscriptions?: Maybe<Array<TenancyEconomicSubscription>>;
};

export type TenancyEconomicSubscriptionArgs = {
  id: Scalars["ID"]["input"];
};

export type TenancyEconomicSubscription = {
  __typename?: "TenancyEconomicSubscription";
  currentAmount?: Maybe<Scalars["Float"]["output"]>;
  history?: Maybe<Array<EconomicSubscriber>>;
  id: Scalars["ID"]["output"];
  interval?: Maybe<EconomicSubscriptionIntervalEnum>;
  name: Scalars["String"]["output"];
};

export enum TenancyIntervalAmountTypeEnum {
  Contract = "contract",
  Economic = "economic",
  Unknown = "unknown",
}

export type TenancyList = {
  __typename?: "TenancyList";
  count: Scalars["Int"]["output"];
  records: Array<Tenancy>;
  total: Scalars["Int"]["output"];
};

export type TenancyNote = {
  __typename?: "TenancyNote";
  attachments: Array<File>;
  content: Scalars["String"]["output"];
  createdAt: Scalars["ISO8601DateTime"]["output"];
  id: Scalars["ID"]["output"];
  updatedAt: Scalars["ISO8601DateTime"]["output"];
  user: User;
};

/** Autogenerated return type of TenancyNoteCreateMutation. */
export type TenancyNoteCreateMutationPayload = {
  __typename?: "TenancyNoteCreateMutationPayload";
  errors: Array<Scalars["String"]["output"]>;
  tenancyNote?: Maybe<TenancyNote>;
};

/** Autogenerated return type of TenancyNoteDeleteMutation. */
export type TenancyNoteDeleteMutationPayload = {
  __typename?: "TenancyNoteDeleteMutationPayload";
  errors: Array<Scalars["String"]["output"]>;
  tenancyNote?: Maybe<TenancyNote>;
};

export type TenancyNoteList = {
  __typename?: "TenancyNoteList";
  count: Scalars["Int"]["output"];
  records: Array<TenancyNote>;
  total: Scalars["Int"]["output"];
};

/** Autogenerated return type of TenancyNoteUpdateMutation. */
export type TenancyNoteUpdateMutationPayload = {
  __typename?: "TenancyNoteUpdateMutationPayload";
  errors: Array<Scalars["String"]["output"]>;
  tenancyNote?: Maybe<TenancyNote>;
};

export enum TenancyOrderByEnum {
  ApartmentPublicIdAsc = "apartmentPublicId_ASC",
  ApartmentPublicIdDesc = "apartmentPublicId_DESC",
  ApartmentAsc = "apartment_ASC",
  ApartmentDesc = "apartment_DESC",
  CprAsc = "cpr_ASC",
  CprDesc = "cpr_DESC",
  DepositAmountAsc = "depositAmount_ASC",
  DepositAmountDesc = "depositAmount_DESC",
  DepositDueDateAsc = "depositDueDate_ASC",
  DepositDueDateDesc = "depositDueDate_DESC",
  DepositAsc = "deposit_ASC",
  DepositDesc = "deposit_DESC",
  EconomicAdvanceElectricityAmountAsc = "economicAdvanceElectricityAmount_ASC",
  EconomicAdvanceElectricityAmountDesc = "economicAdvanceElectricityAmount_DESC",
  EconomicAdvanceElectricityIntervalEndAtAsc = "economicAdvanceElectricityIntervalEndAt_ASC",
  EconomicAdvanceElectricityIntervalEndAtDesc = "economicAdvanceElectricityIntervalEndAt_DESC",
  EconomicAdvanceElectricityIntervalStartAtAsc = "economicAdvanceElectricityIntervalStartAt_ASC",
  EconomicAdvanceElectricityIntervalStartAtDesc = "economicAdvanceElectricityIntervalStartAt_DESC",
  EconomicAdvanceHeatingAmountAsc = "economicAdvanceHeatingAmount_ASC",
  EconomicAdvanceHeatingAmountDesc = "economicAdvanceHeatingAmount_DESC",
  EconomicAdvanceHeatingIntervalEndAtAsc = "economicAdvanceHeatingIntervalEndAt_ASC",
  EconomicAdvanceHeatingIntervalEndAtDesc = "economicAdvanceHeatingIntervalEndAt_DESC",
  EconomicAdvanceHeatingIntervalStartAtAsc = "economicAdvanceHeatingIntervalStartAt_ASC",
  EconomicAdvanceHeatingIntervalStartAtDesc = "economicAdvanceHeatingIntervalStartAt_DESC",
  EconomicAdvanceWasteWaterAmountAsc = "economicAdvanceWasteWaterAmount_ASC",
  EconomicAdvanceWasteWaterAmountDesc = "economicAdvanceWasteWaterAmount_DESC",
  EconomicAdvanceWasteWaterIntervalEndAtAsc = "economicAdvanceWasteWaterIntervalEndAt_ASC",
  EconomicAdvanceWasteWaterIntervalEndAtDesc = "economicAdvanceWasteWaterIntervalEndAt_DESC",
  EconomicAdvanceWasteWaterIntervalStartAtAsc = "economicAdvanceWasteWaterIntervalStartAt_ASC",
  EconomicAdvanceWasteWaterIntervalStartAtDesc = "economicAdvanceWasteWaterIntervalStartAt_DESC",
  EconomicAdvanceWaterAmountAsc = "economicAdvanceWaterAmount_ASC",
  EconomicAdvanceWaterAmountDesc = "economicAdvanceWaterAmount_DESC",
  EconomicAdvanceWaterIntervalEndAtAsc = "economicAdvanceWaterIntervalEndAt_ASC",
  EconomicAdvanceWaterIntervalEndAtDesc = "economicAdvanceWaterIntervalEndAt_DESC",
  EconomicAdvanceWaterIntervalStartAtAsc = "economicAdvanceWaterIntervalStartAt_ASC",
  EconomicAdvanceWaterIntervalStartAtDesc = "economicAdvanceWaterIntervalStartAt_DESC",
  EconomicCommercialRentAmountAsc = "economicCommercialRentAmount_ASC",
  EconomicCommercialRentAmountDesc = "economicCommercialRentAmount_DESC",
  EconomicCommercialRentIntervalEndAtAsc = "economicCommercialRentIntervalEndAt_ASC",
  EconomicCommercialRentIntervalEndAtDesc = "economicCommercialRentIntervalEndAt_DESC",
  EconomicCommercialRentIntervalStartAtAsc = "economicCommercialRentIntervalStartAt_ASC",
  EconomicCommercialRentIntervalStartAtDesc = "economicCommercialRentIntervalStartAt_DESC",
  EconomicDepositAmountAsc = "economicDepositAmount_ASC",
  EconomicDepositAmountDesc = "economicDepositAmount_DESC",
  EconomicDepositIntervalEndAtAsc = "economicDepositIntervalEndAt_ASC",
  EconomicDepositIntervalEndAtDesc = "economicDepositIntervalEndAt_DESC",
  EconomicDepositIntervalStartAtAsc = "economicDepositIntervalStartAt_ASC",
  EconomicDepositIntervalStartAtDesc = "economicDepositIntervalStartAt_DESC",
  EconomicOtherAmountAsc = "economicOtherAmount_ASC",
  EconomicOtherAmountDesc = "economicOtherAmount_DESC",
  EconomicOtherIntervalEndAtAsc = "economicOtherIntervalEndAt_ASC",
  EconomicOtherIntervalEndAtDesc = "economicOtherIntervalEndAt_DESC",
  EconomicOtherIntervalStartAtAsc = "economicOtherIntervalStartAt_ASC",
  EconomicOtherIntervalStartAtDesc = "economicOtherIntervalStartAt_DESC",
  EconomicParkingAmountAsc = "economicParkingAmount_ASC",
  EconomicParkingAmountDesc = "economicParkingAmount_DESC",
  EconomicParkingIntervalEndAtAsc = "economicParkingIntervalEndAt_ASC",
  EconomicParkingIntervalEndAtDesc = "economicParkingIntervalEndAt_DESC",
  EconomicParkingIntervalStartAtAsc = "economicParkingIntervalStartAt_ASC",
  EconomicParkingIntervalStartAtDesc = "economicParkingIntervalStartAt_DESC",
  EconomicRegulationDepositAmountAsc = "economicRegulationDepositAmount_ASC",
  EconomicRegulationDepositAmountDesc = "economicRegulationDepositAmount_DESC",
  EconomicRegulationDepositIntervalEndAtAsc = "economicRegulationDepositIntervalEndAt_ASC",
  EconomicRegulationDepositIntervalEndAtDesc = "economicRegulationDepositIntervalEndAt_DESC",
  EconomicRegulationDepositIntervalStartAtAsc = "economicRegulationDepositIntervalStartAt_ASC",
  EconomicRegulationDepositIntervalStartAtDesc = "economicRegulationDepositIntervalStartAt_DESC",
  EconomicRegulationPrepaidRentAmountAsc = "economicRegulationPrepaidRentAmount_ASC",
  EconomicRegulationPrepaidRentAmountDesc = "economicRegulationPrepaidRentAmount_DESC",
  EconomicRegulationPrepaidRentIntervalEndAtAsc = "economicRegulationPrepaidRentIntervalEndAt_ASC",
  EconomicRegulationPrepaidRentIntervalEndAtDesc = "economicRegulationPrepaidRentIntervalEndAt_DESC",
  EconomicRegulationPrepaidRentIntervalStartAtAsc = "economicRegulationPrepaidRentIntervalStartAt_ASC",
  EconomicRegulationPrepaidRentIntervalStartAtDesc = "economicRegulationPrepaidRentIntervalStartAt_DESC",
  EconomicRentAmountAsc = "economicRentAmount_ASC",
  EconomicRentAmountDesc = "economicRentAmount_DESC",
  EconomicRentIntervalEndAtAsc = "economicRentIntervalEndAt_ASC",
  EconomicRentIntervalEndAtDesc = "economicRentIntervalEndAt_DESC",
  EconomicRentIntervalStartAtAsc = "economicRentIntervalStartAt_ASC",
  EconomicRentIntervalStartAtDesc = "economicRentIntervalStartAt_DESC",
  EconomicRentReductionAmountAsc = "economicRentReductionAmount_ASC",
  EconomicRentReductionAmountDesc = "economicRentReductionAmount_DESC",
  EconomicRentReductionIntervalEndAtAsc = "economicRentReductionIntervalEndAt_ASC",
  EconomicRentReductionIntervalEndAtDesc = "economicRentReductionIntervalEndAt_DESC",
  EconomicRentReductionIntervalStartAtAsc = "economicRentReductionIntervalStartAt_ASC",
  EconomicRentReductionIntervalStartAtDesc = "economicRentReductionIntervalStartAt_DESC",
  EconomicSettlementElectricityAmountAsc = "economicSettlementElectricityAmount_ASC",
  EconomicSettlementElectricityAmountDesc = "economicSettlementElectricityAmount_DESC",
  EconomicSettlementElectricityIntervalEndAtAsc = "economicSettlementElectricityIntervalEndAt_ASC",
  EconomicSettlementElectricityIntervalEndAtDesc = "economicSettlementElectricityIntervalEndAt_DESC",
  EconomicSettlementElectricityIntervalStartAtAsc = "economicSettlementElectricityIntervalStartAt_ASC",
  EconomicSettlementElectricityIntervalStartAtDesc = "economicSettlementElectricityIntervalStartAt_DESC",
  EconomicSettlementHeatingAmountAsc = "economicSettlementHeatingAmount_ASC",
  EconomicSettlementHeatingAmountDesc = "economicSettlementHeatingAmount_DESC",
  EconomicSettlementHeatingIntervalEndAtAsc = "economicSettlementHeatingIntervalEndAt_ASC",
  EconomicSettlementHeatingIntervalEndAtDesc = "economicSettlementHeatingIntervalEndAt_DESC",
  EconomicSettlementHeatingIntervalStartAtAsc = "economicSettlementHeatingIntervalStartAt_ASC",
  EconomicSettlementHeatingIntervalStartAtDesc = "economicSettlementHeatingIntervalStartAt_DESC",
  EconomicSettlementWasteWaterAmountAsc = "economicSettlementWasteWaterAmount_ASC",
  EconomicSettlementWasteWaterAmountDesc = "economicSettlementWasteWaterAmount_DESC",
  EconomicSettlementWasteWaterIntervalEndAtAsc = "economicSettlementWasteWaterIntervalEndAt_ASC",
  EconomicSettlementWasteWaterIntervalEndAtDesc = "economicSettlementWasteWaterIntervalEndAt_DESC",
  EconomicSettlementWasteWaterIntervalStartAtAsc = "economicSettlementWasteWaterIntervalStartAt_ASC",
  EconomicSettlementWasteWaterIntervalStartAtDesc = "economicSettlementWasteWaterIntervalStartAt_DESC",
  EconomicSettlementWaterAmountAsc = "economicSettlementWaterAmount_ASC",
  EconomicSettlementWaterAmountDesc = "economicSettlementWaterAmount_DESC",
  EconomicSettlementWaterIntervalEndAtAsc = "economicSettlementWaterIntervalEndAt_ASC",
  EconomicSettlementWaterIntervalEndAtDesc = "economicSettlementWaterIntervalEndAt_DESC",
  EconomicSettlementWaterIntervalStartAtAsc = "economicSettlementWaterIntervalStartAt_ASC",
  EconomicSettlementWaterIntervalStartAtDesc = "economicSettlementWaterIntervalStartAt_DESC",
  EmailAsc = "email_ASC",
  EmailDesc = "email_DESC",
  EndDateAsc = "endDate_ASC",
  EndDateDesc = "endDate_DESC",
  IntervalAmountAsc = "intervalAmount_ASC",
  IntervalAmountDesc = "intervalAmount_DESC",
  LastPaymentDateAsc = "lastPaymentDate_ASC",
  LastPaymentDateDesc = "lastPaymentDate_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  PhoneAsc = "phone_ASC",
  PhoneDesc = "phone_DESC",
  PrepaidRentAsc = "prepaidRent_ASC",
  PrepaidRentDesc = "prepaidRent_DESC",
  RequestedEndDateAsc = "requestedEndDate_ASC",
  RequestedEndDateDesc = "requestedEndDate_DESC",
  StartDateAsc = "startDate_ASC",
  StartDateDesc = "startDate_DESC",
}

export enum TenancyPaymentAttentionEnum {
  Critical = "critical",
  Normal = "normal",
  Success = "success",
}

/** Autogenerated return type of TenancyRemoveMutation. */
export type TenancyRemoveMutationPayload = {
  __typename?: "TenancyRemoveMutationPayload";
  errors: Array<Scalars["String"]["output"]>;
  tenancy?: Maybe<Tenancy>;
};

/** Autogenerated return type of TenancyResidentCreateMutation. */
export type TenancyResidentCreateMutationPayload = {
  __typename?: "TenancyResidentCreateMutationPayload";
  errors?: Maybe<Array<Scalars["String"]["output"]>>;
  resident?: Maybe<Resident>;
};

/** Autogenerated return type of TenancyResidentRemoveMutation. */
export type TenancyResidentRemoveMutationPayload = {
  __typename?: "TenancyResidentRemoveMutationPayload";
  errors?: Maybe<Array<Scalars["String"]["output"]>>;
  resident?: Maybe<Resident>;
};

/** Autogenerated return type of TenancyResidentUpdateMutation. */
export type TenancyResidentUpdateMutationPayload = {
  __typename?: "TenancyResidentUpdateMutationPayload";
  errors?: Maybe<Array<Scalars["String"]["output"]>>;
  resident?: Maybe<Resident>;
};

export type TenancyRole = {
  __typename?: "TenancyRole";
  id: Scalars["ID"]["output"];
  isPrimary: Scalars["Boolean"]["output"];
  isResident: Scalars["Boolean"]["output"];
  user: User;
};

/** Autogenerated return type of TenancyRoleCreateMutation. */
export type TenancyRoleCreateMutationPayload = {
  __typename?: "TenancyRoleCreateMutationPayload";
  errors?: Maybe<Array<Scalars["String"]["output"]>>;
  tenancyRole?: Maybe<TenancyRole>;
};

/** Autogenerated return type of TenancyRoleRemoveMutation. */
export type TenancyRoleRemoveMutationPayload = {
  __typename?: "TenancyRoleRemoveMutationPayload";
  errors?: Maybe<Array<Scalars["String"]["output"]>>;
  tenancyRole?: Maybe<TenancyRole>;
};

/** Autogenerated return type of TenancyRoleUpdateMutation. */
export type TenancyRoleUpdateMutationPayload = {
  __typename?: "TenancyRoleUpdateMutationPayload";
  errors?: Maybe<Array<Scalars["String"]["output"]>>;
  tenancyRole?: Maybe<TenancyRole>;
};

export type TenancyTerms = {
  __typename?: "TenancyTerms";
  earlyEndDate?: Maybe<Scalars["Boolean"]["output"]>;
  endDate?: Maybe<Scalars["String"]["output"]>;
  endDateLongFormat?: Maybe<Scalars["String"]["output"]>;
  lastRentDate?: Maybe<Scalars["String"]["output"]>;
  lastRentDateLongFormat?: Maybe<Scalars["String"]["output"]>;
  leaveDate?: Maybe<Scalars["String"]["output"]>;
  leaveDateLongFormat?: Maybe<Scalars["String"]["output"]>;
};

/** Autogenerated return type of TenancyUpdateMutation. */
export type TenancyUpdateMutationPayload = {
  __typename?: "TenancyUpdateMutationPayload";
  errors?: Maybe<Array<Scalars["String"]["output"]>>;
  tenancy?: Maybe<Tenancy>;
};

export type TenancyWhereInput = {
  approved?: InputMaybe<Scalars["Boolean"]["input"]>;
  archived?: InputMaybe<Scalars["Boolean"]["input"]>;
  byAdministrator?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  byApartment?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  byPaymentAttention?: InputMaybe<Array<TenancyPaymentAttentionEnum>>;
  byProperty?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  conflictingWithDateRange?: InputMaybe<DateRangeInput>;
  search?: InputMaybe<Scalars["String"]["input"]>;
};

export type TextMacro = {
  __typename?: "TextMacro";
  available: Scalars["String"]["output"];
  content: Scalars["String"]["output"];
  createdAt: Scalars["ISO8601DateTime"]["output"];
  id: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  updatedAt: Scalars["ISO8601DateTime"]["output"];
};

/** Autogenerated return type of ToggleCaseFlagMutation. */
export type ToggleCaseFlagMutationPayload = {
  __typename?: "ToggleCaseFlagMutationPayload";
  errors: Array<Scalars["String"]["output"]>;
};

/** Autogenerated return type of UpdateExternalUserMutation. */
export type UpdateExternalUserMutationPayload = {
  __typename?: "UpdateExternalUserMutationPayload";
  errors?: Maybe<Array<Scalars["String"]["output"]>>;
  user?: Maybe<User>;
};

export type User = RecordInterface & {
  __typename?: "User";
  address?: Maybe<Scalars["String"]["output"]>;
  bankAccount?: Maybe<BankAccount>;
  city?: Maybe<Scalars["String"]["output"]>;
  cpr?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["ISO8601DateTime"]["output"];
  email: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  isAdministrator: Scalars["Boolean"]["output"];
  isExternal: Scalars["Boolean"]["output"];
  isPhoneConfirmed: Scalars["Boolean"]["output"];
  isSystemAdministrator: Scalars["Boolean"]["output"];
  locale: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  personalAccessTokens: PersonalAccessTokenConnection;
  phone?: Maybe<Scalars["String"]["output"]>;
  postalCode?: Maybe<Scalars["String"]["output"]>;
  protected: Scalars["Boolean"]["output"];
  shortcut?: Maybe<Shortcut>;
  shortcuts: ShortcutConnection;
  updatedAt: Scalars["ISO8601DateTime"]["output"];
};

export type UserPersonalAccessTokensArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserShortcutArgs = {
  shortcutId: Scalars["ID"]["input"];
};

export type UserShortcutsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ShortcutWhereInput>;
};

export type UserList = {
  __typename?: "UserList";
  count: Scalars["Int"]["output"];
  records: Array<User>;
  total: Scalars["Int"]["output"];
};

export type UtilityAdministration = CaseInterface & {
  __typename?: "UtilityAdministration";
  apartment?: Maybe<Apartment>;
  attachments: Array<CaseAttachment>;
  attention: CaseAttentionEnum;
  awaiting?: Maybe<Scalars["String"]["output"]>;
  body: Scalars["String"]["output"];
  calendarDate?: Maybe<Scalars["String"]["output"]>;
  calendarRange: DateRange;
  comments: Array<CaseComment>;
  company?: Maybe<Company>;
  completeBefore?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["ISO8601DateTime"]["output"];
  icon: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  inspection?: Maybe<Inspection>;
  isArchived: Scalars["Boolean"]["output"];
  isAttending?: Maybe<Scalars["Boolean"]["output"]>;
  isService: Scalars["Boolean"]["output"];
  mayAddComment: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  parent?: Maybe<CaseInterface>;
  property?: Maybe<Property>;
  requester?: Maybe<User>;
  responsible?: Maybe<User>;
  scheduledDate?: Maybe<Scalars["String"]["output"]>;
  scheduledTime?: Maybe<Scalars["String"]["output"]>;
  serviceDate?: Maybe<Scalars["ISO8601Date"]["output"]>;
  serviceEmployee?: Maybe<Scalars["String"]["output"]>;
  serviceTime?: Maybe<Scalars["String"]["output"]>;
  shortName: Scalars["String"]["output"];
  startAfter?: Maybe<Scalars["String"]["output"]>;
  state: Scalars["String"]["output"];
  stateLastChangedAt?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  stateText: Scalars["String"]["output"];
  tenancy?: Maybe<Tenancy>;
  tenantWillAttend?: Maybe<Scalars["Boolean"]["output"]>;
  title: Scalars["String"]["output"];
  totalCommentsCount: Scalars["Int"]["output"];
  type: CaseTypeEnum;
  unreadCommentsCount: Scalars["Int"]["output"];
  updatedAt: Scalars["ISO8601DateTime"]["output"];
};

export type PersonalAccessTokenTokenFragmentFragment = {
  __typename?: "PersonalAccessToken";
  id: string;
  name: string;
  token?: string | null;
  createdAt: any;
} & { " $fragmentName"?: "PersonalAccessTokenTokenFragmentFragment" };

export type PersonalAccessTokensQueryQueryVariables = Exact<{
  [key: string]: never;
}>;

export type PersonalAccessTokensQueryQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "User";
    personalAccessTokens: {
      __typename?: "PersonalAccessTokenConnection";
      nodes: Array<
        { __typename?: "PersonalAccessToken" } & {
          " $fragmentRefs"?: {
            PersonalAccessTokenTokenFragmentFragment: PersonalAccessTokenTokenFragmentFragment;
          };
        }
      >;
    };
  } | null;
};

export type CreatePersonalAccessTokenMutationVariables = Exact<{
  name: Scalars["String"]["input"];
}>;

export type CreatePersonalAccessTokenMutation = {
  __typename?: "Mutation";
  result?: {
    __typename?: "PersonalAccessTokenCreateMutationPayload";
    errors?: Array<string> | null;
    personalAccessToken?: {
      __typename?: "PersonalAccessToken";
      id: string;
      name: string;
      token?: string | null;
    } | null;
  } | null;
};

export type PersonalAccessTokenDeleteMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type PersonalAccessTokenDeleteMutation = {
  __typename?: "Mutation";
  personalAccessTokenDelete?: {
    __typename?: "PersonalAccessTokenDeleteMutationPayload";
    errors: Array<string>;
    id: string;
  } | null;
};

export type DataTableApartmentsFragment = {
  __typename: "Apartment";
  id: string;
  publicId: string;
  address: string;
  city: string;
  key?: string | null;
  inactive?: boolean | null;
  inactiveStartDate?: string | null;
  inactiveEndDate?: string | null;
  inactiveDescription?: string | null;
  service: number;
  property: { __typename?: "Property"; name: string };
  previousTenancy?: {
    __typename?: "Tenancy";
    id: string;
    startDate: string;
    endDate?: string | null;
    requestedEndDate?: string | null;
    intervalAmount?: number | null;
    intervalAmountType: TenancyIntervalAmountTypeEnum;
    depositAmount?: number | null;
    depositDueDate?: string | null;
    heatingAmount?: number | null;
    waterAmount?: number | null;
    electricityAmount?: number | null;
    user: {
      __typename?: "User";
      name: string;
      email: string;
      phone?: string | null;
      protected: boolean;
    };
    expectedMovingInCases: Array<{
      __typename?: "CaseDefinition";
      type: CaseTypeEnum;
      name: string;
      icon: string;
    }>;
    expectedMovingOutCases: Array<{
      __typename?: "CaseDefinition";
      type: CaseTypeEnum;
      name: string;
      icon: string;
    }>;
    movingInCase?: {
      __typename?: "Case";
      id: string;
      icon: string;
      attention: CaseAttentionEnum;
      children: Array<{
        __typename?: "Case";
        id: string;
        type: CaseTypeEnum;
        shortName: string;
        icon: string;
        attention: CaseAttentionEnum;
      }>;
    } | null;
    movingOutCase?: {
      __typename?: "Case";
      id: string;
      icon: string;
      attention: CaseAttentionEnum;
      children: Array<{
        __typename?: "Case";
        id: string;
        type: CaseTypeEnum;
        shortName: string;
        icon: string;
        attention: CaseAttentionEnum;
      }>;
    } | null;
    contractCase?: {
      __typename?: "CreateTenancy";
      id: string;
      type: CaseTypeEnum;
      attention: CaseAttentionEnum;
      awaiting?: string | null;
      stateText: string;
    } | null;
    economic: {
      __typename?: "TenancyEconomic";
      rentAmount?: number | null;
      parkingAmount?: number | null;
      rentReductionAmount?: number | null;
      depositAmount?: number | null;
      regulationDepositAmount?: number | null;
      regulationPrepaidRentAmount?: number | null;
      advanceWasteWaterAmount?: number | null;
      advanceWaterAmount?: number | null;
      advanceHeatingAmount?: number | null;
      advanceElectricityAmount?: number | null;
      settlementWasteWaterAmount?: number | null;
      settlementWaterAmount?: number | null;
      settlementHeatingAmount?: number | null;
      settlementElectricityAmount?: number | null;
      otherAmount?: number | null;
      companySyncedAt?: any | null;
      deposit?: number | null;
      prepaidRent?: number | null;
      debtor?: { __typename?: "EconomicDebtor"; externalId: number } | null;
    };
  } | null;
  currentTenancy?: {
    __typename?: "Tenancy";
    id: string;
    startDate: string;
    endDate?: string | null;
    requestedEndDate?: string | null;
    depositAmount?: number | null;
    intervalAmount?: number | null;
    intervalAmountType: TenancyIntervalAmountTypeEnum;
    depositDueDate?: string | null;
    heatingAmount?: number | null;
    waterAmount?: number | null;
    electricityAmount?: number | null;
    user: {
      __typename?: "User";
      name: string;
      email: string;
      phone?: string | null;
      protected: boolean;
    };
    expectedMovingInCases: Array<{
      __typename?: "CaseDefinition";
      type: CaseTypeEnum;
      name: string;
      icon: string;
    }>;
    expectedMovingOutCases: Array<{
      __typename?: "CaseDefinition";
      type: CaseTypeEnum;
      name: string;
      icon: string;
    }>;
    movingInCase?: {
      __typename?: "Case";
      id: string;
      icon: string;
      attention: CaseAttentionEnum;
      children: Array<{
        __typename?: "Case";
        id: string;
        type: CaseTypeEnum;
        shortName: string;
        icon: string;
        attention: CaseAttentionEnum;
      }>;
    } | null;
    movingOutCase?: {
      __typename?: "Case";
      id: string;
      icon: string;
      attention: CaseAttentionEnum;
      children: Array<{
        __typename?: "Case";
        id: string;
        type: CaseTypeEnum;
        shortName: string;
        icon: string;
        attention: CaseAttentionEnum;
      }>;
    } | null;
    contractCase?: {
      __typename?: "CreateTenancy";
      id: string;
      type: CaseTypeEnum;
      attention: CaseAttentionEnum;
      awaiting?: string | null;
      stateText: string;
    } | null;
    economic: {
      __typename?: "TenancyEconomic";
      rentAmount?: number | null;
      parkingAmount?: number | null;
      rentReductionAmount?: number | null;
      depositAmount?: number | null;
      regulationDepositAmount?: number | null;
      regulationPrepaidRentAmount?: number | null;
      advanceWasteWaterAmount?: number | null;
      advanceWaterAmount?: number | null;
      advanceHeatingAmount?: number | null;
      advanceElectricityAmount?: number | null;
      settlementWasteWaterAmount?: number | null;
      settlementWaterAmount?: number | null;
      settlementHeatingAmount?: number | null;
      settlementElectricityAmount?: number | null;
      otherAmount?: number | null;
      companySyncedAt?: any | null;
      deposit?: number | null;
      prepaidRent?: number | null;
      debtor?: { __typename?: "EconomicDebtor"; externalId: number } | null;
    };
  } | null;
  nextTenancy?: {
    __typename?: "Tenancy";
    id: string;
    startDate: string;
    endDate?: string | null;
    requestedEndDate?: string | null;
    intervalAmount?: number | null;
    intervalAmountType: TenancyIntervalAmountTypeEnum;
    depositAmount?: number | null;
    depositDueDate?: string | null;
    heatingAmount?: number | null;
    waterAmount?: number | null;
    electricityAmount?: number | null;
    user: {
      __typename?: "User";
      name: string;
      email: string;
      phone?: string | null;
      protected: boolean;
    };
    expectedMovingInCases: Array<{
      __typename?: "CaseDefinition";
      type: CaseTypeEnum;
      name: string;
      icon: string;
    }>;
    expectedMovingOutCases: Array<{
      __typename?: "CaseDefinition";
      type: CaseTypeEnum;
      name: string;
      icon: string;
    }>;
    movingInCase?: {
      __typename?: "Case";
      id: string;
      icon: string;
      attention: CaseAttentionEnum;
      children: Array<{
        __typename?: "Case";
        id: string;
        type: CaseTypeEnum;
        shortName: string;
        icon: string;
        attention: CaseAttentionEnum;
      }>;
    } | null;
    movingOutCase?: {
      __typename?: "Case";
      id: string;
      icon: string;
      attention: CaseAttentionEnum;
      children: Array<{
        __typename?: "Case";
        id: string;
        type: CaseTypeEnum;
        shortName: string;
        icon: string;
        attention: CaseAttentionEnum;
      }>;
    } | null;
    contractCase?: {
      __typename?: "CreateTenancy";
      id: string;
      type: CaseTypeEnum;
      attention: CaseAttentionEnum;
      awaiting?: string | null;
      stateText: string;
    } | null;
    economic: {
      __typename?: "TenancyEconomic";
      rentAmount?: number | null;
      parkingAmount?: number | null;
      rentReductionAmount?: number | null;
      depositAmount?: number | null;
      regulationDepositAmount?: number | null;
      regulationPrepaidRentAmount?: number | null;
      advanceWasteWaterAmount?: number | null;
      advanceWaterAmount?: number | null;
      advanceHeatingAmount?: number | null;
      advanceElectricityAmount?: number | null;
      settlementWasteWaterAmount?: number | null;
      settlementWaterAmount?: number | null;
      settlementHeatingAmount?: number | null;
      settlementElectricityAmount?: number | null;
      otherAmount?: number | null;
      companySyncedAt?: any | null;
      deposit?: number | null;
      prepaidRent?: number | null;
      debtor?: { __typename?: "EconomicDebtor"; externalId: number } | null;
    };
  } | null;
} & { " $fragmentName"?: "DataTableApartmentsFragment" };

export type ApartmentsCalendarQueryVariables = Exact<{
  tenancyWhere?: InputMaybe<TenancyWhereInput>;
  caseWhere?: InputMaybe<CaseWhereInput>;
}>;

export type ApartmentsCalendarQuery = {
  __typename?: "Query";
  tenancies: {
    __typename?: "TenancyList";
    records: Array<{
      __typename?: "Tenancy";
      id: string;
      startDate: string;
      endDate?: string | null;
      movingInCase?: {
        __typename?: "Case";
        id: string;
        attention: CaseAttentionEnum;
      } | null;
      movingOutCase?: {
        __typename?: "Case";
        id: string;
        attention: CaseAttentionEnum;
      } | null;
      user: { __typename?: "User"; name: string };
      apartment: { __typename?: "Apartment"; id: string };
    }>;
  };
  cases: {
    __typename?: "CaseList";
    records: Array<
      | {
          __typename?: "CleaningService";
          id: string;
          type: CaseTypeEnum;
          state: string;
          attention: CaseAttentionEnum;
          icon: string;
          name: string;
          calendarDate?: string | null;
          awaiting?: string | null;
          apartment?: { __typename?: "Apartment"; id: string } | null;
        }
      | {
          __typename?: "CreateTenancy";
          id: string;
          type: CaseTypeEnum;
          state: string;
          attention: CaseAttentionEnum;
          icon: string;
          name: string;
          calendarDate?: string | null;
          awaiting?: string | null;
          apartment?: { __typename?: "Apartment"; id: string } | null;
        }
      | {
          __typename?: "EndTenancy";
          id: string;
          type: CaseTypeEnum;
          state: string;
          attention: CaseAttentionEnum;
          icon: string;
          name: string;
          calendarDate?: string | null;
          awaiting?: string | null;
          apartment?: { __typename?: "Apartment"; id: string } | null;
        }
      | {
          __typename?: "FindTenancyAdministration";
          id: string;
          type: CaseTypeEnum;
          state: string;
          attention: CaseAttentionEnum;
          icon: string;
          name: string;
          calendarDate?: string | null;
          awaiting?: string | null;
          apartment?: { __typename?: "Apartment"; id: string } | null;
        }
      | {
          __typename?: "MiscAdministration";
          id: string;
          type: CaseTypeEnum;
          state: string;
          attention: CaseAttentionEnum;
          icon: string;
          name: string;
          calendarDate?: string | null;
          awaiting?: string | null;
          apartment?: { __typename?: "Apartment"; id: string } | null;
        }
      | {
          __typename?: "MiscService";
          id: string;
          type: CaseTypeEnum;
          state: string;
          attention: CaseAttentionEnum;
          icon: string;
          name: string;
          calendarDate?: string | null;
          awaiting?: string | null;
          apartment?: { __typename?: "Apartment"; id: string } | null;
        }
      | {
          __typename?: "MovingInService";
          id: string;
          type: CaseTypeEnum;
          state: string;
          attention: CaseAttentionEnum;
          icon: string;
          name: string;
          calendarDate?: string | null;
          awaiting?: string | null;
          apartment?: { __typename?: "Apartment"; id: string } | null;
        }
      | {
          __typename?: "MovingInTenancy";
          id: string;
          type: CaseTypeEnum;
          state: string;
          attention: CaseAttentionEnum;
          icon: string;
          name: string;
          calendarDate?: string | null;
          awaiting?: string | null;
          apartment?: { __typename?: "Apartment"; id: string } | null;
        }
      | {
          __typename?: "MovingOutService";
          id: string;
          type: CaseTypeEnum;
          state: string;
          attention: CaseAttentionEnum;
          icon: string;
          name: string;
          calendarDate?: string | null;
          awaiting?: string | null;
          apartment?: { __typename?: "Apartment"; id: string } | null;
        }
      | {
          __typename?: "MovingOutTenancy";
          id: string;
          type: CaseTypeEnum;
          state: string;
          attention: CaseAttentionEnum;
          icon: string;
          name: string;
          calendarDate?: string | null;
          awaiting?: string | null;
          apartment?: { __typename?: "Apartment"; id: string } | null;
        }
      | {
          __typename?: "MovingSettlementAdministration";
          id: string;
          type: CaseTypeEnum;
          state: string;
          attention: CaseAttentionEnum;
          icon: string;
          name: string;
          calendarDate?: string | null;
          awaiting?: string | null;
          apartment?: { __typename?: "Apartment"; id: string } | null;
        }
      | {
          __typename?: "PreparationService";
          id: string;
          type: CaseTypeEnum;
          state: string;
          attention: CaseAttentionEnum;
          icon: string;
          name: string;
          calendarDate?: string | null;
          awaiting?: string | null;
          apartment?: { __typename?: "Apartment"; id: string } | null;
        }
      | {
          __typename?: "RequestTenancy";
          id: string;
          type: CaseTypeEnum;
          state: string;
          attention: CaseAttentionEnum;
          icon: string;
          name: string;
          calendarDate?: string | null;
          awaiting?: string | null;
          apartment?: { __typename?: "Apartment"; id: string } | null;
        }
      | {
          __typename?: "UtilityAdministration";
          id: string;
          type: CaseTypeEnum;
          state: string;
          attention: CaseAttentionEnum;
          icon: string;
          name: string;
          calendarDate?: string | null;
          awaiting?: string | null;
          apartment?: { __typename?: "Apartment"; id: string } | null;
        }
    >;
  };
};

type DataTableCases_CleaningService_Fragment = {
  __typename: "CleaningService";
  id: string;
  type: CaseTypeEnum;
  icon: string;
  createdAt: any;
  updatedAt: any;
  name: string;
  shortName: string;
  state: string;
  stateText: string;
  stateLastChangedAt?: any | null;
  attention: CaseAttentionEnum;
  awaiting?: string | null;
  startAfter?: string | null;
  completeBefore?: string | null;
  scheduledDate?: string | null;
  scheduledTime?: string | null;
  requester?: {
    __typename?: "User";
    id: string;
    name: string;
    email: string;
    phone?: string | null;
    protected: boolean;
  } | null;
  responsible?: {
    __typename?: "User";
    id: string;
    name: string;
    email: string;
    phone?: string | null;
    protected: boolean;
  } | null;
  property?: { __typename?: "Property"; id: string; name: string } | null;
  apartment?: {
    __typename?: "Apartment";
    id: string;
    publicId: string;
    address: string;
    postalCode: string;
    city: string;
  } | null;
  tenancy?: {
    __typename?: "Tenancy";
    id: string;
    startDate: string;
    requestedEndDate?: string | null;
    endDate?: string | null;
    user: {
      __typename?: "User";
      id: string;
      name: string;
      email: string;
      phone?: string | null;
      protected: boolean;
    };
  } | null;
} & { " $fragmentName"?: "DataTableCases_CleaningService_Fragment" };

type DataTableCases_CreateTenancy_Fragment = {
  __typename: "CreateTenancy";
  id: string;
  type: CaseTypeEnum;
  icon: string;
  createdAt: any;
  updatedAt: any;
  name: string;
  shortName: string;
  state: string;
  stateText: string;
  stateLastChangedAt?: any | null;
  attention: CaseAttentionEnum;
  awaiting?: string | null;
  startAfter?: string | null;
  completeBefore?: string | null;
  scheduledDate?: string | null;
  scheduledTime?: string | null;
  requester?: {
    __typename?: "User";
    id: string;
    name: string;
    email: string;
    phone?: string | null;
    protected: boolean;
  } | null;
  responsible?: {
    __typename?: "User";
    id: string;
    name: string;
    email: string;
    phone?: string | null;
    protected: boolean;
  } | null;
  property?: { __typename?: "Property"; id: string; name: string } | null;
  apartment?: {
    __typename?: "Apartment";
    id: string;
    publicId: string;
    address: string;
    postalCode: string;
    city: string;
  } | null;
  tenancy?: {
    __typename?: "Tenancy";
    id: string;
    startDate: string;
    requestedEndDate?: string | null;
    endDate?: string | null;
    user: {
      __typename?: "User";
      id: string;
      name: string;
      email: string;
      phone?: string | null;
      protected: boolean;
    };
  } | null;
} & { " $fragmentName"?: "DataTableCases_CreateTenancy_Fragment" };

type DataTableCases_EndTenancy_Fragment = {
  __typename: "EndTenancy";
  id: string;
  type: CaseTypeEnum;
  icon: string;
  createdAt: any;
  updatedAt: any;
  name: string;
  shortName: string;
  state: string;
  stateText: string;
  stateLastChangedAt?: any | null;
  attention: CaseAttentionEnum;
  awaiting?: string | null;
  startAfter?: string | null;
  completeBefore?: string | null;
  scheduledDate?: string | null;
  scheduledTime?: string | null;
  requester?: {
    __typename?: "User";
    id: string;
    name: string;
    email: string;
    phone?: string | null;
    protected: boolean;
  } | null;
  responsible?: {
    __typename?: "User";
    id: string;
    name: string;
    email: string;
    phone?: string | null;
    protected: boolean;
  } | null;
  property?: { __typename?: "Property"; id: string; name: string } | null;
  apartment?: {
    __typename?: "Apartment";
    id: string;
    publicId: string;
    address: string;
    postalCode: string;
    city: string;
  } | null;
  tenancy?: {
    __typename?: "Tenancy";
    id: string;
    startDate: string;
    requestedEndDate?: string | null;
    endDate?: string | null;
    user: {
      __typename?: "User";
      id: string;
      name: string;
      email: string;
      phone?: string | null;
      protected: boolean;
    };
  } | null;
} & { " $fragmentName"?: "DataTableCases_EndTenancy_Fragment" };

type DataTableCases_FindTenancyAdministration_Fragment = {
  __typename: "FindTenancyAdministration";
  id: string;
  type: CaseTypeEnum;
  icon: string;
  createdAt: any;
  updatedAt: any;
  name: string;
  shortName: string;
  state: string;
  stateText: string;
  stateLastChangedAt?: any | null;
  attention: CaseAttentionEnum;
  awaiting?: string | null;
  startAfter?: string | null;
  completeBefore?: string | null;
  scheduledDate?: string | null;
  scheduledTime?: string | null;
  requester?: {
    __typename?: "User";
    id: string;
    name: string;
    email: string;
    phone?: string | null;
    protected: boolean;
  } | null;
  responsible?: {
    __typename?: "User";
    id: string;
    name: string;
    email: string;
    phone?: string | null;
    protected: boolean;
  } | null;
  property?: { __typename?: "Property"; id: string; name: string } | null;
  apartment?: {
    __typename?: "Apartment";
    id: string;
    publicId: string;
    address: string;
    postalCode: string;
    city: string;
  } | null;
  tenancy?: {
    __typename?: "Tenancy";
    id: string;
    startDate: string;
    requestedEndDate?: string | null;
    endDate?: string | null;
    user: {
      __typename?: "User";
      id: string;
      name: string;
      email: string;
      phone?: string | null;
      protected: boolean;
    };
  } | null;
} & { " $fragmentName"?: "DataTableCases_FindTenancyAdministration_Fragment" };

type DataTableCases_MiscAdministration_Fragment = {
  __typename: "MiscAdministration";
  id: string;
  type: CaseTypeEnum;
  icon: string;
  createdAt: any;
  updatedAt: any;
  name: string;
  shortName: string;
  state: string;
  stateText: string;
  stateLastChangedAt?: any | null;
  attention: CaseAttentionEnum;
  awaiting?: string | null;
  startAfter?: string | null;
  completeBefore?: string | null;
  scheduledDate?: string | null;
  scheduledTime?: string | null;
  requester?: {
    __typename?: "User";
    id: string;
    name: string;
    email: string;
    phone?: string | null;
    protected: boolean;
  } | null;
  responsible?: {
    __typename?: "User";
    id: string;
    name: string;
    email: string;
    phone?: string | null;
    protected: boolean;
  } | null;
  property?: { __typename?: "Property"; id: string; name: string } | null;
  apartment?: {
    __typename?: "Apartment";
    id: string;
    publicId: string;
    address: string;
    postalCode: string;
    city: string;
  } | null;
  tenancy?: {
    __typename?: "Tenancy";
    id: string;
    startDate: string;
    requestedEndDate?: string | null;
    endDate?: string | null;
    user: {
      __typename?: "User";
      id: string;
      name: string;
      email: string;
      phone?: string | null;
      protected: boolean;
    };
  } | null;
} & { " $fragmentName"?: "DataTableCases_MiscAdministration_Fragment" };

type DataTableCases_MiscService_Fragment = {
  __typename: "MiscService";
  id: string;
  type: CaseTypeEnum;
  icon: string;
  createdAt: any;
  updatedAt: any;
  name: string;
  shortName: string;
  state: string;
  stateText: string;
  stateLastChangedAt?: any | null;
  attention: CaseAttentionEnum;
  awaiting?: string | null;
  startAfter?: string | null;
  completeBefore?: string | null;
  scheduledDate?: string | null;
  scheduledTime?: string | null;
  requester?: {
    __typename?: "User";
    id: string;
    name: string;
    email: string;
    phone?: string | null;
    protected: boolean;
  } | null;
  responsible?: {
    __typename?: "User";
    id: string;
    name: string;
    email: string;
    phone?: string | null;
    protected: boolean;
  } | null;
  property?: { __typename?: "Property"; id: string; name: string } | null;
  apartment?: {
    __typename?: "Apartment";
    id: string;
    publicId: string;
    address: string;
    postalCode: string;
    city: string;
  } | null;
  tenancy?: {
    __typename?: "Tenancy";
    id: string;
    startDate: string;
    requestedEndDate?: string | null;
    endDate?: string | null;
    user: {
      __typename?: "User";
      id: string;
      name: string;
      email: string;
      phone?: string | null;
      protected: boolean;
    };
  } | null;
} & { " $fragmentName"?: "DataTableCases_MiscService_Fragment" };

type DataTableCases_MovingInService_Fragment = {
  __typename: "MovingInService";
  id: string;
  type: CaseTypeEnum;
  icon: string;
  createdAt: any;
  updatedAt: any;
  name: string;
  shortName: string;
  state: string;
  stateText: string;
  stateLastChangedAt?: any | null;
  attention: CaseAttentionEnum;
  awaiting?: string | null;
  startAfter?: string | null;
  completeBefore?: string | null;
  scheduledDate?: string | null;
  scheduledTime?: string | null;
  requester?: {
    __typename?: "User";
    id: string;
    name: string;
    email: string;
    phone?: string | null;
    protected: boolean;
  } | null;
  responsible?: {
    __typename?: "User";
    id: string;
    name: string;
    email: string;
    phone?: string | null;
    protected: boolean;
  } | null;
  property?: { __typename?: "Property"; id: string; name: string } | null;
  apartment?: {
    __typename?: "Apartment";
    id: string;
    publicId: string;
    address: string;
    postalCode: string;
    city: string;
  } | null;
  tenancy?: {
    __typename?: "Tenancy";
    id: string;
    startDate: string;
    requestedEndDate?: string | null;
    endDate?: string | null;
    user: {
      __typename?: "User";
      id: string;
      name: string;
      email: string;
      phone?: string | null;
      protected: boolean;
    };
  } | null;
} & { " $fragmentName"?: "DataTableCases_MovingInService_Fragment" };

type DataTableCases_MovingInTenancy_Fragment = {
  __typename: "MovingInTenancy";
  id: string;
  type: CaseTypeEnum;
  icon: string;
  createdAt: any;
  updatedAt: any;
  name: string;
  shortName: string;
  state: string;
  stateText: string;
  stateLastChangedAt?: any | null;
  attention: CaseAttentionEnum;
  awaiting?: string | null;
  startAfter?: string | null;
  completeBefore?: string | null;
  scheduledDate?: string | null;
  scheduledTime?: string | null;
  requester?: {
    __typename?: "User";
    id: string;
    name: string;
    email: string;
    phone?: string | null;
    protected: boolean;
  } | null;
  responsible?: {
    __typename?: "User";
    id: string;
    name: string;
    email: string;
    phone?: string | null;
    protected: boolean;
  } | null;
  property?: { __typename?: "Property"; id: string; name: string } | null;
  apartment?: {
    __typename?: "Apartment";
    id: string;
    publicId: string;
    address: string;
    postalCode: string;
    city: string;
  } | null;
  tenancy?: {
    __typename?: "Tenancy";
    id: string;
    startDate: string;
    requestedEndDate?: string | null;
    endDate?: string | null;
    user: {
      __typename?: "User";
      id: string;
      name: string;
      email: string;
      phone?: string | null;
      protected: boolean;
    };
  } | null;
} & { " $fragmentName"?: "DataTableCases_MovingInTenancy_Fragment" };

type DataTableCases_MovingOutService_Fragment = {
  __typename: "MovingOutService";
  id: string;
  type: CaseTypeEnum;
  icon: string;
  createdAt: any;
  updatedAt: any;
  name: string;
  shortName: string;
  state: string;
  stateText: string;
  stateLastChangedAt?: any | null;
  attention: CaseAttentionEnum;
  awaiting?: string | null;
  startAfter?: string | null;
  completeBefore?: string | null;
  scheduledDate?: string | null;
  scheduledTime?: string | null;
  requester?: {
    __typename?: "User";
    id: string;
    name: string;
    email: string;
    phone?: string | null;
    protected: boolean;
  } | null;
  responsible?: {
    __typename?: "User";
    id: string;
    name: string;
    email: string;
    phone?: string | null;
    protected: boolean;
  } | null;
  property?: { __typename?: "Property"; id: string; name: string } | null;
  apartment?: {
    __typename?: "Apartment";
    id: string;
    publicId: string;
    address: string;
    postalCode: string;
    city: string;
  } | null;
  tenancy?: {
    __typename?: "Tenancy";
    id: string;
    startDate: string;
    requestedEndDate?: string | null;
    endDate?: string | null;
    user: {
      __typename?: "User";
      id: string;
      name: string;
      email: string;
      phone?: string | null;
      protected: boolean;
    };
  } | null;
} & { " $fragmentName"?: "DataTableCases_MovingOutService_Fragment" };

type DataTableCases_MovingOutTenancy_Fragment = {
  __typename: "MovingOutTenancy";
  id: string;
  type: CaseTypeEnum;
  icon: string;
  createdAt: any;
  updatedAt: any;
  name: string;
  shortName: string;
  state: string;
  stateText: string;
  stateLastChangedAt?: any | null;
  attention: CaseAttentionEnum;
  awaiting?: string | null;
  startAfter?: string | null;
  completeBefore?: string | null;
  scheduledDate?: string | null;
  scheduledTime?: string | null;
  requester?: {
    __typename?: "User";
    id: string;
    name: string;
    email: string;
    phone?: string | null;
    protected: boolean;
  } | null;
  responsible?: {
    __typename?: "User";
    id: string;
    name: string;
    email: string;
    phone?: string | null;
    protected: boolean;
  } | null;
  property?: { __typename?: "Property"; id: string; name: string } | null;
  apartment?: {
    __typename?: "Apartment";
    id: string;
    publicId: string;
    address: string;
    postalCode: string;
    city: string;
  } | null;
  tenancy?: {
    __typename?: "Tenancy";
    id: string;
    startDate: string;
    requestedEndDate?: string | null;
    endDate?: string | null;
    user: {
      __typename?: "User";
      id: string;
      name: string;
      email: string;
      phone?: string | null;
      protected: boolean;
    };
  } | null;
} & { " $fragmentName"?: "DataTableCases_MovingOutTenancy_Fragment" };

type DataTableCases_MovingSettlementAdministration_Fragment = {
  __typename: "MovingSettlementAdministration";
  id: string;
  type: CaseTypeEnum;
  icon: string;
  createdAt: any;
  updatedAt: any;
  name: string;
  shortName: string;
  state: string;
  stateText: string;
  stateLastChangedAt?: any | null;
  attention: CaseAttentionEnum;
  awaiting?: string | null;
  startAfter?: string | null;
  completeBefore?: string | null;
  scheduledDate?: string | null;
  scheduledTime?: string | null;
  requester?: {
    __typename?: "User";
    id: string;
    name: string;
    email: string;
    phone?: string | null;
    protected: boolean;
  } | null;
  responsible?: {
    __typename?: "User";
    id: string;
    name: string;
    email: string;
    phone?: string | null;
    protected: boolean;
  } | null;
  property?: { __typename?: "Property"; id: string; name: string } | null;
  apartment?: {
    __typename?: "Apartment";
    id: string;
    publicId: string;
    address: string;
    postalCode: string;
    city: string;
  } | null;
  tenancy?: {
    __typename?: "Tenancy";
    id: string;
    startDate: string;
    requestedEndDate?: string | null;
    endDate?: string | null;
    user: {
      __typename?: "User";
      id: string;
      name: string;
      email: string;
      phone?: string | null;
      protected: boolean;
    };
  } | null;
} & {
  " $fragmentName"?: "DataTableCases_MovingSettlementAdministration_Fragment";
};

type DataTableCases_PreparationService_Fragment = {
  __typename: "PreparationService";
  id: string;
  type: CaseTypeEnum;
  icon: string;
  createdAt: any;
  updatedAt: any;
  name: string;
  shortName: string;
  state: string;
  stateText: string;
  stateLastChangedAt?: any | null;
  attention: CaseAttentionEnum;
  awaiting?: string | null;
  startAfter?: string | null;
  completeBefore?: string | null;
  scheduledDate?: string | null;
  scheduledTime?: string | null;
  requester?: {
    __typename?: "User";
    id: string;
    name: string;
    email: string;
    phone?: string | null;
    protected: boolean;
  } | null;
  responsible?: {
    __typename?: "User";
    id: string;
    name: string;
    email: string;
    phone?: string | null;
    protected: boolean;
  } | null;
  property?: { __typename?: "Property"; id: string; name: string } | null;
  apartment?: {
    __typename?: "Apartment";
    id: string;
    publicId: string;
    address: string;
    postalCode: string;
    city: string;
  } | null;
  tenancy?: {
    __typename?: "Tenancy";
    id: string;
    startDate: string;
    requestedEndDate?: string | null;
    endDate?: string | null;
    user: {
      __typename?: "User";
      id: string;
      name: string;
      email: string;
      phone?: string | null;
      protected: boolean;
    };
  } | null;
} & { " $fragmentName"?: "DataTableCases_PreparationService_Fragment" };

type DataTableCases_RequestTenancy_Fragment = {
  __typename: "RequestTenancy";
  id: string;
  type: CaseTypeEnum;
  icon: string;
  createdAt: any;
  updatedAt: any;
  name: string;
  shortName: string;
  state: string;
  stateText: string;
  stateLastChangedAt?: any | null;
  attention: CaseAttentionEnum;
  awaiting?: string | null;
  startAfter?: string | null;
  completeBefore?: string | null;
  scheduledDate?: string | null;
  scheduledTime?: string | null;
  requester?: {
    __typename?: "User";
    id: string;
    name: string;
    email: string;
    phone?: string | null;
    protected: boolean;
  } | null;
  responsible?: {
    __typename?: "User";
    id: string;
    name: string;
    email: string;
    phone?: string | null;
    protected: boolean;
  } | null;
  property?: { __typename?: "Property"; id: string; name: string } | null;
  apartment?: {
    __typename?: "Apartment";
    id: string;
    publicId: string;
    address: string;
    postalCode: string;
    city: string;
  } | null;
  tenancy?: {
    __typename?: "Tenancy";
    id: string;
    startDate: string;
    requestedEndDate?: string | null;
    endDate?: string | null;
    user: {
      __typename?: "User";
      id: string;
      name: string;
      email: string;
      phone?: string | null;
      protected: boolean;
    };
  } | null;
} & { " $fragmentName"?: "DataTableCases_RequestTenancy_Fragment" };

type DataTableCases_UtilityAdministration_Fragment = {
  __typename: "UtilityAdministration";
  id: string;
  type: CaseTypeEnum;
  icon: string;
  createdAt: any;
  updatedAt: any;
  name: string;
  shortName: string;
  state: string;
  stateText: string;
  stateLastChangedAt?: any | null;
  attention: CaseAttentionEnum;
  awaiting?: string | null;
  startAfter?: string | null;
  completeBefore?: string | null;
  scheduledDate?: string | null;
  scheduledTime?: string | null;
  requester?: {
    __typename?: "User";
    id: string;
    name: string;
    email: string;
    phone?: string | null;
    protected: boolean;
  } | null;
  responsible?: {
    __typename?: "User";
    id: string;
    name: string;
    email: string;
    phone?: string | null;
    protected: boolean;
  } | null;
  property?: { __typename?: "Property"; id: string; name: string } | null;
  apartment?: {
    __typename?: "Apartment";
    id: string;
    publicId: string;
    address: string;
    postalCode: string;
    city: string;
  } | null;
  tenancy?: {
    __typename?: "Tenancy";
    id: string;
    startDate: string;
    requestedEndDate?: string | null;
    endDate?: string | null;
    user: {
      __typename?: "User";
      id: string;
      name: string;
      email: string;
      phone?: string | null;
      protected: boolean;
    };
  } | null;
} & { " $fragmentName"?: "DataTableCases_UtilityAdministration_Fragment" };

export type DataTableCasesFragment =
  | DataTableCases_CleaningService_Fragment
  | DataTableCases_CreateTenancy_Fragment
  | DataTableCases_EndTenancy_Fragment
  | DataTableCases_FindTenancyAdministration_Fragment
  | DataTableCases_MiscAdministration_Fragment
  | DataTableCases_MiscService_Fragment
  | DataTableCases_MovingInService_Fragment
  | DataTableCases_MovingInTenancy_Fragment
  | DataTableCases_MovingOutService_Fragment
  | DataTableCases_MovingOutTenancy_Fragment
  | DataTableCases_MovingSettlementAdministration_Fragment
  | DataTableCases_PreparationService_Fragment
  | DataTableCases_RequestTenancy_Fragment
  | DataTableCases_UtilityAdministration_Fragment;

export type EconomicSubscriberFragment = {
  __typename: "EconomicSubscriber";
  id: string;
  price: number;
  canceledAt?: any | null;
  intervalStartAt?: any | null;
  intervalEndAt?: any | null;
  signedUpAt?: any | null;
  debtor: {
    __typename: "EconomicDebtor";
    id: string;
    name: string;
    addressText?: string | null;
  };
  subscription: {
    __typename: "EconomicSubscription";
    id: string;
    intervalType?: EconomicSubscriptionIntervalEnum | null;
    name: string;
  };
} & { " $fragmentName"?: "EconomicSubscriberFragment" };

export type DataTablePropertiesFragment = {
  __typename: "Property";
  id: string;
  name: string;
  activeServiceCasesCount: number;
  createdAt: any;
  administrator: { __typename?: "Company"; name: string };
  owner: { __typename?: "Company"; name: string };
} & { " $fragmentName"?: "DataTablePropertiesFragment" };

export type DataTableTenanciesFragment = {
  __typename?: "Tenancy";
  id: string;
  startDate: string;
  endDate?: string | null;
  requestedEndDate?: string | null;
  depositAmount?: number | null;
  depositDueDate?: string | null;
  intervalAmount?: number | null;
  heatingAmount?: number | null;
  waterAmount?: number | null;
  electricityAmount?: number | null;
  expectedMovingInCases: Array<{
    __typename?: "CaseDefinition";
    type: CaseTypeEnum;
    name: string;
    icon: string;
  }>;
  expectedMovingOutCases: Array<{
    __typename?: "CaseDefinition";
    type: CaseTypeEnum;
    name: string;
    icon: string;
  }>;
  movingInCase?: {
    __typename?: "Case";
    id: string;
    icon: string;
    attention: CaseAttentionEnum;
    children: Array<{
      __typename?: "Case";
      id: string;
      type: CaseTypeEnum;
      shortName: string;
      icon: string;
      attention: CaseAttentionEnum;
    }>;
  } | null;
  movingOutCase?: {
    __typename?: "Case";
    id: string;
    icon: string;
    attention: CaseAttentionEnum;
    children: Array<{
      __typename?: "Case";
      id: string;
      type: CaseTypeEnum;
      shortName: string;
      icon: string;
      attention: CaseAttentionEnum;
    }>;
  } | null;
  contractCase?: {
    __typename?: "CreateTenancy";
    id: string;
    icon: string;
    attention: CaseAttentionEnum;
    stateText: string;
  } | null;
  user: {
    __typename?: "User";
    name: string;
    phone?: string | null;
    email: string;
    protected: boolean;
  };
  apartment: {
    __typename?: "Apartment";
    id: string;
    publicId: string;
    address: string;
    city: string;
  };
  economic: {
    __typename?: "TenancyEconomic";
    deposit?: number | null;
    prepaidRent?: number | null;
    rentAmount?: number | null;
    parkingAmount?: number | null;
    rentReductionAmount?: number | null;
    depositAmount?: number | null;
    regulationDepositAmount?: number | null;
    regulationPrepaidRentAmount?: number | null;
    advanceWasteWaterAmount?: number | null;
    advanceWaterAmount?: number | null;
    advanceHeatingAmount?: number | null;
    advanceElectricityAmount?: number | null;
    settlementWasteWaterAmount?: number | null;
    settlementWaterAmount?: number | null;
    settlementHeatingAmount?: number | null;
    settlementElectricityAmount?: number | null;
    otherAmount?: number | null;
    companySyncedAt?: any | null;
    debtor?: {
      __typename?: "EconomicDebtor";
      id: string;
      name: string;
      externalId: number;
    } | null;
  };
} & { " $fragmentName"?: "DataTableTenanciesFragment" };

export type FilterCaseTypeQueryVariables = Exact<{ [key: string]: never }>;

export type FilterCaseTypeQuery = {
  __typename?: "Query";
  caseDefinitions: Array<{
    __typename: "CaseDefinition";
    states: Array<string>;
    type: CaseTypeEnum;
    icon: string;
  }>;
};

export type FilterCaseWorkerQueryVariables = Exact<{ [key: string]: never }>;

export type FilterCaseWorkerQuery = {
  __typename?: "Query";
  teamMembers: {
    __typename?: "UserList";
    records: Array<{ __typename?: "User"; id: string; name: string }>;
  };
};

export type FilterPropertyQueryVariables = Exact<{ [key: string]: never }>;

export type FilterPropertyQuery = {
  __typename?: "Query";
  properties: {
    __typename?: "PropertyList";
    records: Array<{ __typename?: "Property"; id: string; name: string }>;
  };
};

type CaseDefinitionCaseFragment_CleaningService_Fragment = {
  __typename: "CleaningService";
  id: string;
  name: string;
  shortName: string;
  type: CaseTypeEnum;
  icon: string;
  attention: CaseAttentionEnum;
  awaiting?: string | null;
  state: string;
  stateText: string;
} & {
  " $fragmentName"?: "CaseDefinitionCaseFragment_CleaningService_Fragment";
};

type CaseDefinitionCaseFragment_CreateTenancy_Fragment = {
  __typename: "CreateTenancy";
  id: string;
  name: string;
  shortName: string;
  type: CaseTypeEnum;
  icon: string;
  attention: CaseAttentionEnum;
  awaiting?: string | null;
  state: string;
  stateText: string;
} & { " $fragmentName"?: "CaseDefinitionCaseFragment_CreateTenancy_Fragment" };

type CaseDefinitionCaseFragment_EndTenancy_Fragment = {
  __typename: "EndTenancy";
  id: string;
  name: string;
  shortName: string;
  type: CaseTypeEnum;
  icon: string;
  attention: CaseAttentionEnum;
  awaiting?: string | null;
  state: string;
  stateText: string;
} & { " $fragmentName"?: "CaseDefinitionCaseFragment_EndTenancy_Fragment" };

type CaseDefinitionCaseFragment_FindTenancyAdministration_Fragment = {
  __typename: "FindTenancyAdministration";
  id: string;
  name: string;
  shortName: string;
  type: CaseTypeEnum;
  icon: string;
  attention: CaseAttentionEnum;
  awaiting?: string | null;
  state: string;
  stateText: string;
} & {
  " $fragmentName"?: "CaseDefinitionCaseFragment_FindTenancyAdministration_Fragment";
};

type CaseDefinitionCaseFragment_MiscAdministration_Fragment = {
  __typename: "MiscAdministration";
  id: string;
  name: string;
  shortName: string;
  type: CaseTypeEnum;
  icon: string;
  attention: CaseAttentionEnum;
  awaiting?: string | null;
  state: string;
  stateText: string;
} & {
  " $fragmentName"?: "CaseDefinitionCaseFragment_MiscAdministration_Fragment";
};

type CaseDefinitionCaseFragment_MiscService_Fragment = {
  __typename: "MiscService";
  id: string;
  name: string;
  shortName: string;
  type: CaseTypeEnum;
  icon: string;
  attention: CaseAttentionEnum;
  awaiting?: string | null;
  state: string;
  stateText: string;
} & { " $fragmentName"?: "CaseDefinitionCaseFragment_MiscService_Fragment" };

type CaseDefinitionCaseFragment_MovingInService_Fragment = {
  __typename: "MovingInService";
  id: string;
  name: string;
  shortName: string;
  type: CaseTypeEnum;
  icon: string;
  attention: CaseAttentionEnum;
  awaiting?: string | null;
  state: string;
  stateText: string;
} & {
  " $fragmentName"?: "CaseDefinitionCaseFragment_MovingInService_Fragment";
};

type CaseDefinitionCaseFragment_MovingInTenancy_Fragment = {
  __typename: "MovingInTenancy";
  id: string;
  name: string;
  shortName: string;
  type: CaseTypeEnum;
  icon: string;
  attention: CaseAttentionEnum;
  awaiting?: string | null;
  state: string;
  stateText: string;
} & {
  " $fragmentName"?: "CaseDefinitionCaseFragment_MovingInTenancy_Fragment";
};

type CaseDefinitionCaseFragment_MovingOutService_Fragment = {
  __typename: "MovingOutService";
  id: string;
  name: string;
  shortName: string;
  type: CaseTypeEnum;
  icon: string;
  attention: CaseAttentionEnum;
  awaiting?: string | null;
  state: string;
  stateText: string;
} & {
  " $fragmentName"?: "CaseDefinitionCaseFragment_MovingOutService_Fragment";
};

type CaseDefinitionCaseFragment_MovingOutTenancy_Fragment = {
  __typename: "MovingOutTenancy";
  id: string;
  name: string;
  shortName: string;
  type: CaseTypeEnum;
  icon: string;
  attention: CaseAttentionEnum;
  awaiting?: string | null;
  state: string;
  stateText: string;
} & {
  " $fragmentName"?: "CaseDefinitionCaseFragment_MovingOutTenancy_Fragment";
};

type CaseDefinitionCaseFragment_MovingSettlementAdministration_Fragment = {
  __typename: "MovingSettlementAdministration";
  id: string;
  name: string;
  shortName: string;
  type: CaseTypeEnum;
  icon: string;
  attention: CaseAttentionEnum;
  awaiting?: string | null;
  state: string;
  stateText: string;
} & {
  " $fragmentName"?: "CaseDefinitionCaseFragment_MovingSettlementAdministration_Fragment";
};

type CaseDefinitionCaseFragment_PreparationService_Fragment = {
  __typename: "PreparationService";
  id: string;
  name: string;
  shortName: string;
  type: CaseTypeEnum;
  icon: string;
  attention: CaseAttentionEnum;
  awaiting?: string | null;
  state: string;
  stateText: string;
} & {
  " $fragmentName"?: "CaseDefinitionCaseFragment_PreparationService_Fragment";
};

type CaseDefinitionCaseFragment_RequestTenancy_Fragment = {
  __typename: "RequestTenancy";
  id: string;
  name: string;
  shortName: string;
  type: CaseTypeEnum;
  icon: string;
  attention: CaseAttentionEnum;
  awaiting?: string | null;
  state: string;
  stateText: string;
} & { " $fragmentName"?: "CaseDefinitionCaseFragment_RequestTenancy_Fragment" };

type CaseDefinitionCaseFragment_UtilityAdministration_Fragment = {
  __typename: "UtilityAdministration";
  id: string;
  name: string;
  shortName: string;
  type: CaseTypeEnum;
  icon: string;
  attention: CaseAttentionEnum;
  awaiting?: string | null;
  state: string;
  stateText: string;
} & {
  " $fragmentName"?: "CaseDefinitionCaseFragment_UtilityAdministration_Fragment";
};

export type CaseDefinitionCaseFragmentFragment =
  | CaseDefinitionCaseFragment_CleaningService_Fragment
  | CaseDefinitionCaseFragment_CreateTenancy_Fragment
  | CaseDefinitionCaseFragment_EndTenancy_Fragment
  | CaseDefinitionCaseFragment_FindTenancyAdministration_Fragment
  | CaseDefinitionCaseFragment_MiscAdministration_Fragment
  | CaseDefinitionCaseFragment_MiscService_Fragment
  | CaseDefinitionCaseFragment_MovingInService_Fragment
  | CaseDefinitionCaseFragment_MovingInTenancy_Fragment
  | CaseDefinitionCaseFragment_MovingOutService_Fragment
  | CaseDefinitionCaseFragment_MovingOutTenancy_Fragment
  | CaseDefinitionCaseFragment_MovingSettlementAdministration_Fragment
  | CaseDefinitionCaseFragment_PreparationService_Fragment
  | CaseDefinitionCaseFragment_RequestTenancy_Fragment
  | CaseDefinitionCaseFragment_UtilityAdministration_Fragment;

export type CaseDefinitionInfoFragmentFragment = {
  __typename: "CaseDefinition";
  name: string;
  icon: string;
  type: CaseTypeEnum;
} & { " $fragmentName"?: "CaseDefinitionInfoFragmentFragment" };

export type HeaderActionsDataQueryVariables = Exact<{ [key: string]: never }>;

export type HeaderActionsDataQuery = {
  __typename?: "Query";
  inbox: Array<
    {
      __typename?: "CaseDefinition";
      cases: {
        __typename?: "CaseList";
        count: number;
        records: Array<
          | ({ __typename?: "CleaningService" } & {
              " $fragmentRefs"?: {
                CaseDefinitionCaseFragment_CleaningService_Fragment: CaseDefinitionCaseFragment_CleaningService_Fragment;
              };
            })
          | ({ __typename?: "CreateTenancy" } & {
              " $fragmentRefs"?: {
                CaseDefinitionCaseFragment_CreateTenancy_Fragment: CaseDefinitionCaseFragment_CreateTenancy_Fragment;
              };
            })
          | ({ __typename?: "EndTenancy" } & {
              " $fragmentRefs"?: {
                CaseDefinitionCaseFragment_EndTenancy_Fragment: CaseDefinitionCaseFragment_EndTenancy_Fragment;
              };
            })
          | ({ __typename?: "FindTenancyAdministration" } & {
              " $fragmentRefs"?: {
                CaseDefinitionCaseFragment_FindTenancyAdministration_Fragment: CaseDefinitionCaseFragment_FindTenancyAdministration_Fragment;
              };
            })
          | ({ __typename?: "MiscAdministration" } & {
              " $fragmentRefs"?: {
                CaseDefinitionCaseFragment_MiscAdministration_Fragment: CaseDefinitionCaseFragment_MiscAdministration_Fragment;
              };
            })
          | ({ __typename?: "MiscService" } & {
              " $fragmentRefs"?: {
                CaseDefinitionCaseFragment_MiscService_Fragment: CaseDefinitionCaseFragment_MiscService_Fragment;
              };
            })
          | ({ __typename?: "MovingInService" } & {
              " $fragmentRefs"?: {
                CaseDefinitionCaseFragment_MovingInService_Fragment: CaseDefinitionCaseFragment_MovingInService_Fragment;
              };
            })
          | ({ __typename?: "MovingInTenancy" } & {
              " $fragmentRefs"?: {
                CaseDefinitionCaseFragment_MovingInTenancy_Fragment: CaseDefinitionCaseFragment_MovingInTenancy_Fragment;
              };
            })
          | ({ __typename?: "MovingOutService" } & {
              " $fragmentRefs"?: {
                CaseDefinitionCaseFragment_MovingOutService_Fragment: CaseDefinitionCaseFragment_MovingOutService_Fragment;
              };
            })
          | ({ __typename?: "MovingOutTenancy" } & {
              " $fragmentRefs"?: {
                CaseDefinitionCaseFragment_MovingOutTenancy_Fragment: CaseDefinitionCaseFragment_MovingOutTenancy_Fragment;
              };
            })
          | ({ __typename?: "MovingSettlementAdministration" } & {
              " $fragmentRefs"?: {
                CaseDefinitionCaseFragment_MovingSettlementAdministration_Fragment: CaseDefinitionCaseFragment_MovingSettlementAdministration_Fragment;
              };
            })
          | ({ __typename?: "PreparationService" } & {
              " $fragmentRefs"?: {
                CaseDefinitionCaseFragment_PreparationService_Fragment: CaseDefinitionCaseFragment_PreparationService_Fragment;
              };
            })
          | ({ __typename?: "RequestTenancy" } & {
              " $fragmentRefs"?: {
                CaseDefinitionCaseFragment_RequestTenancy_Fragment: CaseDefinitionCaseFragment_RequestTenancy_Fragment;
              };
            })
          | ({ __typename?: "UtilityAdministration" } & {
              " $fragmentRefs"?: {
                CaseDefinitionCaseFragment_UtilityAdministration_Fragment: CaseDefinitionCaseFragment_UtilityAdministration_Fragment;
              };
            })
        >;
      };
    } & {
      " $fragmentRefs"?: {
        CaseDefinitionInfoFragmentFragment: CaseDefinitionInfoFragmentFragment;
      };
    }
  >;
  flagged: Array<
    {
      __typename?: "CaseDefinition";
      cases: {
        __typename?: "CaseList";
        count: number;
        records: Array<
          | ({ __typename?: "CleaningService" } & {
              " $fragmentRefs"?: {
                CaseDefinitionCaseFragment_CleaningService_Fragment: CaseDefinitionCaseFragment_CleaningService_Fragment;
              };
            })
          | ({ __typename?: "CreateTenancy" } & {
              " $fragmentRefs"?: {
                CaseDefinitionCaseFragment_CreateTenancy_Fragment: CaseDefinitionCaseFragment_CreateTenancy_Fragment;
              };
            })
          | ({ __typename?: "EndTenancy" } & {
              " $fragmentRefs"?: {
                CaseDefinitionCaseFragment_EndTenancy_Fragment: CaseDefinitionCaseFragment_EndTenancy_Fragment;
              };
            })
          | ({ __typename?: "FindTenancyAdministration" } & {
              " $fragmentRefs"?: {
                CaseDefinitionCaseFragment_FindTenancyAdministration_Fragment: CaseDefinitionCaseFragment_FindTenancyAdministration_Fragment;
              };
            })
          | ({ __typename?: "MiscAdministration" } & {
              " $fragmentRefs"?: {
                CaseDefinitionCaseFragment_MiscAdministration_Fragment: CaseDefinitionCaseFragment_MiscAdministration_Fragment;
              };
            })
          | ({ __typename?: "MiscService" } & {
              " $fragmentRefs"?: {
                CaseDefinitionCaseFragment_MiscService_Fragment: CaseDefinitionCaseFragment_MiscService_Fragment;
              };
            })
          | ({ __typename?: "MovingInService" } & {
              " $fragmentRefs"?: {
                CaseDefinitionCaseFragment_MovingInService_Fragment: CaseDefinitionCaseFragment_MovingInService_Fragment;
              };
            })
          | ({ __typename?: "MovingInTenancy" } & {
              " $fragmentRefs"?: {
                CaseDefinitionCaseFragment_MovingInTenancy_Fragment: CaseDefinitionCaseFragment_MovingInTenancy_Fragment;
              };
            })
          | ({ __typename?: "MovingOutService" } & {
              " $fragmentRefs"?: {
                CaseDefinitionCaseFragment_MovingOutService_Fragment: CaseDefinitionCaseFragment_MovingOutService_Fragment;
              };
            })
          | ({ __typename?: "MovingOutTenancy" } & {
              " $fragmentRefs"?: {
                CaseDefinitionCaseFragment_MovingOutTenancy_Fragment: CaseDefinitionCaseFragment_MovingOutTenancy_Fragment;
              };
            })
          | ({ __typename?: "MovingSettlementAdministration" } & {
              " $fragmentRefs"?: {
                CaseDefinitionCaseFragment_MovingSettlementAdministration_Fragment: CaseDefinitionCaseFragment_MovingSettlementAdministration_Fragment;
              };
            })
          | ({ __typename?: "PreparationService" } & {
              " $fragmentRefs"?: {
                CaseDefinitionCaseFragment_PreparationService_Fragment: CaseDefinitionCaseFragment_PreparationService_Fragment;
              };
            })
          | ({ __typename?: "RequestTenancy" } & {
              " $fragmentRefs"?: {
                CaseDefinitionCaseFragment_RequestTenancy_Fragment: CaseDefinitionCaseFragment_RequestTenancy_Fragment;
              };
            })
          | ({ __typename?: "UtilityAdministration" } & {
              " $fragmentRefs"?: {
                CaseDefinitionCaseFragment_UtilityAdministration_Fragment: CaseDefinitionCaseFragment_UtilityAdministration_Fragment;
              };
            })
        >;
      };
    } & {
      " $fragmentRefs"?: {
        CaseDefinitionInfoFragmentFragment: CaseDefinitionInfoFragmentFragment;
      };
    }
  >;
  messages: Array<
    {
      __typename?: "CaseDefinition";
      cases: {
        __typename?: "CaseList";
        count: number;
        records: Array<
          | ({ __typename?: "CleaningService" } & {
              " $fragmentRefs"?: {
                CaseDefinitionCaseFragment_CleaningService_Fragment: CaseDefinitionCaseFragment_CleaningService_Fragment;
              };
            })
          | ({ __typename?: "CreateTenancy" } & {
              " $fragmentRefs"?: {
                CaseDefinitionCaseFragment_CreateTenancy_Fragment: CaseDefinitionCaseFragment_CreateTenancy_Fragment;
              };
            })
          | ({ __typename?: "EndTenancy" } & {
              " $fragmentRefs"?: {
                CaseDefinitionCaseFragment_EndTenancy_Fragment: CaseDefinitionCaseFragment_EndTenancy_Fragment;
              };
            })
          | ({ __typename?: "FindTenancyAdministration" } & {
              " $fragmentRefs"?: {
                CaseDefinitionCaseFragment_FindTenancyAdministration_Fragment: CaseDefinitionCaseFragment_FindTenancyAdministration_Fragment;
              };
            })
          | ({ __typename?: "MiscAdministration" } & {
              " $fragmentRefs"?: {
                CaseDefinitionCaseFragment_MiscAdministration_Fragment: CaseDefinitionCaseFragment_MiscAdministration_Fragment;
              };
            })
          | ({ __typename?: "MiscService" } & {
              " $fragmentRefs"?: {
                CaseDefinitionCaseFragment_MiscService_Fragment: CaseDefinitionCaseFragment_MiscService_Fragment;
              };
            })
          | ({ __typename?: "MovingInService" } & {
              " $fragmentRefs"?: {
                CaseDefinitionCaseFragment_MovingInService_Fragment: CaseDefinitionCaseFragment_MovingInService_Fragment;
              };
            })
          | ({ __typename?: "MovingInTenancy" } & {
              " $fragmentRefs"?: {
                CaseDefinitionCaseFragment_MovingInTenancy_Fragment: CaseDefinitionCaseFragment_MovingInTenancy_Fragment;
              };
            })
          | ({ __typename?: "MovingOutService" } & {
              " $fragmentRefs"?: {
                CaseDefinitionCaseFragment_MovingOutService_Fragment: CaseDefinitionCaseFragment_MovingOutService_Fragment;
              };
            })
          | ({ __typename?: "MovingOutTenancy" } & {
              " $fragmentRefs"?: {
                CaseDefinitionCaseFragment_MovingOutTenancy_Fragment: CaseDefinitionCaseFragment_MovingOutTenancy_Fragment;
              };
            })
          | ({ __typename?: "MovingSettlementAdministration" } & {
              " $fragmentRefs"?: {
                CaseDefinitionCaseFragment_MovingSettlementAdministration_Fragment: CaseDefinitionCaseFragment_MovingSettlementAdministration_Fragment;
              };
            })
          | ({ __typename?: "PreparationService" } & {
              " $fragmentRefs"?: {
                CaseDefinitionCaseFragment_PreparationService_Fragment: CaseDefinitionCaseFragment_PreparationService_Fragment;
              };
            })
          | ({ __typename?: "RequestTenancy" } & {
              " $fragmentRefs"?: {
                CaseDefinitionCaseFragment_RequestTenancy_Fragment: CaseDefinitionCaseFragment_RequestTenancy_Fragment;
              };
            })
          | ({ __typename?: "UtilityAdministration" } & {
              " $fragmentRefs"?: {
                CaseDefinitionCaseFragment_UtilityAdministration_Fragment: CaseDefinitionCaseFragment_UtilityAdministration_Fragment;
              };
            })
        >;
      };
    } & {
      " $fragmentRefs"?: {
        CaseDefinitionInfoFragmentFragment: CaseDefinitionInfoFragmentFragment;
      };
    }
  >;
};

export type GlobalSearchQueryQueryVariables = Exact<{
  searchValue: Scalars["String"]["input"];
}>;

export type GlobalSearchQueryQuery = {
  __typename?: "Query";
  tenancies: {
    __typename: "TenancyList";
    records: Array<{
      __typename: "Tenancy";
      id: string;
      user: { __typename: "User"; id: string; name: string };
      apartment: { __typename: "Apartment"; id: string; address: string };
    }>;
  };
  cases: {
    __typename: "CaseList";
    records: Array<
      | {
          __typename: "CleaningService";
          id: string;
          name: string;
          shortName: string;
          stateText: string;
          apartment?: {
            __typename: "Apartment";
            id: string;
            address: string;
          } | null;
        }
      | {
          __typename: "CreateTenancy";
          id: string;
          name: string;
          shortName: string;
          stateText: string;
          apartment?: {
            __typename: "Apartment";
            id: string;
            address: string;
          } | null;
        }
      | {
          __typename: "EndTenancy";
          id: string;
          name: string;
          shortName: string;
          stateText: string;
          apartment?: {
            __typename: "Apartment";
            id: string;
            address: string;
          } | null;
        }
      | {
          __typename: "FindTenancyAdministration";
          id: string;
          name: string;
          shortName: string;
          stateText: string;
          apartment?: {
            __typename: "Apartment";
            id: string;
            address: string;
          } | null;
        }
      | {
          __typename: "MiscAdministration";
          id: string;
          name: string;
          shortName: string;
          stateText: string;
          apartment?: {
            __typename: "Apartment";
            id: string;
            address: string;
          } | null;
        }
      | {
          __typename: "MiscService";
          id: string;
          name: string;
          shortName: string;
          stateText: string;
          apartment?: {
            __typename: "Apartment";
            id: string;
            address: string;
          } | null;
        }
      | {
          __typename: "MovingInService";
          id: string;
          name: string;
          shortName: string;
          stateText: string;
          apartment?: {
            __typename: "Apartment";
            id: string;
            address: string;
          } | null;
        }
      | {
          __typename: "MovingInTenancy";
          id: string;
          name: string;
          shortName: string;
          stateText: string;
          apartment?: {
            __typename: "Apartment";
            id: string;
            address: string;
          } | null;
        }
      | {
          __typename: "MovingOutService";
          id: string;
          name: string;
          shortName: string;
          stateText: string;
          apartment?: {
            __typename: "Apartment";
            id: string;
            address: string;
          } | null;
        }
      | {
          __typename: "MovingOutTenancy";
          id: string;
          name: string;
          shortName: string;
          stateText: string;
          apartment?: {
            __typename: "Apartment";
            id: string;
            address: string;
          } | null;
        }
      | {
          __typename: "MovingSettlementAdministration";
          id: string;
          name: string;
          shortName: string;
          stateText: string;
          apartment?: {
            __typename: "Apartment";
            id: string;
            address: string;
          } | null;
        }
      | {
          __typename: "PreparationService";
          id: string;
          name: string;
          shortName: string;
          stateText: string;
          apartment?: {
            __typename: "Apartment";
            id: string;
            address: string;
          } | null;
        }
      | {
          __typename: "RequestTenancy";
          id: string;
          name: string;
          shortName: string;
          stateText: string;
          apartment?: {
            __typename: "Apartment";
            id: string;
            address: string;
          } | null;
        }
      | {
          __typename: "UtilityAdministration";
          id: string;
          name: string;
          shortName: string;
          stateText: string;
          apartment?: {
            __typename: "Apartment";
            id: string;
            address: string;
          } | null;
        }
    >;
  };
};

export type ShortcutControlQueryVariables = Exact<{
  shortcutId: Scalars["ID"]["input"];
}>;

export type ShortcutControlQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "User";
    shortcut?: {
      __typename?: "Shortcut";
      id: string;
      type: string;
      name: string;
    } | null;
  } | null;
};

export type ShortcutUpdateMutationVariables = Exact<{
  shortcutId: Scalars["ID"]["input"];
  attributes: ShortcutAttributesInput;
}>;

export type ShortcutUpdateMutation = {
  __typename?: "Mutation";
  shortcutUpdate?: {
    __typename?: "ShortcutUpdatePayload";
    errors?: Array<string> | null;
  } | null;
};

export type ShortcutCreateMutationMutationVariables = Exact<{
  attributes: ShortcutAttributesInput;
}>;

export type ShortcutCreateMutationMutation = {
  __typename?: "Mutation";
  shortcutCreate?: {
    __typename?: "ShortcutCreateMutationPayload";
    errors?: Array<string> | null;
    shortcut?: { __typename?: "Shortcut"; id: string } | null;
  } | null;
};

export type ShortcutRemoveMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type ShortcutRemoveMutation = {
  __typename?: "Mutation";
  shortcutDelete?: {
    __typename?: "ShortcutDeleteMutationPayload";
    shortcutId?: string | null;
    errors?: Array<string> | null;
  } | null;
};

export type ShortcutsListShortcutFragment = {
  __typename?: "Shortcut";
  id: string;
  name: string;
  type: string;
} & { " $fragmentName"?: "ShortcutsListShortcutFragment" };

export type ShortcutsListQueryVariables = Exact<{
  where?: InputMaybe<ShortcutWhereInput>;
}>;

export type ShortcutsListQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "User";
    shortcuts: {
      __typename?: "ShortcutConnection";
      nodes: Array<
        { __typename?: "Shortcut" } & {
          " $fragmentRefs"?: {
            ShortcutsListShortcutFragment: ShortcutsListShortcutFragment;
          };
        }
      >;
    };
    shortcutsTotal: {
      __typename?: "ShortcutConnection";
      totalCount?: number | null;
    };
  } | null;
};

export type ScreenApartment_ApartmentJournalFragment = {
  __typename?: "TenancyNoteList";
  records: Array<{
    __typename: "TenancyNote";
    id: string;
    content: string;
    createdAt: any;
    updatedAt: any;
    user: { __typename?: "User"; id: string; name: string };
    attachments: Array<{
      __typename?: "File";
      id: string;
      fileName: string;
      url: string;
    }>;
  }>;
} & { " $fragmentName"?: "ScreenApartment_ApartmentJournalFragment" };

export type TenancyNoteDeleteMutationVariables = Exact<{
  tenancyId: Scalars["ID"]["input"];
  noteId: Scalars["ID"]["input"];
}>;

export type TenancyNoteDeleteMutation = {
  __typename?: "Mutation";
  tenancyNoteDelete?: {
    __typename?: "TenancyNoteDeleteMutationPayload";
    errors: Array<string>;
  } | null;
};

export type TenancyNoteCreateMutationVariables = Exact<{
  tenancyId: Scalars["ID"]["input"];
  content: Scalars["String"]["input"];
  attachments?: InputMaybe<
    Array<Scalars["String"]["input"]> | Scalars["String"]["input"]
  >;
}>;

export type TenancyNoteCreateMutation = {
  __typename?: "Mutation";
  tenancyNoteCreate?: {
    __typename?: "TenancyNoteCreateMutationPayload";
    errors: Array<string>;
    tenancyNote?: {
      __typename?: "TenancyNote";
      content: string;
      createdAt: any;
      user: { __typename?: "User"; name: string };
      attachments: Array<{
        __typename?: "File";
        fileName: string;
        url: string;
      }>;
    } | null;
  } | null;
};

export type TenancyNoteUpdateMutationVariables = Exact<{
  tenancyId: Scalars["ID"]["input"];
  noteId: Scalars["ID"]["input"];
  content?: InputMaybe<Scalars["String"]["input"]>;
  attachments?: InputMaybe<
    Array<Scalars["String"]["input"]> | Scalars["String"]["input"]
  >;
  attachmentsToRemove?: InputMaybe<
    Array<Scalars["String"]["input"]> | Scalars["String"]["input"]
  >;
}>;

export type TenancyNoteUpdateMutation = {
  __typename?: "Mutation";
  tenancyNoteUpdate?: {
    __typename?: "TenancyNoteUpdateMutationPayload";
    errors: Array<string>;
    tenancyNote?: {
      __typename?: "TenancyNote";
      content: string;
      createdAt: any;
      user: { __typename?: "User"; name: string };
      attachments: Array<{
        __typename?: "File";
        id: string;
        fileName: string;
        url: string;
      }>;
    } | null;
  } | null;
};

export type ScreenApartment_BodyApartmentQueryVariables = Exact<{
  apartmentId: Scalars["ID"]["input"];
  archived: Scalars["Boolean"]["input"];
}>;

export type ScreenApartment_BodyApartmentQuery = {
  __typename?: "Query";
  apartment?: {
    __typename?: "Apartment";
    id: string;
    address: string;
    property: { __typename?: "Property" } & {
      " $fragmentRefs"?: {
        ScreenApartment_LandlordFragment: ScreenApartment_LandlordFragment;
      };
    };
    messages: {
      __typename?: "MessageList";
      records: Array<
        { __typename?: "Message" } & {
          " $fragmentRefs"?: {
            ScreenApartment_MessageFragment: ScreenApartment_MessageFragment;
          };
        }
      >;
    };
    cases: {
      __typename?: "CaseList";
      records: Array<
        | ({ __typename?: "CleaningService" } & {
            " $fragmentRefs"?: {
              ScreenApartment_Case_CleaningService_Fragment: ScreenApartment_Case_CleaningService_Fragment;
            };
          })
        | ({ __typename?: "CreateTenancy" } & {
            " $fragmentRefs"?: {
              ScreenApartment_Case_CreateTenancy_Fragment: ScreenApartment_Case_CreateTenancy_Fragment;
            };
          })
        | ({ __typename?: "EndTenancy" } & {
            " $fragmentRefs"?: {
              ScreenApartment_Case_EndTenancy_Fragment: ScreenApartment_Case_EndTenancy_Fragment;
            };
          })
        | ({ __typename?: "FindTenancyAdministration" } & {
            " $fragmentRefs"?: {
              ScreenApartment_Case_FindTenancyAdministration_Fragment: ScreenApartment_Case_FindTenancyAdministration_Fragment;
            };
          })
        | ({ __typename?: "MiscAdministration" } & {
            " $fragmentRefs"?: {
              ScreenApartment_Case_MiscAdministration_Fragment: ScreenApartment_Case_MiscAdministration_Fragment;
            };
          })
        | ({ __typename?: "MiscService" } & {
            " $fragmentRefs"?: {
              ScreenApartment_Case_MiscService_Fragment: ScreenApartment_Case_MiscService_Fragment;
            };
          })
        | ({ __typename?: "MovingInService" } & {
            " $fragmentRefs"?: {
              ScreenApartment_Case_MovingInService_Fragment: ScreenApartment_Case_MovingInService_Fragment;
            };
          })
        | ({ __typename?: "MovingInTenancy" } & {
            " $fragmentRefs"?: {
              ScreenApartment_Case_MovingInTenancy_Fragment: ScreenApartment_Case_MovingInTenancy_Fragment;
            };
          })
        | ({ __typename?: "MovingOutService" } & {
            " $fragmentRefs"?: {
              ScreenApartment_Case_MovingOutService_Fragment: ScreenApartment_Case_MovingOutService_Fragment;
            };
          })
        | ({ __typename?: "MovingOutTenancy" } & {
            " $fragmentRefs"?: {
              ScreenApartment_Case_MovingOutTenancy_Fragment: ScreenApartment_Case_MovingOutTenancy_Fragment;
            };
          })
        | ({ __typename?: "MovingSettlementAdministration" } & {
            " $fragmentRefs"?: {
              ScreenApartment_Case_MovingSettlementAdministration_Fragment: ScreenApartment_Case_MovingSettlementAdministration_Fragment;
            };
          })
        | ({ __typename?: "PreparationService" } & {
            " $fragmentRefs"?: {
              ScreenApartment_Case_PreparationService_Fragment: ScreenApartment_Case_PreparationService_Fragment;
            };
          })
        | ({ __typename?: "RequestTenancy" } & {
            " $fragmentRefs"?: {
              ScreenApartment_Case_RequestTenancy_Fragment: ScreenApartment_Case_RequestTenancy_Fragment;
            };
          })
        | ({ __typename?: "UtilityAdministration" } & {
            " $fragmentRefs"?: {
              ScreenApartment_Case_UtilityAdministration_Fragment: ScreenApartment_Case_UtilityAdministration_Fragment;
            };
          })
      >;
    };
    documents?: Array<
      { __typename?: "Document" } & {
        " $fragmentRefs"?: {
          ScreenApartment_DocumentFragment: ScreenApartment_DocumentFragment;
        };
      }
    > | null;
  } | null;
};

export type ScreenApartment_BodyTenancyQueryVariables = Exact<{
  apartmentId: Scalars["ID"]["input"];
  tenancyId: Scalars["ID"]["input"];
}>;

export type ScreenApartment_BodyTenancyQuery = {
  __typename?: "Query";
  apartment?: {
    __typename?: "Apartment";
    id: string;
    tenancy?:
      | ({
          __typename?: "Tenancy";
          id: string;
          startDate: string;
          endDate?: string | null;
          economic: { __typename?: "TenancyEconomic" } & {
            " $fragmentRefs"?: {
              ScreenApartment_EconomicFragment: ScreenApartment_EconomicFragment;
            };
          };
          roles: Array<{
            __typename?: "TenancyRole";
            id: string;
            user: {
              __typename?: "User";
              id: string;
              name: string;
              isPhoneConfirmed: boolean;
            };
          }>;
          notes: { __typename?: "TenancyNoteList" } & {
            " $fragmentRefs"?: {
              ScreenApartment_ApartmentJournalFragment: ScreenApartment_ApartmentJournalFragment;
            };
          };
          cases: {
            __typename?: "CaseList";
            records: Array<
              | ({ __typename?: "CleaningService" } & {
                  " $fragmentRefs"?: {
                    ScreenApartment_Case_CleaningService_Fragment: ScreenApartment_Case_CleaningService_Fragment;
                  };
                })
              | ({ __typename?: "CreateTenancy" } & {
                  " $fragmentRefs"?: {
                    ScreenApartment_Case_CreateTenancy_Fragment: ScreenApartment_Case_CreateTenancy_Fragment;
                  };
                })
              | ({ __typename?: "EndTenancy" } & {
                  " $fragmentRefs"?: {
                    ScreenApartment_Case_EndTenancy_Fragment: ScreenApartment_Case_EndTenancy_Fragment;
                  };
                })
              | ({ __typename?: "FindTenancyAdministration" } & {
                  " $fragmentRefs"?: {
                    ScreenApartment_Case_FindTenancyAdministration_Fragment: ScreenApartment_Case_FindTenancyAdministration_Fragment;
                  };
                })
              | ({ __typename?: "MiscAdministration" } & {
                  " $fragmentRefs"?: {
                    ScreenApartment_Case_MiscAdministration_Fragment: ScreenApartment_Case_MiscAdministration_Fragment;
                  };
                })
              | ({ __typename?: "MiscService" } & {
                  " $fragmentRefs"?: {
                    ScreenApartment_Case_MiscService_Fragment: ScreenApartment_Case_MiscService_Fragment;
                  };
                })
              | ({ __typename?: "MovingInService" } & {
                  " $fragmentRefs"?: {
                    ScreenApartment_Case_MovingInService_Fragment: ScreenApartment_Case_MovingInService_Fragment;
                  };
                })
              | ({ __typename?: "MovingInTenancy" } & {
                  " $fragmentRefs"?: {
                    ScreenApartment_Case_MovingInTenancy_Fragment: ScreenApartment_Case_MovingInTenancy_Fragment;
                  };
                })
              | ({ __typename?: "MovingOutService" } & {
                  " $fragmentRefs"?: {
                    ScreenApartment_Case_MovingOutService_Fragment: ScreenApartment_Case_MovingOutService_Fragment;
                  };
                })
              | ({ __typename?: "MovingOutTenancy" } & {
                  " $fragmentRefs"?: {
                    ScreenApartment_Case_MovingOutTenancy_Fragment: ScreenApartment_Case_MovingOutTenancy_Fragment;
                  };
                })
              | ({ __typename?: "MovingSettlementAdministration" } & {
                  " $fragmentRefs"?: {
                    ScreenApartment_Case_MovingSettlementAdministration_Fragment: ScreenApartment_Case_MovingSettlementAdministration_Fragment;
                  };
                })
              | ({ __typename?: "PreparationService" } & {
                  " $fragmentRefs"?: {
                    ScreenApartment_Case_PreparationService_Fragment: ScreenApartment_Case_PreparationService_Fragment;
                  };
                })
              | ({ __typename?: "RequestTenancy" } & {
                  " $fragmentRefs"?: {
                    ScreenApartment_Case_RequestTenancy_Fragment: ScreenApartment_Case_RequestTenancy_Fragment;
                  };
                })
              | ({ __typename?: "UtilityAdministration" } & {
                  " $fragmentRefs"?: {
                    ScreenApartment_Case_UtilityAdministration_Fragment: ScreenApartment_Case_UtilityAdministration_Fragment;
                  };
                })
            >;
          };
          documents?: Array<
            { __typename?: "Document" } & {
              " $fragmentRefs"?: {
                ScreenApartment_DocumentFragment: ScreenApartment_DocumentFragment;
              };
            }
          > | null;
        } & {
          " $fragmentRefs"?: {
            ScreenApartment_ManageTenantsAndResidentsFragment: ScreenApartment_ManageTenantsAndResidentsFragment;
            ScreenApartment_ContractFragment: ScreenApartment_ContractFragment;
          };
        })
      | null;
  } | null;
};

type ScreenApartment_Case_CleaningService_Fragment = {
  __typename: "CleaningService";
  scheduledDate?: string | null;
  unreadCommentsCount: number;
  id: string;
  attention: CaseAttentionEnum;
  awaiting?: string | null;
  body: string;
  completeBefore?: string | null;
  createdAt: any;
  icon: string;
  isArchived: boolean;
  name: string;
  scheduledTime?: string | null;
  state: string;
  stateText: string;
  startAfter?: string | null;
  type: CaseTypeEnum;
  parent?:
    | {
        __typename?: "CleaningService";
        title: string;
        scheduledDate?: string | null;
      }
    | {
        __typename?: "CreateTenancy";
        title: string;
        scheduledDate?: string | null;
      }
    | {
        __typename?: "EndTenancy";
        title: string;
        scheduledDate?: string | null;
      }
    | {
        __typename?: "FindTenancyAdministration";
        title: string;
        scheduledDate?: string | null;
      }
    | {
        __typename?: "MiscAdministration";
        title: string;
        scheduledDate?: string | null;
      }
    | {
        __typename?: "MiscService";
        title: string;
        scheduledDate?: string | null;
      }
    | {
        __typename?: "MovingInService";
        title: string;
        scheduledDate?: string | null;
      }
    | {
        __typename?: "MovingInTenancy";
        title: string;
        scheduledDate?: string | null;
      }
    | {
        __typename?: "MovingOutService";
        title: string;
        scheduledDate?: string | null;
      }
    | {
        __typename?: "MovingOutTenancy";
        title: string;
        scheduledDate?: string | null;
      }
    | {
        __typename?: "MovingSettlementAdministration";
        title: string;
        scheduledDate?: string | null;
      }
    | {
        __typename?: "PreparationService";
        title: string;
        scheduledDate?: string | null;
      }
    | {
        __typename?: "RequestTenancy";
        title: string;
        scheduledDate?: string | null;
      }
    | {
        __typename?: "UtilityAdministration";
        title: string;
        scheduledDate?: string | null;
      }
    | null;
  apartment?: { __typename?: "Apartment"; address: string } | null;
  responsible?: { __typename?: "User"; name: string } | null;
} & { " $fragmentName"?: "ScreenApartment_Case_CleaningService_Fragment" };

type ScreenApartment_Case_CreateTenancy_Fragment = {
  __typename: "CreateTenancy";
  unreadCommentsCount: number;
  id: string;
  attention: CaseAttentionEnum;
  awaiting?: string | null;
  body: string;
  completeBefore?: string | null;
  createdAt: any;
  icon: string;
  isArchived: boolean;
  name: string;
  scheduledTime?: string | null;
  state: string;
  stateText: string;
  startAfter?: string | null;
  type: CaseTypeEnum;
  apartment?: { __typename?: "Apartment"; address: string } | null;
  responsible?: { __typename?: "User"; name: string } | null;
} & { " $fragmentName"?: "ScreenApartment_Case_CreateTenancy_Fragment" };

type ScreenApartment_Case_EndTenancy_Fragment = {
  __typename: "EndTenancy";
  awaiting?: string | null;
  id: string;
  attention: CaseAttentionEnum;
  body: string;
  completeBefore?: string | null;
  createdAt: any;
  icon: string;
  isArchived: boolean;
  name: string;
  scheduledTime?: string | null;
  state: string;
  stateText: string;
  startAfter?: string | null;
  type: CaseTypeEnum;
  apartment?: { __typename?: "Apartment"; address: string } | null;
  responsible?: { __typename?: "User"; name: string } | null;
} & { " $fragmentName"?: "ScreenApartment_Case_EndTenancy_Fragment" };

type ScreenApartment_Case_FindTenancyAdministration_Fragment = {
  __typename: "FindTenancyAdministration";
  id: string;
  attention: CaseAttentionEnum;
  awaiting?: string | null;
  body: string;
  completeBefore?: string | null;
  createdAt: any;
  icon: string;
  isArchived: boolean;
  name: string;
  scheduledTime?: string | null;
  state: string;
  stateText: string;
  startAfter?: string | null;
  type: CaseTypeEnum;
  parent?:
    | {
        __typename?: "CleaningService";
        title: string;
        scheduledDate?: string | null;
      }
    | {
        __typename?: "CreateTenancy";
        title: string;
        scheduledDate?: string | null;
      }
    | {
        __typename?: "EndTenancy";
        title: string;
        scheduledDate?: string | null;
      }
    | {
        __typename?: "FindTenancyAdministration";
        title: string;
        scheduledDate?: string | null;
      }
    | {
        __typename?: "MiscAdministration";
        title: string;
        scheduledDate?: string | null;
      }
    | {
        __typename?: "MiscService";
        title: string;
        scheduledDate?: string | null;
      }
    | {
        __typename?: "MovingInService";
        title: string;
        scheduledDate?: string | null;
      }
    | {
        __typename?: "MovingInTenancy";
        title: string;
        scheduledDate?: string | null;
      }
    | {
        __typename?: "MovingOutService";
        title: string;
        scheduledDate?: string | null;
      }
    | {
        __typename?: "MovingOutTenancy";
        title: string;
        scheduledDate?: string | null;
      }
    | {
        __typename?: "MovingSettlementAdministration";
        title: string;
        scheduledDate?: string | null;
      }
    | {
        __typename?: "PreparationService";
        title: string;
        scheduledDate?: string | null;
      }
    | {
        __typename?: "RequestTenancy";
        title: string;
        scheduledDate?: string | null;
      }
    | {
        __typename?: "UtilityAdministration";
        title: string;
        scheduledDate?: string | null;
      }
    | null;
  apartment?: { __typename?: "Apartment"; address: string } | null;
  responsible?: { __typename?: "User"; name: string } | null;
} & {
  " $fragmentName"?: "ScreenApartment_Case_FindTenancyAdministration_Fragment";
};

type ScreenApartment_Case_MiscAdministration_Fragment = {
  __typename: "MiscAdministration";
  scheduledDate?: string | null;
  unreadCommentsCount: number;
  id: string;
  attention: CaseAttentionEnum;
  awaiting?: string | null;
  body: string;
  completeBefore?: string | null;
  createdAt: any;
  icon: string;
  isArchived: boolean;
  name: string;
  scheduledTime?: string | null;
  state: string;
  stateText: string;
  startAfter?: string | null;
  type: CaseTypeEnum;
  parent?:
    | {
        __typename?: "CleaningService";
        scheduledDate?: string | null;
        title: string;
        apartment?: { __typename?: "Apartment"; address: string } | null;
      }
    | {
        __typename?: "CreateTenancy";
        scheduledDate?: string | null;
        title: string;
        apartment?: { __typename?: "Apartment"; address: string } | null;
      }
    | {
        __typename?: "EndTenancy";
        scheduledDate?: string | null;
        title: string;
        apartment?: { __typename?: "Apartment"; address: string } | null;
      }
    | {
        __typename?: "FindTenancyAdministration";
        scheduledDate?: string | null;
        title: string;
        apartment?: { __typename?: "Apartment"; address: string } | null;
      }
    | {
        __typename?: "MiscAdministration";
        scheduledDate?: string | null;
        title: string;
        apartment?: { __typename?: "Apartment"; address: string } | null;
      }
    | {
        __typename?: "MiscService";
        scheduledDate?: string | null;
        title: string;
        apartment?: { __typename?: "Apartment"; address: string } | null;
      }
    | {
        __typename?: "MovingInService";
        scheduledDate?: string | null;
        title: string;
        apartment?: { __typename?: "Apartment"; address: string } | null;
      }
    | {
        __typename?: "MovingInTenancy";
        scheduledDate?: string | null;
        title: string;
        apartment?: { __typename?: "Apartment"; address: string } | null;
      }
    | {
        __typename?: "MovingOutService";
        scheduledDate?: string | null;
        title: string;
        apartment?: { __typename?: "Apartment"; address: string } | null;
      }
    | {
        __typename?: "MovingOutTenancy";
        scheduledDate?: string | null;
        title: string;
        apartment?: { __typename?: "Apartment"; address: string } | null;
      }
    | {
        __typename?: "MovingSettlementAdministration";
        scheduledDate?: string | null;
        title: string;
        apartment?: { __typename?: "Apartment"; address: string } | null;
      }
    | {
        __typename?: "PreparationService";
        scheduledDate?: string | null;
        title: string;
        apartment?: { __typename?: "Apartment"; address: string } | null;
      }
    | {
        __typename?: "RequestTenancy";
        scheduledDate?: string | null;
        title: string;
        apartment?: { __typename?: "Apartment"; address: string } | null;
      }
    | {
        __typename?: "UtilityAdministration";
        scheduledDate?: string | null;
        title: string;
        apartment?: { __typename?: "Apartment"; address: string } | null;
      }
    | null;
  apartment?: { __typename?: "Apartment"; address: string } | null;
  responsible?: { __typename?: "User"; name: string } | null;
} & { " $fragmentName"?: "ScreenApartment_Case_MiscAdministration_Fragment" };

type ScreenApartment_Case_MiscService_Fragment = {
  __typename: "MiscService";
  scheduledDate?: string | null;
  unreadCommentsCount: number;
  id: string;
  attention: CaseAttentionEnum;
  awaiting?: string | null;
  body: string;
  completeBefore?: string | null;
  createdAt: any;
  icon: string;
  isArchived: boolean;
  name: string;
  scheduledTime?: string | null;
  state: string;
  stateText: string;
  startAfter?: string | null;
  type: CaseTypeEnum;
  parent?:
    | {
        __typename?: "CleaningService";
        scheduledDate?: string | null;
        title: string;
        apartment?: { __typename?: "Apartment"; address: string } | null;
      }
    | {
        __typename?: "CreateTenancy";
        scheduledDate?: string | null;
        title: string;
        apartment?: { __typename?: "Apartment"; address: string } | null;
      }
    | {
        __typename?: "EndTenancy";
        scheduledDate?: string | null;
        title: string;
        apartment?: { __typename?: "Apartment"; address: string } | null;
      }
    | {
        __typename?: "FindTenancyAdministration";
        scheduledDate?: string | null;
        title: string;
        apartment?: { __typename?: "Apartment"; address: string } | null;
      }
    | {
        __typename?: "MiscAdministration";
        scheduledDate?: string | null;
        title: string;
        apartment?: { __typename?: "Apartment"; address: string } | null;
      }
    | {
        __typename?: "MiscService";
        scheduledDate?: string | null;
        title: string;
        apartment?: { __typename?: "Apartment"; address: string } | null;
      }
    | {
        __typename?: "MovingInService";
        scheduledDate?: string | null;
        title: string;
        apartment?: { __typename?: "Apartment"; address: string } | null;
      }
    | {
        __typename?: "MovingInTenancy";
        scheduledDate?: string | null;
        title: string;
        apartment?: { __typename?: "Apartment"; address: string } | null;
      }
    | {
        __typename?: "MovingOutService";
        scheduledDate?: string | null;
        title: string;
        apartment?: { __typename?: "Apartment"; address: string } | null;
      }
    | {
        __typename?: "MovingOutTenancy";
        scheduledDate?: string | null;
        title: string;
        apartment?: { __typename?: "Apartment"; address: string } | null;
      }
    | {
        __typename?: "MovingSettlementAdministration";
        scheduledDate?: string | null;
        title: string;
        apartment?: { __typename?: "Apartment"; address: string } | null;
      }
    | {
        __typename?: "PreparationService";
        scheduledDate?: string | null;
        title: string;
        apartment?: { __typename?: "Apartment"; address: string } | null;
      }
    | {
        __typename?: "RequestTenancy";
        scheduledDate?: string | null;
        title: string;
        apartment?: { __typename?: "Apartment"; address: string } | null;
      }
    | {
        __typename?: "UtilityAdministration";
        scheduledDate?: string | null;
        title: string;
        apartment?: { __typename?: "Apartment"; address: string } | null;
      }
    | null;
  apartment?: { __typename?: "Apartment"; address: string } | null;
  responsible?: { __typename?: "User"; name: string } | null;
} & { " $fragmentName"?: "ScreenApartment_Case_MiscService_Fragment" };

type ScreenApartment_Case_MovingInService_Fragment = {
  __typename: "MovingInService";
  isAttending?: boolean | null;
  unreadCommentsCount: number;
  id: string;
  attention: CaseAttentionEnum;
  awaiting?: string | null;
  body: string;
  completeBefore?: string | null;
  createdAt: any;
  icon: string;
  isArchived: boolean;
  name: string;
  scheduledTime?: string | null;
  state: string;
  stateText: string;
  startAfter?: string | null;
  type: CaseTypeEnum;
  parent?:
    | {
        __typename?: "CleaningService";
        scheduledDate?: string | null;
        title: string;
        apartment?: { __typename?: "Apartment"; address: string } | null;
      }
    | {
        __typename?: "CreateTenancy";
        scheduledDate?: string | null;
        title: string;
        apartment?: { __typename?: "Apartment"; address: string } | null;
      }
    | {
        __typename?: "EndTenancy";
        scheduledDate?: string | null;
        title: string;
        apartment?: { __typename?: "Apartment"; address: string } | null;
      }
    | {
        __typename?: "FindTenancyAdministration";
        scheduledDate?: string | null;
        title: string;
        apartment?: { __typename?: "Apartment"; address: string } | null;
      }
    | {
        __typename?: "MiscAdministration";
        scheduledDate?: string | null;
        title: string;
        apartment?: { __typename?: "Apartment"; address: string } | null;
      }
    | {
        __typename?: "MiscService";
        scheduledDate?: string | null;
        title: string;
        apartment?: { __typename?: "Apartment"; address: string } | null;
      }
    | {
        __typename?: "MovingInService";
        scheduledDate?: string | null;
        title: string;
        apartment?: { __typename?: "Apartment"; address: string } | null;
      }
    | {
        __typename?: "MovingInTenancy";
        scheduledDate?: string | null;
        title: string;
        apartment?: { __typename?: "Apartment"; address: string } | null;
      }
    | {
        __typename?: "MovingOutService";
        scheduledDate?: string | null;
        title: string;
        apartment?: { __typename?: "Apartment"; address: string } | null;
      }
    | {
        __typename?: "MovingOutTenancy";
        scheduledDate?: string | null;
        title: string;
        apartment?: { __typename?: "Apartment"; address: string } | null;
      }
    | {
        __typename?: "MovingSettlementAdministration";
        scheduledDate?: string | null;
        title: string;
        apartment?: { __typename?: "Apartment"; address: string } | null;
      }
    | {
        __typename?: "PreparationService";
        scheduledDate?: string | null;
        title: string;
        apartment?: { __typename?: "Apartment"; address: string } | null;
      }
    | {
        __typename?: "RequestTenancy";
        scheduledDate?: string | null;
        title: string;
        apartment?: { __typename?: "Apartment"; address: string } | null;
      }
    | {
        __typename?: "UtilityAdministration";
        scheduledDate?: string | null;
        title: string;
        apartment?: { __typename?: "Apartment"; address: string } | null;
      }
    | null;
  apartment?: { __typename?: "Apartment"; address: string } | null;
  responsible?: { __typename?: "User"; name: string } | null;
} & { " $fragmentName"?: "ScreenApartment_Case_MovingInService_Fragment" };

type ScreenApartment_Case_MovingInTenancy_Fragment = {
  __typename: "MovingInTenancy";
  unreadCommentsCount: number;
  scheduledDate?: string | null;
  id: string;
  attention: CaseAttentionEnum;
  awaiting?: string | null;
  body: string;
  completeBefore?: string | null;
  createdAt: any;
  icon: string;
  isArchived: boolean;
  name: string;
  scheduledTime?: string | null;
  state: string;
  stateText: string;
  startAfter?: string | null;
  type: CaseTypeEnum;
  parent?:
    | {
        __typename?: "CleaningService";
        scheduledDate?: string | null;
        title: string;
      }
    | {
        __typename?: "CreateTenancy";
        scheduledDate?: string | null;
        title: string;
      }
    | {
        __typename?: "EndTenancy";
        scheduledDate?: string | null;
        title: string;
      }
    | {
        __typename?: "FindTenancyAdministration";
        scheduledDate?: string | null;
        title: string;
      }
    | {
        __typename?: "MiscAdministration";
        scheduledDate?: string | null;
        title: string;
      }
    | {
        __typename?: "MiscService";
        scheduledDate?: string | null;
        title: string;
      }
    | {
        __typename?: "MovingInService";
        scheduledDate?: string | null;
        title: string;
      }
    | {
        __typename?: "MovingInTenancy";
        scheduledDate?: string | null;
        title: string;
      }
    | {
        __typename?: "MovingOutService";
        scheduledDate?: string | null;
        title: string;
      }
    | {
        __typename?: "MovingOutTenancy";
        scheduledDate?: string | null;
        title: string;
      }
    | {
        __typename?: "MovingSettlementAdministration";
        scheduledDate?: string | null;
        title: string;
      }
    | {
        __typename?: "PreparationService";
        scheduledDate?: string | null;
        title: string;
      }
    | {
        __typename?: "RequestTenancy";
        scheduledDate?: string | null;
        title: string;
      }
    | {
        __typename?: "UtilityAdministration";
        scheduledDate?: string | null;
        title: string;
      }
    | null;
  apartment?: { __typename?: "Apartment"; address: string } | null;
  responsible?: { __typename?: "User"; name: string } | null;
} & { " $fragmentName"?: "ScreenApartment_Case_MovingInTenancy_Fragment" };

type ScreenApartment_Case_MovingOutService_Fragment = {
  __typename: "MovingOutService";
  scheduledDate?: string | null;
  unreadCommentsCount: number;
  id: string;
  attention: CaseAttentionEnum;
  awaiting?: string | null;
  body: string;
  completeBefore?: string | null;
  createdAt: any;
  icon: string;
  isArchived: boolean;
  name: string;
  scheduledTime?: string | null;
  state: string;
  stateText: string;
  startAfter?: string | null;
  type: CaseTypeEnum;
  parent?:
    | {
        __typename?: "CleaningService";
        scheduledDate?: string | null;
        title: string;
      }
    | {
        __typename?: "CreateTenancy";
        scheduledDate?: string | null;
        title: string;
      }
    | {
        __typename?: "EndTenancy";
        scheduledDate?: string | null;
        title: string;
      }
    | {
        __typename?: "FindTenancyAdministration";
        scheduledDate?: string | null;
        title: string;
      }
    | {
        __typename?: "MiscAdministration";
        scheduledDate?: string | null;
        title: string;
      }
    | {
        __typename?: "MiscService";
        scheduledDate?: string | null;
        title: string;
      }
    | {
        __typename?: "MovingInService";
        scheduledDate?: string | null;
        title: string;
      }
    | {
        __typename?: "MovingInTenancy";
        scheduledDate?: string | null;
        title: string;
      }
    | {
        __typename?: "MovingOutService";
        scheduledDate?: string | null;
        title: string;
      }
    | {
        __typename?: "MovingOutTenancy";
        scheduledDate?: string | null;
        title: string;
      }
    | {
        __typename?: "MovingSettlementAdministration";
        scheduledDate?: string | null;
        title: string;
      }
    | {
        __typename?: "PreparationService";
        scheduledDate?: string | null;
        title: string;
      }
    | {
        __typename?: "RequestTenancy";
        scheduledDate?: string | null;
        title: string;
      }
    | {
        __typename?: "UtilityAdministration";
        scheduledDate?: string | null;
        title: string;
      }
    | null;
  apartment?: { __typename?: "Apartment"; address: string } | null;
  responsible?: { __typename?: "User"; name: string } | null;
} & { " $fragmentName"?: "ScreenApartment_Case_MovingOutService_Fragment" };

type ScreenApartment_Case_MovingOutTenancy_Fragment = {
  __typename: "MovingOutTenancy";
  unreadCommentsCount: number;
  id: string;
  attention: CaseAttentionEnum;
  awaiting?: string | null;
  body: string;
  completeBefore?: string | null;
  createdAt: any;
  icon: string;
  isArchived: boolean;
  name: string;
  scheduledTime?: string | null;
  state: string;
  stateText: string;
  startAfter?: string | null;
  type: CaseTypeEnum;
  apartment?: { __typename?: "Apartment"; address: string } | null;
  responsible?: { __typename?: "User"; name: string } | null;
} & { " $fragmentName"?: "ScreenApartment_Case_MovingOutTenancy_Fragment" };

type ScreenApartment_Case_MovingSettlementAdministration_Fragment = {
  __typename: "MovingSettlementAdministration";
  scheduledDate?: string | null;
  unreadCommentsCount: number;
  id: string;
  attention: CaseAttentionEnum;
  awaiting?: string | null;
  body: string;
  completeBefore?: string | null;
  createdAt: any;
  icon: string;
  isArchived: boolean;
  name: string;
  scheduledTime?: string | null;
  state: string;
  stateText: string;
  startAfter?: string | null;
  type: CaseTypeEnum;
  apartment?: { __typename?: "Apartment"; address: string } | null;
  parent?:
    | {
        __typename?: "CleaningService";
        scheduledDate?: string | null;
        title: string;
      }
    | {
        __typename?: "CreateTenancy";
        scheduledDate?: string | null;
        title: string;
      }
    | {
        __typename?: "EndTenancy";
        scheduledDate?: string | null;
        title: string;
      }
    | {
        __typename?: "FindTenancyAdministration";
        scheduledDate?: string | null;
        title: string;
      }
    | {
        __typename?: "MiscAdministration";
        scheduledDate?: string | null;
        title: string;
      }
    | {
        __typename?: "MiscService";
        scheduledDate?: string | null;
        title: string;
      }
    | {
        __typename?: "MovingInService";
        scheduledDate?: string | null;
        title: string;
      }
    | {
        __typename?: "MovingInTenancy";
        scheduledDate?: string | null;
        title: string;
      }
    | {
        __typename?: "MovingOutService";
        scheduledDate?: string | null;
        title: string;
      }
    | {
        __typename?: "MovingOutTenancy";
        scheduledDate?: string | null;
        title: string;
      }
    | {
        __typename?: "MovingSettlementAdministration";
        scheduledDate?: string | null;
        title: string;
      }
    | {
        __typename?: "PreparationService";
        scheduledDate?: string | null;
        title: string;
      }
    | {
        __typename?: "RequestTenancy";
        scheduledDate?: string | null;
        title: string;
      }
    | {
        __typename?: "UtilityAdministration";
        scheduledDate?: string | null;
        title: string;
      }
    | null;
  responsible?: { __typename?: "User"; name: string } | null;
} & {
  " $fragmentName"?: "ScreenApartment_Case_MovingSettlementAdministration_Fragment";
};

type ScreenApartment_Case_PreparationService_Fragment = {
  __typename: "PreparationService";
  scheduledDate?: string | null;
  unreadCommentsCount: number;
  id: string;
  attention: CaseAttentionEnum;
  awaiting?: string | null;
  body: string;
  completeBefore?: string | null;
  createdAt: any;
  icon: string;
  isArchived: boolean;
  name: string;
  scheduledTime?: string | null;
  state: string;
  stateText: string;
  startAfter?: string | null;
  type: CaseTypeEnum;
  apartment?: { __typename?: "Apartment"; address: string } | null;
  parent?:
    | {
        __typename?: "CleaningService";
        scheduledDate?: string | null;
        title: string;
      }
    | {
        __typename?: "CreateTenancy";
        scheduledDate?: string | null;
        title: string;
      }
    | {
        __typename?: "EndTenancy";
        scheduledDate?: string | null;
        title: string;
      }
    | {
        __typename?: "FindTenancyAdministration";
        scheduledDate?: string | null;
        title: string;
      }
    | {
        __typename?: "MiscAdministration";
        scheduledDate?: string | null;
        title: string;
      }
    | {
        __typename?: "MiscService";
        scheduledDate?: string | null;
        title: string;
      }
    | {
        __typename?: "MovingInService";
        scheduledDate?: string | null;
        title: string;
      }
    | {
        __typename?: "MovingInTenancy";
        scheduledDate?: string | null;
        title: string;
      }
    | {
        __typename?: "MovingOutService";
        scheduledDate?: string | null;
        title: string;
      }
    | {
        __typename?: "MovingOutTenancy";
        scheduledDate?: string | null;
        title: string;
      }
    | {
        __typename?: "MovingSettlementAdministration";
        scheduledDate?: string | null;
        title: string;
      }
    | {
        __typename?: "PreparationService";
        scheduledDate?: string | null;
        title: string;
      }
    | {
        __typename?: "RequestTenancy";
        scheduledDate?: string | null;
        title: string;
      }
    | {
        __typename?: "UtilityAdministration";
        scheduledDate?: string | null;
        title: string;
      }
    | null;
  responsible?: { __typename?: "User"; name: string } | null;
} & { " $fragmentName"?: "ScreenApartment_Case_PreparationService_Fragment" };

type ScreenApartment_Case_RequestTenancy_Fragment = {
  __typename: "RequestTenancy";
  unreadCommentsCount: number;
  id: string;
  attention: CaseAttentionEnum;
  awaiting?: string | null;
  body: string;
  completeBefore?: string | null;
  createdAt: any;
  icon: string;
  isArchived: boolean;
  name: string;
  scheduledTime?: string | null;
  state: string;
  stateText: string;
  startAfter?: string | null;
  type: CaseTypeEnum;
  apartment?: { __typename?: "Apartment"; address: string } | null;
  responsible?: { __typename?: "User"; name: string } | null;
} & { " $fragmentName"?: "ScreenApartment_Case_RequestTenancy_Fragment" };

type ScreenApartment_Case_UtilityAdministration_Fragment = {
  __typename: "UtilityAdministration";
  unreadCommentsCount: number;
  id: string;
  attention: CaseAttentionEnum;
  awaiting?: string | null;
  body: string;
  completeBefore?: string | null;
  createdAt: any;
  icon: string;
  isArchived: boolean;
  name: string;
  scheduledTime?: string | null;
  state: string;
  stateText: string;
  startAfter?: string | null;
  type: CaseTypeEnum;
  parent?:
    | {
        __typename?: "CleaningService";
        scheduledDate?: string | null;
        title: string;
      }
    | {
        __typename?: "CreateTenancy";
        scheduledDate?: string | null;
        title: string;
      }
    | {
        __typename?: "EndTenancy";
        scheduledDate?: string | null;
        title: string;
      }
    | {
        __typename?: "FindTenancyAdministration";
        scheduledDate?: string | null;
        title: string;
      }
    | {
        __typename?: "MiscAdministration";
        scheduledDate?: string | null;
        title: string;
      }
    | {
        __typename?: "MiscService";
        scheduledDate?: string | null;
        title: string;
      }
    | {
        __typename?: "MovingInService";
        scheduledDate?: string | null;
        title: string;
      }
    | {
        __typename?: "MovingInTenancy";
        scheduledDate?: string | null;
        title: string;
      }
    | {
        __typename?: "MovingOutService";
        scheduledDate?: string | null;
        title: string;
      }
    | {
        __typename?: "MovingOutTenancy";
        scheduledDate?: string | null;
        title: string;
      }
    | {
        __typename?: "MovingSettlementAdministration";
        scheduledDate?: string | null;
        title: string;
      }
    | {
        __typename?: "PreparationService";
        scheduledDate?: string | null;
        title: string;
      }
    | {
        __typename?: "RequestTenancy";
        scheduledDate?: string | null;
        title: string;
      }
    | {
        __typename?: "UtilityAdministration";
        scheduledDate?: string | null;
        title: string;
      }
    | null;
  apartment?: { __typename?: "Apartment"; address: string } | null;
  responsible?: { __typename?: "User"; name: string } | null;
} & {
  " $fragmentName"?: "ScreenApartment_Case_UtilityAdministration_Fragment";
};

export type ScreenApartment_CaseFragment =
  | ScreenApartment_Case_CleaningService_Fragment
  | ScreenApartment_Case_CreateTenancy_Fragment
  | ScreenApartment_Case_EndTenancy_Fragment
  | ScreenApartment_Case_FindTenancyAdministration_Fragment
  | ScreenApartment_Case_MiscAdministration_Fragment
  | ScreenApartment_Case_MiscService_Fragment
  | ScreenApartment_Case_MovingInService_Fragment
  | ScreenApartment_Case_MovingInTenancy_Fragment
  | ScreenApartment_Case_MovingOutService_Fragment
  | ScreenApartment_Case_MovingOutTenancy_Fragment
  | ScreenApartment_Case_MovingSettlementAdministration_Fragment
  | ScreenApartment_Case_PreparationService_Fragment
  | ScreenApartment_Case_RequestTenancy_Fragment
  | ScreenApartment_Case_UtilityAdministration_Fragment;

export type CasesByParentQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars["ID"]["input"]> | Scalars["ID"]["input"]>;
}>;

export type CasesByParentQuery = {
  __typename?: "Query";
  cases: {
    __typename?: "CaseList";
    records: Array<
      | {
          __typename?: "CleaningService";
          id: string;
          icon: string;
          attention: CaseAttentionEnum;
          awaiting?: string | null;
          stateText: string;
          state: string;
          title: string;
          type: CaseTypeEnum;
        }
      | {
          __typename?: "CreateTenancy";
          id: string;
          icon: string;
          attention: CaseAttentionEnum;
          awaiting?: string | null;
          stateText: string;
          state: string;
          title: string;
          type: CaseTypeEnum;
        }
      | {
          __typename?: "EndTenancy";
          id: string;
          icon: string;
          attention: CaseAttentionEnum;
          awaiting?: string | null;
          stateText: string;
          state: string;
          title: string;
          type: CaseTypeEnum;
        }
      | {
          __typename?: "FindTenancyAdministration";
          id: string;
          icon: string;
          attention: CaseAttentionEnum;
          awaiting?: string | null;
          stateText: string;
          state: string;
          title: string;
          type: CaseTypeEnum;
        }
      | {
          __typename?: "MiscAdministration";
          id: string;
          icon: string;
          attention: CaseAttentionEnum;
          awaiting?: string | null;
          stateText: string;
          state: string;
          title: string;
          type: CaseTypeEnum;
        }
      | {
          __typename?: "MiscService";
          id: string;
          icon: string;
          attention: CaseAttentionEnum;
          awaiting?: string | null;
          stateText: string;
          state: string;
          title: string;
          type: CaseTypeEnum;
        }
      | {
          __typename?: "MovingInService";
          id: string;
          icon: string;
          attention: CaseAttentionEnum;
          awaiting?: string | null;
          stateText: string;
          state: string;
          title: string;
          type: CaseTypeEnum;
        }
      | {
          __typename?: "MovingInTenancy";
          id: string;
          icon: string;
          attention: CaseAttentionEnum;
          awaiting?: string | null;
          stateText: string;
          state: string;
          title: string;
          type: CaseTypeEnum;
        }
      | {
          __typename?: "MovingOutService";
          id: string;
          icon: string;
          attention: CaseAttentionEnum;
          awaiting?: string | null;
          stateText: string;
          state: string;
          title: string;
          type: CaseTypeEnum;
        }
      | {
          __typename?: "MovingOutTenancy";
          id: string;
          icon: string;
          attention: CaseAttentionEnum;
          awaiting?: string | null;
          stateText: string;
          state: string;
          title: string;
          type: CaseTypeEnum;
        }
      | {
          __typename?: "MovingSettlementAdministration";
          id: string;
          icon: string;
          attention: CaseAttentionEnum;
          awaiting?: string | null;
          stateText: string;
          state: string;
          title: string;
          type: CaseTypeEnum;
        }
      | {
          __typename?: "PreparationService";
          id: string;
          icon: string;
          attention: CaseAttentionEnum;
          awaiting?: string | null;
          stateText: string;
          state: string;
          title: string;
          type: CaseTypeEnum;
        }
      | {
          __typename?: "RequestTenancy";
          id: string;
          icon: string;
          attention: CaseAttentionEnum;
          awaiting?: string | null;
          stateText: string;
          state: string;
          title: string;
          type: CaseTypeEnum;
        }
      | {
          __typename?: "UtilityAdministration";
          id: string;
          icon: string;
          attention: CaseAttentionEnum;
          awaiting?: string | null;
          stateText: string;
          state: string;
          title: string;
          type: CaseTypeEnum;
        }
    >;
  };
};

export type ApartmentContractEditMutationVariables = Exact<{
  depositAmount: Scalars["Float"]["input"];
  intervalAmount: Scalars["Float"]["input"];
  startDate: Scalars["ISO8601Date"]["input"];
  endDate?: InputMaybe<Scalars["ISO8601Date"]["input"]>;
  endTenancyTerms: Scalars["String"]["input"];
  tenancyId: Scalars["ID"]["input"];
}>;

export type ApartmentContractEditMutation = {
  __typename?: "Mutation";
  tenancyUpdate?: {
    __typename?: "TenancyUpdateMutationPayload";
    errors?: Array<string> | null;
    tenancy?: { __typename?: "Tenancy"; id: string } | null;
  } | null;
};

export type ApartmentContractTerminateModal_EndTenancyTermsQueryVariables =
  Exact<{
    tenancyId: Scalars["ID"]["input"];
    endDate: Scalars["ISO8601Date"]["input"];
  }>;

export type ApartmentContractTerminateModal_EndTenancyTermsQuery = {
  __typename?: "Query";
  tenancy?: {
    __typename?: "Tenancy";
    endTenancyTerms?: {
      __typename?: "TenancyTerms";
      endDate?: string | null;
      earlyEndDate?: boolean | null;
      endDateLongFormat?: string | null;
      lastRentDate?: string | null;
      lastRentDateLongFormat?: string | null;
      leaveDate?: string | null;
      leaveDateLongFormat?: string | null;
    } | null;
  } | null;
};

export type ApartmentContractTerminateModal_CreateEndTenancyMutationVariables =
  Exact<{
    endDate: Scalars["ISO8601Date"]["input"];
    comment?: InputMaybe<Scalars["String"]["input"]>;
    tenancyId: Scalars["ID"]["input"];
  }>;

export type ApartmentContractTerminateModal_CreateEndTenancyMutation = {
  __typename?: "Mutation";
  tenancyEnd?: {
    __typename?: "EndTenancyMutationPayload";
    errors?: Array<string> | null;
    case?: { __typename?: "EndTenancy"; id: string } | null;
  } | null;
};

export type ScreenApartment_ContractFragment = {
  __typename?: "Tenancy";
  id: string;
  startDate: string;
  endDate?: string | null;
  intervalAmount?: number | null;
  intervalAmountType: TenancyIntervalAmountTypeEnum;
  depositAmount?: number | null;
  hasEndTenancyCase: boolean;
  endTenancyTermsCondition?: string | null;
  contractCase?: {
    __typename?: "CreateTenancy";
    id: string;
    state: string;
    contract?: { __typename?: "Document"; url: string } | null;
  } | null;
} & { " $fragmentName"?: "ScreenApartment_ContractFragment" };

export type ScreenApartment_DocumentFragment = {
  __typename?: "Document";
  id: string;
  title: string;
  type: DocumentTypeEnum;
  url: string;
  createdAt: any;
} & { " $fragmentName"?: "ScreenApartment_DocumentFragment" };

export type EconomicDebtorsQueryVariables = Exact<{
  tenancyId?: InputMaybe<Scalars["ID"]["input"]>;
}>;

export type EconomicDebtorsQuery = {
  __typename?: "Query";
  economicDebtors: {
    __typename?: "EconomicDebtorList";
    records: Array<{
      __typename?: "EconomicDebtor";
      id: string;
      name: string;
      addressText?: string | null;
    }>;
  };
};

export type TenancyDebtorLinkMutationVariables = Exact<{
  tenancyId: Scalars["ID"]["input"];
  debtorId: Scalars["ID"]["input"];
}>;

export type TenancyDebtorLinkMutation = {
  __typename?: "Mutation";
  tenancyDebtorLink?: {
    __typename?: "TenancyDebtorLinkMutationPayload";
    isLinked?: boolean | null;
    errors?: Array<string> | null;
  } | null;
};

export type ScreenApartment_EconomicHistoryQueryVariables = Exact<{
  tenancyId: Scalars["ID"]["input"];
  subscriptionId: Scalars["ID"]["input"];
}>;

export type ScreenApartment_EconomicHistoryQuery = {
  __typename?: "Query";
  tenancy?: {
    __typename?: "Tenancy";
    economic: {
      __typename?: "TenancyEconomic";
      subscription?: {
        __typename?: "TenancyEconomicSubscription";
        name: string;
        history?: Array<{
          __typename?: "EconomicSubscriber";
          id: string;
          intervalStartAt?: any | null;
          intervalEndAt?: any | null;
          amount: number;
        }> | null;
      } | null;
    };
  } | null;
};

export type ScreenApartment_EconomicFragment = {
  __typename: "TenancyEconomic";
  deposit?: number | null;
  prepaidRent?: number | null;
  companySyncedAt?: any | null;
  debtor?: {
    __typename?: "EconomicDebtor";
    id: string;
    name: string;
    addressText?: string | null;
  } | null;
  subscriptions?: Array<{
    __typename?: "TenancyEconomicSubscription";
    id: string;
    name: string;
    interval?: EconomicSubscriptionIntervalEnum | null;
    currentAmount?: number | null;
  }> | null;
} & { " $fragmentName"?: "ScreenApartment_EconomicFragment" };

export type ScreenApartment_LandlordFragment = {
  __typename?: "Property";
  name: string;
  owner: {
    __typename?: "Company";
    name: string;
    phone?: string | null;
    email?: string | null;
    address?: string | null;
    postalCode?: string | null;
    city?: string | null;
  };
} & { " $fragmentName"?: "ScreenApartment_LandlordFragment" };

export type LookupUserMutationVariables = Exact<{
  email: Scalars["String"]["input"];
}>;

export type LookupUserMutation = {
  __typename?: "Mutation";
  lookupUser?: {
    __typename?: "User";
    email: string;
    id: string;
    name: string;
    phone?: string | null;
  } | null;
};

export type PhoneConfirmMutationVariables = Exact<{
  userId: Scalars["ID"]["input"];
}>;

export type PhoneConfirmMutation = {
  __typename?: "Mutation";
  result?: {
    __typename?: "PhoneConfirmMutationPayload";
    errors: Array<string>;
    user?: { __typename?: "User"; id: string } | null;
  } | null;
};

export type UpdateExternalUserMutationVariables = Exact<{
  userId: Scalars["ID"]["input"];
  email?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type UpdateExternalUserMutation = {
  __typename?: "Mutation";
  result?: {
    __typename?: "UpdateExternalUserMutationPayload";
    errors?: Array<string> | null;
    user?: {
      __typename?: "User";
      id: string;
      name: string;
      email: string;
      phone?: string | null;
    } | null;
  } | null;
};

export type ManageTenantsAndResidentsRemoveTenancyMutationVariables = Exact<{
  tenancyId: Scalars["ID"]["input"];
}>;

export type ManageTenantsAndResidentsRemoveTenancyMutation = {
  __typename?: "Mutation";
  tenancyRemove?: {
    __typename?: "TenancyRemoveMutationPayload";
    errors: Array<string>;
    tenancy?:
      | ({ __typename?: "Tenancy" } & {
          " $fragmentRefs"?: {
            ScreenApartment_ManageTenantsAndResidentsFragment: ScreenApartment_ManageTenantsAndResidentsFragment;
          };
        })
      | null;
  } | null;
};

export type ScreenApartment_ManageTenantsAndResidentsFragment = {
  __typename?: "Tenancy";
  id: string;
  removable: boolean;
  residents: Array<{
    __typename?: "Resident";
    id?: string | null;
    name: string;
    email?: string | null;
    phone?: string | null;
  }>;
  roles: Array<{
    __typename?: "TenancyRole";
    id: string;
    isPrimary: boolean;
    isResident: boolean;
    user: {
      __typename?: "User";
      id: string;
      name: string;
      email: string;
      phone?: string | null;
      isPhoneConfirmed: boolean;
      isExternal: boolean;
    };
  }>;
} & { " $fragmentName"?: "ScreenApartment_ManageTenantsAndResidentsFragment" };

export type TenancyResidentCreateMutationVariables = Exact<{
  tenancyId: Scalars["ID"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type TenancyResidentCreateMutation = {
  __typename?: "Mutation";
  tenancyResidentCreate?: {
    __typename?: "TenancyResidentCreateMutationPayload";
    resident?: {
      __typename?: "Resident";
      id?: string | null;
      name: string;
      email?: string | null;
    } | null;
  } | null;
};

export type TenancyResidentUpdateMutationVariables = Exact<{
  residentId: Scalars["ID"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type TenancyResidentUpdateMutation = {
  __typename?: "Mutation";
  tenancyResidentUpdate?: {
    __typename?: "TenancyResidentUpdateMutationPayload";
    errors?: Array<string> | null;
    resident?: {
      __typename?: "Resident";
      id?: string | null;
      name: string;
      email?: string | null;
    } | null;
  } | null;
};

export type TenancyResidentRemoveMutationVariables = Exact<{
  residentId: Scalars["ID"]["input"];
}>;

export type TenancyResidentRemoveMutation = {
  __typename?: "Mutation";
  tenancyResidentRemove?: {
    __typename?: "TenancyResidentRemoveMutationPayload";
    errors?: Array<string> | null;
    resident?: {
      __typename?: "Resident";
      id?: string | null;
      name: string;
      email?: string | null;
    } | null;
  } | null;
};

export type TenancyRoleCreateMutationVariables = Exact<{
  tenancyId: Scalars["ID"]["input"];
  userId?: InputMaybe<Scalars["ID"]["input"]>;
  isPrimary?: InputMaybe<Scalars["Boolean"]["input"]>;
  isResident?: InputMaybe<Scalars["Boolean"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type TenancyRoleCreateMutation = {
  __typename?: "Mutation";
  tenancyRoleCreate?: {
    __typename?: "TenancyRoleCreateMutationPayload";
    errors?: Array<string> | null;
    tenancyRole?: {
      __typename?: "TenancyRole";
      isPrimary: boolean;
      isResident: boolean;
      user: { __typename?: "User"; name: string };
    } | null;
  } | null;
};

export type TenancyRoleUpdateMutationVariables = Exact<{
  tenancyRoleId: Scalars["ID"]["input"];
  isPrimary?: InputMaybe<Scalars["Boolean"]["input"]>;
  isResident?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type TenancyRoleUpdateMutation = {
  __typename?: "Mutation";
  tenancyRoleUpdate?: {
    __typename?: "TenancyRoleUpdateMutationPayload";
    errors?: Array<string> | null;
    tenancyRole?: {
      __typename?: "TenancyRole";
      isPrimary: boolean;
      isResident: boolean;
      user: { __typename?: "User"; id: string; name: string };
    } | null;
  } | null;
};

export type TenancyRoleRemoveMutationVariables = Exact<{
  tenancyRoleId: Scalars["ID"]["input"];
}>;

export type TenancyRoleRemoveMutation = {
  __typename?: "Mutation";
  tenancyRoleRemove?: {
    __typename?: "TenancyRoleRemoveMutationPayload";
    errors?: Array<string> | null;
    tenancyRole?: {
      __typename?: "TenancyRole";
      isPrimary: boolean;
      isResident: boolean;
      user: { __typename?: "User"; name: string };
    } | null;
  } | null;
};

export type ScreenApartment_MessageFragment = {
  __typename?: "Message";
  id: string;
  title: string;
  body: string;
  authorId: string;
  createdAt: any;
  updatedAt: any;
  emailsSentAt?: any | null;
  smsSentAt?: any | null;
  apartment?: string | null;
  property: { __typename?: "Property"; id: string; name: string };
} & { " $fragmentName"?: "ScreenApartment_MessageFragment" };

export type ScreenApartmentQueryVariables = Exact<{
  apartmentId: Scalars["ID"]["input"];
}>;

export type ScreenApartmentQuery = {
  __typename?: "Query";
  apartment?: {
    __typename?: "Apartment";
    id: string;
    address: string;
    tenancies: {
      __typename?: "TenancyList";
      records: Array<{
        __typename?: "Tenancy";
        id: string;
        startDate: string;
        endDate?: string | null;
        roles: Array<{
          __typename?: "TenancyRole";
          id: string;
          user: { __typename?: "User"; id: string; name: string };
        }>;
      }>;
    };
  } | null;
};

export type ApartmentsQueryVariables = Exact<{
  where: ApartmentWhereInput;
  orderBy: ApartmentOrderByEnum;
  offset: Scalars["Int"]["input"];
  limit: Scalars["Int"]["input"];
}>;

export type ApartmentsQuery = {
  __typename?: "Query";
  apartments: {
    __typename?: "ApartmentList";
    total: number;
    records: Array<
      { __typename?: "Apartment"; id: string } & {
        " $fragmentRefs"?: {
          DataTableApartmentsFragment: DataTableApartmentsFragment;
        };
      }
    >;
  };
};

export type CasesCountQueryVariables = Exact<{ [key: string]: never }>;

export type CasesCountQuery = {
  __typename?: "Query";
  new: { __typename?: "CaseList"; total: number };
  comments: { __typename?: "CaseList"; total: number };
  flagged: { __typename?: "CaseList"; total: number };
};

export type CasesQueryVariables = Exact<{
  where: CaseWhereInput;
  orderBy: CaseOrderByEnum;
  offset: Scalars["Int"]["input"];
  limit: Scalars["Int"]["input"];
}>;

export type CasesQuery = {
  __typename?: "Query";
  cases: {
    __typename?: "CaseList";
    total: number;
    records: Array<
      | ({ __typename?: "CleaningService" } & {
          " $fragmentRefs"?: {
            DataTableCases_CleaningService_Fragment: DataTableCases_CleaningService_Fragment;
          };
        })
      | ({ __typename?: "CreateTenancy" } & {
          " $fragmentRefs"?: {
            DataTableCases_CreateTenancy_Fragment: DataTableCases_CreateTenancy_Fragment;
          };
        })
      | ({ __typename?: "EndTenancy" } & {
          " $fragmentRefs"?: {
            DataTableCases_EndTenancy_Fragment: DataTableCases_EndTenancy_Fragment;
          };
        })
      | ({ __typename?: "FindTenancyAdministration" } & {
          " $fragmentRefs"?: {
            DataTableCases_FindTenancyAdministration_Fragment: DataTableCases_FindTenancyAdministration_Fragment;
          };
        })
      | ({ __typename?: "MiscAdministration" } & {
          " $fragmentRefs"?: {
            DataTableCases_MiscAdministration_Fragment: DataTableCases_MiscAdministration_Fragment;
          };
        })
      | ({ __typename?: "MiscService" } & {
          " $fragmentRefs"?: {
            DataTableCases_MiscService_Fragment: DataTableCases_MiscService_Fragment;
          };
        })
      | ({ __typename?: "MovingInService" } & {
          " $fragmentRefs"?: {
            DataTableCases_MovingInService_Fragment: DataTableCases_MovingInService_Fragment;
          };
        })
      | ({ __typename?: "MovingInTenancy" } & {
          " $fragmentRefs"?: {
            DataTableCases_MovingInTenancy_Fragment: DataTableCases_MovingInTenancy_Fragment;
          };
        })
      | ({ __typename?: "MovingOutService" } & {
          " $fragmentRefs"?: {
            DataTableCases_MovingOutService_Fragment: DataTableCases_MovingOutService_Fragment;
          };
        })
      | ({ __typename?: "MovingOutTenancy" } & {
          " $fragmentRefs"?: {
            DataTableCases_MovingOutTenancy_Fragment: DataTableCases_MovingOutTenancy_Fragment;
          };
        })
      | ({ __typename?: "MovingSettlementAdministration" } & {
          " $fragmentRefs"?: {
            DataTableCases_MovingSettlementAdministration_Fragment: DataTableCases_MovingSettlementAdministration_Fragment;
          };
        })
      | ({ __typename?: "PreparationService" } & {
          " $fragmentRefs"?: {
            DataTableCases_PreparationService_Fragment: DataTableCases_PreparationService_Fragment;
          };
        })
      | ({ __typename?: "RequestTenancy" } & {
          " $fragmentRefs"?: {
            DataTableCases_RequestTenancy_Fragment: DataTableCases_RequestTenancy_Fragment;
          };
        })
      | ({ __typename?: "UtilityAdministration" } & {
          " $fragmentRefs"?: {
            DataTableCases_UtilityAdministration_Fragment: DataTableCases_UtilityAdministration_Fragment;
          };
        })
    >;
  };
};

export type CompaniesQueryVariables = Exact<{ [key: string]: never }>;

export type CompaniesQuery = {
  __typename?: "Query";
  companies: {
    __typename?: "CompanyList";
    records: Array<{
      __typename?: "Company";
      id: string;
      name: string;
      isOwner: boolean;
      isAdministrator: boolean;
    }>;
  };
};

export type PropertiesQueryVariables = Exact<{
  where: PropertyWhereInput;
  orderBy: PropertyOrderByEnum;
  offset: Scalars["Int"]["input"];
  limit: Scalars["Int"]["input"];
}>;

export type PropertiesQuery = {
  __typename?: "Query";
  properties: {
    __typename?: "PropertyList";
    total: number;
    records: Array<
      { __typename?: "Property" } & {
        " $fragmentRefs"?: {
          DataTablePropertiesFragment: DataTablePropertiesFragment;
        };
      }
    >;
  };
};

export type TenanciesQueryVariables = Exact<{
  where: TenancyWhereInput;
  orderBy: TenancyOrderByEnum;
  offset: Scalars["Int"]["input"];
  limit: Scalars["Int"]["input"];
}>;

export type TenanciesQuery = {
  __typename?: "Query";
  tenancies: {
    __typename?: "TenancyList";
    total: number;
    records: Array<
      { __typename?: "Tenancy" } & {
        " $fragmentRefs"?: {
          DataTableTenanciesFragment: DataTableTenanciesFragment;
        };
      }
    >;
  };
};

export type ScreenTenancyQueryVariables = Exact<{
  tenancyId: Scalars["ID"]["input"];
}>;

export type ScreenTenancyQuery = {
  __typename?: "Query";
  tenancy?: {
    __typename?: "Tenancy";
    id: string;
    apartment: { __typename?: "Apartment"; id: string };
  } | null;
};

export type CurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentUserQuery = {
  __typename?: "Query";
  currentUser?:
    | ({ __typename?: "User" } & {
        " $fragmentRefs"?: {
          CurrentUserFragmentFragment: CurrentUserFragmentFragment;
        };
      })
    | null;
};

export type CurrentUserFragmentFragment = {
  __typename: "User";
  id: string;
  name: string;
  email: string;
  isSystemAdministrator: boolean;
  isAdministrator: boolean;
} & { " $fragmentName"?: "CurrentUserFragmentFragment" };

export type ShortcutFragmentFragment = {
  __typename?: "Shortcut";
  id: string;
  type: string;
  name: string;
  state: any;
} & { " $fragmentName"?: "ShortcutFragmentFragment" };

export type ShortcutQueryVariables = Exact<{
  shortcutId: Scalars["ID"]["input"];
}>;

export type ShortcutQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "User";
    shortcut?:
      | ({ __typename?: "Shortcut" } & {
          " $fragmentRefs"?: {
            ShortcutFragmentFragment: ShortcutFragmentFragment;
          };
        })
      | null;
  } | null;
};

export const PersonalAccessTokenTokenFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PersonalAccessTokenTokenFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "PersonalAccessToken" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "token" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PersonalAccessTokenTokenFragmentFragment, unknown>;
export const DataTableApartmentsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "DataTableApartments" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Apartment" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "publicId" } },
          { kind: "Field", name: { kind: "Name", value: "address" } },
          { kind: "Field", name: { kind: "Name", value: "city" } },
          { kind: "Field", name: { kind: "Name", value: "key" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "service" },
            name: { kind: "Name", value: "activeServiceCasesCount" },
          },
          { kind: "Field", name: { kind: "Name", value: "inactive" } },
          { kind: "Field", name: { kind: "Name", value: "inactiveStartDate" } },
          { kind: "Field", name: { kind: "Name", value: "inactiveEndDate" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "inactiveDescription" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "property" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "previousTenancy" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "startDate" } },
                { kind: "Field", name: { kind: "Name", value: "endDate" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "requestedEndDate" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "intervalAmount" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "intervalAmountType" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "depositAmount" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "depositDueDate" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "heatingAmount" },
                },
                { kind: "Field", name: { kind: "Name", value: "waterAmount" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "electricityAmount" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "user" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      { kind: "Field", name: { kind: "Name", value: "phone" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "protected" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "expectedMovingInCases" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "icon" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "expectedMovingOutCases" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "icon" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "movingInCase" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "icon" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attention" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "children" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "shortName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "icon" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "attention" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "movingOutCase" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "icon" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attention" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "children" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "shortName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "icon" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "attention" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "contractCase" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attention" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "awaiting" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "stateText" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "economic" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "debtor" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "externalId" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rentAmount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "parkingAmount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rentReductionAmount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "depositAmount" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "regulationDepositAmount",
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "regulationPrepaidRentAmount",
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "advanceWasteWaterAmount",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "advanceWaterAmount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "advanceHeatingAmount" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "advanceElectricityAmount",
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "settlementWasteWaterAmount",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "settlementWaterAmount" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "settlementHeatingAmount",
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "settlementElectricityAmount",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "otherAmount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "companySyncedAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "deposit" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "prepaidRent" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "currentTenancy" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "startDate" } },
                { kind: "Field", name: { kind: "Name", value: "endDate" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "requestedEndDate" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "depositAmount" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "intervalAmount" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "intervalAmountType" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "depositDueDate" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "heatingAmount" },
                },
                { kind: "Field", name: { kind: "Name", value: "waterAmount" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "electricityAmount" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "user" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      { kind: "Field", name: { kind: "Name", value: "phone" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "protected" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "expectedMovingInCases" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "icon" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "expectedMovingOutCases" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "icon" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "movingInCase" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "icon" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attention" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "children" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "shortName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "icon" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "attention" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "movingOutCase" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "icon" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attention" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "children" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "shortName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "icon" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "attention" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "contractCase" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attention" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "awaiting" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "stateText" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "economic" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "debtor" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "externalId" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rentAmount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "parkingAmount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rentReductionAmount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "depositAmount" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "regulationDepositAmount",
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "regulationPrepaidRentAmount",
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "advanceWasteWaterAmount",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "advanceWaterAmount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "advanceHeatingAmount" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "advanceElectricityAmount",
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "settlementWasteWaterAmount",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "settlementWaterAmount" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "settlementHeatingAmount",
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "settlementElectricityAmount",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "otherAmount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "companySyncedAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "deposit" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "prepaidRent" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "nextTenancy" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "startDate" } },
                { kind: "Field", name: { kind: "Name", value: "endDate" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "requestedEndDate" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "intervalAmount" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "intervalAmountType" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "depositAmount" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "depositDueDate" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "heatingAmount" },
                },
                { kind: "Field", name: { kind: "Name", value: "waterAmount" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "electricityAmount" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "user" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      { kind: "Field", name: { kind: "Name", value: "phone" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "protected" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "expectedMovingInCases" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "icon" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "expectedMovingOutCases" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "icon" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "movingInCase" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "icon" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attention" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "children" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "shortName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "icon" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "attention" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "movingOutCase" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "icon" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attention" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "children" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "shortName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "icon" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "attention" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "contractCase" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attention" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "awaiting" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "stateText" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "economic" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "debtor" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "externalId" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rentAmount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "parkingAmount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rentReductionAmount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "depositAmount" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "regulationDepositAmount",
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "regulationPrepaidRentAmount",
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "advanceWasteWaterAmount",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "advanceWaterAmount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "advanceHeatingAmount" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "advanceElectricityAmount",
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "settlementWasteWaterAmount",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "settlementWaterAmount" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "settlementHeatingAmount",
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "settlementElectricityAmount",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "otherAmount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "companySyncedAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "deposit" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "prepaidRent" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataTableApartmentsFragment, unknown>;
export const DataTableCasesFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "DataTableCases" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CaseInterface" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "icon" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "shortName" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "stateText" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "stateLastChangedAt" },
          },
          { kind: "Field", name: { kind: "Name", value: "attention" } },
          { kind: "Field", name: { kind: "Name", value: "awaiting" } },
          { kind: "Field", name: { kind: "Name", value: "startAfter" } },
          { kind: "Field", name: { kind: "Name", value: "completeBefore" } },
          { kind: "Field", name: { kind: "Name", value: "scheduledDate" } },
          { kind: "Field", name: { kind: "Name", value: "scheduledTime" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "requester" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
                { kind: "Field", name: { kind: "Name", value: "protected" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "responsible" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
                { kind: "Field", name: { kind: "Name", value: "protected" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "property" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "apartment" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "publicId" } },
                { kind: "Field", name: { kind: "Name", value: "address" } },
                { kind: "Field", name: { kind: "Name", value: "postalCode" } },
                { kind: "Field", name: { kind: "Name", value: "city" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "tenancy" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "startDate" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "requestedEndDate" },
                },
                { kind: "Field", name: { kind: "Name", value: "endDate" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "user" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      { kind: "Field", name: { kind: "Name", value: "phone" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "protected" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataTableCasesFragment, unknown>;
export const EconomicSubscriberFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "EconomicSubscriber" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "EconomicSubscriber" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "price" } },
          { kind: "Field", name: { kind: "Name", value: "canceledAt" } },
          { kind: "Field", name: { kind: "Name", value: "intervalStartAt" } },
          { kind: "Field", name: { kind: "Name", value: "intervalEndAt" } },
          { kind: "Field", name: { kind: "Name", value: "signedUpAt" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "debtor" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "addressText" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "subscription" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "intervalType" },
                },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EconomicSubscriberFragment, unknown>;
export const DataTablePropertiesFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "DataTableProperties" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Property" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "activeServiceCasesCount" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "administrator" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "owner" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataTablePropertiesFragment, unknown>;
export const DataTableTenanciesFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "DataTableTenancies" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Tenancy" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "startDate" } },
          { kind: "Field", name: { kind: "Name", value: "endDate" } },
          { kind: "Field", name: { kind: "Name", value: "requestedEndDate" } },
          { kind: "Field", name: { kind: "Name", value: "depositAmount" } },
          { kind: "Field", name: { kind: "Name", value: "depositDueDate" } },
          { kind: "Field", name: { kind: "Name", value: "intervalAmount" } },
          { kind: "Field", name: { kind: "Name", value: "heatingAmount" } },
          { kind: "Field", name: { kind: "Name", value: "waterAmount" } },
          { kind: "Field", name: { kind: "Name", value: "electricityAmount" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "expectedMovingInCases" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "icon" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "expectedMovingOutCases" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "icon" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "movingInCase" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "icon" } },
                { kind: "Field", name: { kind: "Name", value: "attention" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "children" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shortName" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "icon" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attention" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "movingOutCase" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "icon" } },
                { kind: "Field", name: { kind: "Name", value: "attention" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "children" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shortName" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "icon" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attention" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "contractCase" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "icon" } },
                { kind: "Field", name: { kind: "Name", value: "attention" } },
                { kind: "Field", name: { kind: "Name", value: "stateText" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "protected" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "apartment" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "publicId" } },
                { kind: "Field", name: { kind: "Name", value: "address" } },
                { kind: "Field", name: { kind: "Name", value: "city" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "economic" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "debtor" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "externalId" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "deposit" } },
                { kind: "Field", name: { kind: "Name", value: "prepaidRent" } },
                { kind: "Field", name: { kind: "Name", value: "rentAmount" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parkingAmount" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "rentReductionAmount" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "depositAmount" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "regulationDepositAmount" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "regulationPrepaidRentAmount" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "advanceWasteWaterAmount" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "advanceWaterAmount" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "advanceHeatingAmount" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "advanceElectricityAmount" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "settlementWasteWaterAmount" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "settlementWaterAmount" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "settlementHeatingAmount" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "settlementElectricityAmount" },
                },
                { kind: "Field", name: { kind: "Name", value: "otherAmount" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "companySyncedAt" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DataTableTenanciesFragment, unknown>;
export const CaseDefinitionCaseFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CaseDefinitionCaseFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CaseInterface" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "shortName" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "icon" } },
          { kind: "Field", name: { kind: "Name", value: "attention" } },
          { kind: "Field", name: { kind: "Name", value: "awaiting" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "stateText" } },
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CaseDefinitionCaseFragmentFragment, unknown>;
export const CaseDefinitionInfoFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CaseDefinitionInfoFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CaseDefinition" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "icon" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CaseDefinitionInfoFragmentFragment, unknown>;
export const ShortcutsListShortcutFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ShortcutsListShortcut" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Shortcut" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShortcutsListShortcutFragment, unknown>;
export const ScreenApartment_ApartmentJournalFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ScreenApartment_ApartmentJournal" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "TenancyNoteList" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "records" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "user" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "content" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "attachments" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fileName" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ScreenApartment_ApartmentJournalFragment, unknown>;
export const ScreenApartment_CaseFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ScreenApartment_Case" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CaseInterface" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "attention" } },
          { kind: "Field", name: { kind: "Name", value: "awaiting" } },
          { kind: "Field", name: { kind: "Name", value: "body" } },
          { kind: "Field", name: { kind: "Name", value: "completeBefore" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "icon" } },
          { kind: "Field", name: { kind: "Name", value: "isArchived" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "scheduledTime" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "stateText" } },
          { kind: "Field", name: { kind: "Name", value: "startAfter" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "apartment" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "address" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "responsible" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: {
              kind: "NamedType",
              name: { kind: "Name", value: "CleaningService" },
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parent" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "scheduledDate" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "scheduledDate" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "unreadCommentsCount" },
                },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: {
              kind: "NamedType",
              name: { kind: "Name", value: "CreateTenancy" },
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "unreadCommentsCount" },
                },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: {
              kind: "NamedType",
              name: { kind: "Name", value: "EndTenancy" },
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "awaiting" } },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: {
              kind: "NamedType",
              name: { kind: "Name", value: "FindTenancyAdministration" },
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parent" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "scheduledDate" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: {
              kind: "NamedType",
              name: { kind: "Name", value: "MiscAdministration" },
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parent" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "apartment" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "address" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "scheduledDate" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "scheduledDate" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "unreadCommentsCount" },
                },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: {
              kind: "NamedType",
              name: { kind: "Name", value: "MiscService" },
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parent" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "apartment" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "address" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "scheduledDate" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "scheduledDate" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "unreadCommentsCount" },
                },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: {
              kind: "NamedType",
              name: { kind: "Name", value: "MovingInService" },
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "isAttending" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "unreadCommentsCount" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parent" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "apartment" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "address" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "scheduledDate" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: {
              kind: "NamedType",
              name: { kind: "Name", value: "MovingInTenancy" },
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "unreadCommentsCount" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "scheduledDate" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parent" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "scheduledDate" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: {
              kind: "NamedType",
              name: { kind: "Name", value: "MovingOutService" },
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parent" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "scheduledDate" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "scheduledDate" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "unreadCommentsCount" },
                },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: {
              kind: "NamedType",
              name: { kind: "Name", value: "MovingOutTenancy" },
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "unreadCommentsCount" },
                },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: {
              kind: "NamedType",
              name: { kind: "Name", value: "MovingSettlementAdministration" },
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "apartment" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "address" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parent" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "scheduledDate" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "scheduledDate" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "unreadCommentsCount" },
                },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: {
              kind: "NamedType",
              name: { kind: "Name", value: "PreparationService" },
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "scheduledDate" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "apartment" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "address" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parent" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "scheduledDate" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "unreadCommentsCount" },
                },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: {
              kind: "NamedType",
              name: { kind: "Name", value: "RequestTenancy" },
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "unreadCommentsCount" },
                },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: {
              kind: "NamedType",
              name: { kind: "Name", value: "UtilityAdministration" },
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parent" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "scheduledDate" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "unreadCommentsCount" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ScreenApartment_CaseFragment, unknown>;
export const ScreenApartment_ContractFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ScreenApartment_Contract" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Tenancy" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "startDate" } },
          { kind: "Field", name: { kind: "Name", value: "endDate" } },
          { kind: "Field", name: { kind: "Name", value: "intervalAmount" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "intervalAmountType" },
          },
          { kind: "Field", name: { kind: "Name", value: "depositAmount" } },
          { kind: "Field", name: { kind: "Name", value: "hasEndTenancyCase" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "endTenancyTermsCondition" },
          },
          { kind: "Field", name: { kind: "Name", value: "intervalAmount" } },
          { kind: "Field", name: { kind: "Name", value: "depositAmount" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "contractCase" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "state" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "contract" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "endTenancyTermsCondition" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ScreenApartment_ContractFragment, unknown>;
export const ScreenApartment_DocumentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ScreenApartment_Document" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Document" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "url" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ScreenApartment_DocumentFragment, unknown>;
export const ScreenApartment_EconomicFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ScreenApartment_Economic" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "TenancyEconomic" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "debtor" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "addressText" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "subscriptions" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "interval" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "currentAmount" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "deposit" } },
          { kind: "Field", name: { kind: "Name", value: "prepaidRent" } },
          { kind: "Field", name: { kind: "Name", value: "companySyncedAt" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ScreenApartment_EconomicFragment, unknown>;
export const ScreenApartment_LandlordFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ScreenApartment_Landlord" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Property" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "name" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "owner" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "address" } },
                { kind: "Field", name: { kind: "Name", value: "postalCode" } },
                { kind: "Field", name: { kind: "Name", value: "city" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ScreenApartment_LandlordFragment, unknown>;
export const ScreenApartment_ManageTenantsAndResidentsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: {
        kind: "Name",
        value: "ScreenApartment_ManageTenantsAndResidents",
      },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Tenancy" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "residents" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "roles" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "isPrimary" } },
                { kind: "Field", name: { kind: "Name", value: "isResident" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "user" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      { kind: "Field", name: { kind: "Name", value: "phone" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isPhoneConfirmed" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isExternal" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "removable" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ScreenApartment_ManageTenantsAndResidentsFragment,
  unknown
>;
export const ScreenApartment_MessageFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ScreenApartment_Message" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Message" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "body" } },
          { kind: "Field", name: { kind: "Name", value: "authorId" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          { kind: "Field", name: { kind: "Name", value: "emailsSentAt" } },
          { kind: "Field", name: { kind: "Name", value: "smsSentAt" } },
          { kind: "Field", name: { kind: "Name", value: "apartment" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "property" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ScreenApartment_MessageFragment, unknown>;
export const CurrentUserFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CurrentUserFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "User" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "isSystemAdministrator" },
          },
          { kind: "Field", name: { kind: "Name", value: "isAdministrator" } },
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CurrentUserFragmentFragment, unknown>;
export const ShortcutFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ShortcutFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Shortcut" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShortcutFragmentFragment, unknown>;
export const PersonalAccessTokensQueryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "PersonalAccessTokensQuery" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "currentUser" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "personalAccessTokens" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "PersonalAccessTokenTokenFragment",
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PersonalAccessTokenTokenFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "PersonalAccessToken" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "token" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PersonalAccessTokensQueryQuery,
  PersonalAccessTokensQueryQueryVariables
>;
export const CreatePersonalAccessTokenDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreatePersonalAccessToken" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "name" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            alias: { kind: "Name", value: "result" },
            name: { kind: "Name", value: "personalAccessTokenCreate" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "name" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "name" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "errors" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "personalAccessToken" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "token" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreatePersonalAccessTokenMutation,
  CreatePersonalAccessTokenMutationVariables
>;
export const PersonalAccessTokenDeleteDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "PersonalAccessTokenDelete" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "personalAccessTokenDelete" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "errors" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PersonalAccessTokenDeleteMutation,
  PersonalAccessTokenDeleteMutationVariables
>;
export const ApartmentsCalendarDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ApartmentsCalendar" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "tenancyWhere" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "TenancyWhereInput" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "caseWhere" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "CaseWhereInput" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "tenancies" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "tenancyWhere" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "limit" },
                value: { kind: "IntValue", value: "1000" },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "records" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "startDate" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "endDate" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "movingInCase" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "attention" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "movingOutCase" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "attention" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "apartment" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "cases" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "caseWhere" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "limit" },
                value: { kind: "IntValue", value: "1000" },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "records" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "state" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attention" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "icon" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "calendarDate" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "awaiting" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "apartment" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApartmentsCalendarQuery,
  ApartmentsCalendarQueryVariables
>;
export const FilterCaseTypeDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "FilterCaseType" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "caseDefinitions" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "states" } },
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "states" } },
                { kind: "Field", name: { kind: "Name", value: "icon" } },
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FilterCaseTypeQuery, FilterCaseTypeQueryVariables>;
export const FilterCaseWorkerDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "FilterCaseWorker" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "teamMembers" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "caseWorkers" },
                      value: { kind: "BooleanValue", value: true },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "records" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FilterCaseWorkerQuery,
  FilterCaseWorkerQueryVariables
>;
export const FilterPropertyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "FilterProperty" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "properties" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "archived" },
                      value: { kind: "BooleanValue", value: false },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "limit" },
                value: { kind: "IntValue", value: "1000" },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: { kind: "EnumValue", value: "name_ASC" },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "records" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FilterPropertyQuery, FilterPropertyQueryVariables>;
export const HeaderActionsDataDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "HeaderActionsData" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            alias: { kind: "Name", value: "inbox" },
            name: { kind: "Name", value: "caseDefinitions" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CaseDefinitionInfoFragment" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "cases" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "where" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "newForCurrentUser" },
                            value: { kind: "BooleanValue", value: true },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "records" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "CaseDefinitionCaseFragment",
                              },
                            },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "count" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "flagged" },
            name: { kind: "Name", value: "caseDefinitions" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CaseDefinitionInfoFragment" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "cases" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "where" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: {
                              kind: "Name",
                              value: "flaggedForCurrentUser",
                            },
                            value: { kind: "BooleanValue", value: true },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "records" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "CaseDefinitionCaseFragment",
                              },
                            },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "count" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "messages" },
            name: { kind: "Name", value: "caseDefinitions" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CaseDefinitionInfoFragment" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "cases" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "where" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: {
                              kind: "Name",
                              value: "commentsForCurrentUser",
                            },
                            value: { kind: "BooleanValue", value: true },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "records" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "CaseDefinitionCaseFragment",
                              },
                            },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "count" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CaseDefinitionInfoFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CaseDefinition" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "icon" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CaseDefinitionCaseFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CaseInterface" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "shortName" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "icon" } },
          { kind: "Field", name: { kind: "Name", value: "attention" } },
          { kind: "Field", name: { kind: "Name", value: "awaiting" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "stateText" } },
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  HeaderActionsDataQuery,
  HeaderActionsDataQueryVariables
>;
export const GlobalSearchQueryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GlobalSearchQuery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "searchValue" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "tenancies" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "search" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "searchValue" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "archived" },
                      value: { kind: "BooleanValue", value: false },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "limit" },
                value: { kind: "IntValue", value: "10" },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "records" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "apartment" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "address" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "cases" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "search" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "searchValue" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "archived" },
                      value: { kind: "BooleanValue", value: false },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "limit" },
                value: { kind: "IntValue", value: "10" },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "records" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shortName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "stateText" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "apartment" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "address" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GlobalSearchQueryQuery,
  GlobalSearchQueryQueryVariables
>;
export const ShortcutControlDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ShortcutControl" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shortcutId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "currentUser" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shortcut" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "shortcutId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "shortcutId" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ShortcutControlQuery,
  ShortcutControlQueryVariables
>;
export const ShortcutUpdateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ShortcutUpdate" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shortcutId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "attributes" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "ShortcutAttributesInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "shortcutUpdate" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "shortcutId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "shortcutId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "attributes" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "attributes" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "errors" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ShortcutUpdateMutation,
  ShortcutUpdateMutationVariables
>;
export const ShortcutCreateMutationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ShortcutCreateMutation" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "attributes" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "ShortcutAttributesInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "shortcutCreate" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "attributes" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "attributes" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "errors" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shortcut" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ShortcutCreateMutationMutation,
  ShortcutCreateMutationMutationVariables
>;
export const ShortcutRemoveDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ShortcutRemove" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "shortcutDelete" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "shortcutId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "shortcutId" } },
                { kind: "Field", name: { kind: "Name", value: "errors" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ShortcutRemoveMutation,
  ShortcutRemoveMutationVariables
>;
export const ShortcutsListDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ShortcutsList" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "where" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "ShortcutWhereInput" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "currentUser" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shortcuts" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "where" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "where" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "ShortcutsListShortcut",
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "shortcutsTotal" },
                  name: { kind: "Name", value: "shortcuts" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalCount" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ShortcutsListShortcut" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Shortcut" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShortcutsListQuery, ShortcutsListQueryVariables>;
export const TenancyNoteDeleteDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "TenancyNoteDelete" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "tenancyId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "noteId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "tenancyNoteDelete" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "tenancyId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "tenancyId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "noteId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "noteId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "errors" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TenancyNoteDeleteMutation,
  TenancyNoteDeleteMutationVariables
>;
export const TenancyNoteCreateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "TenancyNoteCreate" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "tenancyId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "content" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "attachments" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "String" },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "tenancyNoteCreate" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "tenancyId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "tenancyId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "content" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "content" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "attachments" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "attachments" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "errors" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "tenancyNote" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "content" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attachments" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "fileName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "url" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdAt" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TenancyNoteCreateMutation,
  TenancyNoteCreateMutationVariables
>;
export const TenancyNoteUpdateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "TenancyNoteUpdate" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "tenancyId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "noteId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "content" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "attachments" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "String" },
              },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "attachmentsToRemove" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "String" },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "tenancyNoteUpdate" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "tenancyId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "tenancyId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "noteId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "noteId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "content" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "content" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "attachments" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "attachments" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "attachmentsToRemove" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "attachmentsToRemove" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "errors" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "tenancyNote" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "content" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attachments" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "fileName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "url" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdAt" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TenancyNoteUpdateMutation,
  TenancyNoteUpdateMutationVariables
>;
export const ScreenApartment_BodyApartmentDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ScreenApartment_BodyApartment" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "apartmentId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "archived" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "apartment" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "apartmentId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "address" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "property" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "ScreenApartment_Landlord",
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "messages" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "orderBy" },
                      value: { kind: "EnumValue", value: "createdAt_DESC" },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "records" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "ScreenApartment_Message",
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "cases" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "orderBy" },
                      value: { kind: "EnumValue", value: "createdAt_DESC" },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "where" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "withTenancy" },
                            value: { kind: "BooleanValue", value: false },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "archived" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "archived" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "records" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "ScreenApartment_Case",
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "documents" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "ScreenApartment_Document",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ScreenApartment_Landlord" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Property" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "name" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "owner" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "address" } },
                { kind: "Field", name: { kind: "Name", value: "postalCode" } },
                { kind: "Field", name: { kind: "Name", value: "city" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ScreenApartment_Message" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Message" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "body" } },
          { kind: "Field", name: { kind: "Name", value: "authorId" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          { kind: "Field", name: { kind: "Name", value: "emailsSentAt" } },
          { kind: "Field", name: { kind: "Name", value: "smsSentAt" } },
          { kind: "Field", name: { kind: "Name", value: "apartment" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "property" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ScreenApartment_Case" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CaseInterface" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "attention" } },
          { kind: "Field", name: { kind: "Name", value: "awaiting" } },
          { kind: "Field", name: { kind: "Name", value: "body" } },
          { kind: "Field", name: { kind: "Name", value: "completeBefore" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "icon" } },
          { kind: "Field", name: { kind: "Name", value: "isArchived" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "scheduledTime" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "stateText" } },
          { kind: "Field", name: { kind: "Name", value: "startAfter" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "apartment" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "address" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "responsible" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: {
              kind: "NamedType",
              name: { kind: "Name", value: "CleaningService" },
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parent" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "scheduledDate" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "scheduledDate" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "unreadCommentsCount" },
                },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: {
              kind: "NamedType",
              name: { kind: "Name", value: "CreateTenancy" },
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "unreadCommentsCount" },
                },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: {
              kind: "NamedType",
              name: { kind: "Name", value: "EndTenancy" },
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "awaiting" } },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: {
              kind: "NamedType",
              name: { kind: "Name", value: "FindTenancyAdministration" },
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parent" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "scheduledDate" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: {
              kind: "NamedType",
              name: { kind: "Name", value: "MiscAdministration" },
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parent" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "apartment" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "address" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "scheduledDate" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "scheduledDate" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "unreadCommentsCount" },
                },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: {
              kind: "NamedType",
              name: { kind: "Name", value: "MiscService" },
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parent" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "apartment" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "address" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "scheduledDate" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "scheduledDate" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "unreadCommentsCount" },
                },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: {
              kind: "NamedType",
              name: { kind: "Name", value: "MovingInService" },
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "isAttending" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "unreadCommentsCount" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parent" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "apartment" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "address" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "scheduledDate" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: {
              kind: "NamedType",
              name: { kind: "Name", value: "MovingInTenancy" },
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "unreadCommentsCount" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "scheduledDate" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parent" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "scheduledDate" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: {
              kind: "NamedType",
              name: { kind: "Name", value: "MovingOutService" },
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parent" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "scheduledDate" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "scheduledDate" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "unreadCommentsCount" },
                },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: {
              kind: "NamedType",
              name: { kind: "Name", value: "MovingOutTenancy" },
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "unreadCommentsCount" },
                },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: {
              kind: "NamedType",
              name: { kind: "Name", value: "MovingSettlementAdministration" },
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "apartment" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "address" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parent" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "scheduledDate" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "scheduledDate" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "unreadCommentsCount" },
                },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: {
              kind: "NamedType",
              name: { kind: "Name", value: "PreparationService" },
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "scheduledDate" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "apartment" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "address" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parent" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "scheduledDate" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "unreadCommentsCount" },
                },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: {
              kind: "NamedType",
              name: { kind: "Name", value: "RequestTenancy" },
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "unreadCommentsCount" },
                },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: {
              kind: "NamedType",
              name: { kind: "Name", value: "UtilityAdministration" },
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parent" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "scheduledDate" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "unreadCommentsCount" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ScreenApartment_Document" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Document" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "url" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ScreenApartment_BodyApartmentQuery,
  ScreenApartment_BodyApartmentQueryVariables
>;
export const ScreenApartment_BodyTenancyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ScreenApartment_BodyTenancy" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "apartmentId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "tenancyId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "apartment" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "apartmentId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "tenancy" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenancyId" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "ScreenApartment_ManageTenantsAndResidents",
                        },
                      },
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "ScreenApartment_Contract",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "economic" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "ScreenApartment_Economic",
                              },
                            },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "startDate" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "endDate" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "roles" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "isPhoneConfirmed",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "notes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "ScreenApartment_ApartmentJournal",
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cases" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "orderBy" },
                            value: {
                              kind: "EnumValue",
                              value: "createdAt_DESC",
                            },
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "records" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "FragmentSpread",
                                    name: {
                                      kind: "Name",
                                      value: "ScreenApartment_Case",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "documents" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "ScreenApartment_Document",
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: {
        kind: "Name",
        value: "ScreenApartment_ManageTenantsAndResidents",
      },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Tenancy" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "residents" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "roles" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "isPrimary" } },
                { kind: "Field", name: { kind: "Name", value: "isResident" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "user" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      { kind: "Field", name: { kind: "Name", value: "phone" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isPhoneConfirmed" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isExternal" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "removable" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ScreenApartment_Contract" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Tenancy" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "startDate" } },
          { kind: "Field", name: { kind: "Name", value: "endDate" } },
          { kind: "Field", name: { kind: "Name", value: "intervalAmount" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "intervalAmountType" },
          },
          { kind: "Field", name: { kind: "Name", value: "depositAmount" } },
          { kind: "Field", name: { kind: "Name", value: "hasEndTenancyCase" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "endTenancyTermsCondition" },
          },
          { kind: "Field", name: { kind: "Name", value: "intervalAmount" } },
          { kind: "Field", name: { kind: "Name", value: "depositAmount" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "contractCase" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "state" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "contract" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "endTenancyTermsCondition" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ScreenApartment_Economic" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "TenancyEconomic" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "debtor" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "addressText" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "subscriptions" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "interval" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "currentAmount" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "deposit" } },
          { kind: "Field", name: { kind: "Name", value: "prepaidRent" } },
          { kind: "Field", name: { kind: "Name", value: "companySyncedAt" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ScreenApartment_ApartmentJournal" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "TenancyNoteList" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "records" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "user" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "content" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "attachments" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fileName" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ScreenApartment_Case" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CaseInterface" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "attention" } },
          { kind: "Field", name: { kind: "Name", value: "awaiting" } },
          { kind: "Field", name: { kind: "Name", value: "body" } },
          { kind: "Field", name: { kind: "Name", value: "completeBefore" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "icon" } },
          { kind: "Field", name: { kind: "Name", value: "isArchived" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "scheduledTime" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "stateText" } },
          { kind: "Field", name: { kind: "Name", value: "startAfter" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "apartment" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "address" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "responsible" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: {
              kind: "NamedType",
              name: { kind: "Name", value: "CleaningService" },
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parent" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "scheduledDate" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "scheduledDate" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "unreadCommentsCount" },
                },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: {
              kind: "NamedType",
              name: { kind: "Name", value: "CreateTenancy" },
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "unreadCommentsCount" },
                },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: {
              kind: "NamedType",
              name: { kind: "Name", value: "EndTenancy" },
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "awaiting" } },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: {
              kind: "NamedType",
              name: { kind: "Name", value: "FindTenancyAdministration" },
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parent" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "scheduledDate" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: {
              kind: "NamedType",
              name: { kind: "Name", value: "MiscAdministration" },
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parent" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "apartment" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "address" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "scheduledDate" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "scheduledDate" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "unreadCommentsCount" },
                },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: {
              kind: "NamedType",
              name: { kind: "Name", value: "MiscService" },
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parent" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "apartment" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "address" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "scheduledDate" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "scheduledDate" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "unreadCommentsCount" },
                },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: {
              kind: "NamedType",
              name: { kind: "Name", value: "MovingInService" },
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "isAttending" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "unreadCommentsCount" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parent" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "apartment" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "address" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "scheduledDate" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: {
              kind: "NamedType",
              name: { kind: "Name", value: "MovingInTenancy" },
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "unreadCommentsCount" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "scheduledDate" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parent" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "scheduledDate" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: {
              kind: "NamedType",
              name: { kind: "Name", value: "MovingOutService" },
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parent" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "scheduledDate" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "scheduledDate" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "unreadCommentsCount" },
                },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: {
              kind: "NamedType",
              name: { kind: "Name", value: "MovingOutTenancy" },
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "unreadCommentsCount" },
                },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: {
              kind: "NamedType",
              name: { kind: "Name", value: "MovingSettlementAdministration" },
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "apartment" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "address" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parent" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "scheduledDate" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "scheduledDate" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "unreadCommentsCount" },
                },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: {
              kind: "NamedType",
              name: { kind: "Name", value: "PreparationService" },
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "scheduledDate" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "apartment" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "address" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parent" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "scheduledDate" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "unreadCommentsCount" },
                },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: {
              kind: "NamedType",
              name: { kind: "Name", value: "RequestTenancy" },
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "unreadCommentsCount" },
                },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: {
              kind: "NamedType",
              name: { kind: "Name", value: "UtilityAdministration" },
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parent" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "scheduledDate" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "unreadCommentsCount" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ScreenApartment_Document" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Document" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "url" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ScreenApartment_BodyTenancyQuery,
  ScreenApartment_BodyTenancyQueryVariables
>;
export const CasesByParentDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "casesByParent" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "ids" } },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "cases" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: { kind: "EnumValue", value: "apartment_DESC" },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "byParent" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "ids" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "records" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "icon" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attention" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "awaiting" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "stateText" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "state" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CasesByParentQuery, CasesByParentQueryVariables>;
export const ApartmentContractEditDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ApartmentContractEdit" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "depositAmount" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Float" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "intervalAmount" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Float" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "startDate" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "ISO8601Date" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "endDate" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "ISO8601Date" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "endTenancyTerms" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "tenancyId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "tenancyUpdate" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "tenancyId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "tenancyId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "endDate" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "endDate" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "startDate" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "startDate" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "depositAmount" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "depositAmount" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "intervalAmount" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "intervalAmount" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "endTenancyTerms" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "endTenancyTerms" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "errors" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "tenancy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApartmentContractEditMutation,
  ApartmentContractEditMutationVariables
>;
export const ApartmentContractTerminateModal_EndTenancyTermsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: {
        kind: "Name",
        value: "ApartmentContractTerminateModal_EndTenancyTerms",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "tenancyId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "endDate" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "ISO8601Date" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "tenancy" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "tenancyId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "endTenancyTerms" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "endDate" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "endDate" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "endDate" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "earlyEndDate" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "endDateLongFormat" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastRentDate" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastRentDateLongFormat" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "leaveDate" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "leaveDateLongFormat" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApartmentContractTerminateModal_EndTenancyTermsQuery,
  ApartmentContractTerminateModal_EndTenancyTermsQueryVariables
>;
export const ApartmentContractTerminateModal_CreateEndTenancyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {
        kind: "Name",
        value: "ApartmentContractTerminateModal_CreateEndTenancy",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "endDate" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "ISO8601Date" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "comment" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "tenancyId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "tenancyEnd" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "endDate" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "endDate" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "comment" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "comment" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "tenancyId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "tenancyId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "errors" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "case" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApartmentContractTerminateModal_CreateEndTenancyMutation,
  ApartmentContractTerminateModal_CreateEndTenancyMutationVariables
>;
export const EconomicDebtorsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "economicDebtors" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "tenancyId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "economicDebtors" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "byTenancy" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "tenancyId" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "records" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "addressText" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EconomicDebtorsQuery,
  EconomicDebtorsQueryVariables
>;
export const TenancyDebtorLinkDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "tenancyDebtorLink" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "tenancyId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "debtorId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "tenancyDebtorLink" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "tenancyId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "tenancyId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "debtorId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "debtorId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "isLinked" } },
                { kind: "Field", name: { kind: "Name", value: "errors" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TenancyDebtorLinkMutation,
  TenancyDebtorLinkMutationVariables
>;
export const ScreenApartment_EconomicHistoryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ScreenApartment_EconomicHistory" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "tenancyId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "subscriptionId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "tenancy" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "tenancyId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "economic" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "subscription" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "id" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "subscriptionId" },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "history" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    alias: { kind: "Name", value: "amount" },
                                    name: { kind: "Name", value: "price" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "intervalStartAt",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "intervalEndAt",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ScreenApartment_EconomicHistoryQuery,
  ScreenApartment_EconomicHistoryQueryVariables
>;
export const LookupUserDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "lookupUser" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "email" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "lookupUser" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "email" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "email" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LookupUserMutation, LookupUserMutationVariables>;
export const PhoneConfirmDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "PhoneConfirm" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "userId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            alias: { kind: "Name", value: "result" },
            name: { kind: "Name", value: "phoneConfirm" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "userId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "userId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "errors" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "user" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PhoneConfirmMutation,
  PhoneConfirmMutationVariables
>;
export const UpdateExternalUserDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateExternalUser" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "userId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "email" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "name" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "phone" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            alias: { kind: "Name", value: "result" },
            name: { kind: "Name", value: "updateExternalUser" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "userId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "userId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "email" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "email" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "name" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "name" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "phone" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "phone" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "errors" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "user" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      { kind: "Field", name: { kind: "Name", value: "phone" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateExternalUserMutation,
  UpdateExternalUserMutationVariables
>;
export const ManageTenantsAndResidentsRemoveTenancyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ManageTenantsAndResidentsRemoveTenancy" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "tenancyId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "tenancyRemove" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "tenancyId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "tenancy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "ScreenApartment_ManageTenantsAndResidents",
                        },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "errors" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: {
        kind: "Name",
        value: "ScreenApartment_ManageTenantsAndResidents",
      },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Tenancy" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "residents" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "roles" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "isPrimary" } },
                { kind: "Field", name: { kind: "Name", value: "isResident" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "user" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      { kind: "Field", name: { kind: "Name", value: "phone" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isPhoneConfirmed" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isExternal" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "removable" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ManageTenantsAndResidentsRemoveTenancyMutation,
  ManageTenantsAndResidentsRemoveTenancyMutationVariables
>;
export const TenancyResidentCreateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "tenancyResidentCreate" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "tenancyId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "name" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "email" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "phone" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "tenancyResidentCreate" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "tenancyId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "tenancyId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "name" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "name" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "email" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "email" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "phone" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "phone" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "resident" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TenancyResidentCreateMutation,
  TenancyResidentCreateMutationVariables
>;
export const TenancyResidentUpdateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "tenancyResidentUpdate" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "residentId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "name" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "email" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "phone" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "tenancyResidentUpdate" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "residentId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "residentId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "name" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "name" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "email" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "email" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "phone" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "phone" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "errors" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "resident" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TenancyResidentUpdateMutation,
  TenancyResidentUpdateMutationVariables
>;
export const TenancyResidentRemoveDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "tenancyResidentRemove" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "residentId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "tenancyResidentRemove" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "residentId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "residentId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "errors" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "resident" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TenancyResidentRemoveMutation,
  TenancyResidentRemoveMutationVariables
>;
export const TenancyRoleCreateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "tenancyRoleCreate" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "tenancyId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "userId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "isPrimary" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "isResident" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "email" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "name" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "phone" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "tenancyRoleCreate" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "tenancyId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "tenancyId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "userId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "userId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "isPrimary" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "isPrimary" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "isResident" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "isResident" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "email" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "email" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "name" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "name" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "phone" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "phone" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "errors" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "tenancyRole" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isPrimary" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isResident" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TenancyRoleCreateMutation,
  TenancyRoleCreateMutationVariables
>;
export const TenancyRoleUpdateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "tenancyRoleUpdate" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "tenancyRoleId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "isPrimary" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "isResident" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "tenancyRoleUpdate" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "tenancyRoleId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "tenancyRoleId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "isPrimary" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "isPrimary" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "isResident" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "isResident" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "errors" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "tenancyRole" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isPrimary" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isResident" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TenancyRoleUpdateMutation,
  TenancyRoleUpdateMutationVariables
>;
export const TenancyRoleRemoveDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "tenancyRoleRemove" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "tenancyRoleId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "tenancyRoleRemove" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "tenancyRoleId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "tenancyRoleId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "errors" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "tenancyRole" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isPrimary" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isResident" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TenancyRoleRemoveMutation,
  TenancyRoleRemoveMutationVariables
>;
export const ScreenApartmentDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ScreenApartment" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "apartmentId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "apartment" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "apartmentId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "address" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "tenancies" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "records" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "startDate" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "endDate" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "roles" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "user" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ScreenApartmentQuery,
  ScreenApartmentQueryVariables
>;
export const ApartmentsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Apartments" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "where" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "ApartmentWhereInput" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "orderBy" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "ApartmentOrderByEnum" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "offset" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "limit" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "apartments" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "where" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "orderBy" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "offset" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "offset" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "limit" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "limit" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "records" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "DataTableApartments" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "total" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "DataTableApartments" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Apartment" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "publicId" } },
          { kind: "Field", name: { kind: "Name", value: "address" } },
          { kind: "Field", name: { kind: "Name", value: "city" } },
          { kind: "Field", name: { kind: "Name", value: "key" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "service" },
            name: { kind: "Name", value: "activeServiceCasesCount" },
          },
          { kind: "Field", name: { kind: "Name", value: "inactive" } },
          { kind: "Field", name: { kind: "Name", value: "inactiveStartDate" } },
          { kind: "Field", name: { kind: "Name", value: "inactiveEndDate" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "inactiveDescription" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "property" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "previousTenancy" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "startDate" } },
                { kind: "Field", name: { kind: "Name", value: "endDate" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "requestedEndDate" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "intervalAmount" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "intervalAmountType" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "depositAmount" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "depositDueDate" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "heatingAmount" },
                },
                { kind: "Field", name: { kind: "Name", value: "waterAmount" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "electricityAmount" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "user" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      { kind: "Field", name: { kind: "Name", value: "phone" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "protected" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "expectedMovingInCases" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "icon" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "expectedMovingOutCases" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "icon" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "movingInCase" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "icon" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attention" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "children" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "shortName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "icon" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "attention" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "movingOutCase" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "icon" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attention" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "children" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "shortName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "icon" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "attention" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "contractCase" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attention" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "awaiting" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "stateText" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "economic" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "debtor" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "externalId" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rentAmount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "parkingAmount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rentReductionAmount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "depositAmount" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "regulationDepositAmount",
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "regulationPrepaidRentAmount",
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "advanceWasteWaterAmount",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "advanceWaterAmount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "advanceHeatingAmount" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "advanceElectricityAmount",
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "settlementWasteWaterAmount",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "settlementWaterAmount" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "settlementHeatingAmount",
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "settlementElectricityAmount",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "otherAmount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "companySyncedAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "deposit" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "prepaidRent" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "currentTenancy" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "startDate" } },
                { kind: "Field", name: { kind: "Name", value: "endDate" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "requestedEndDate" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "depositAmount" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "intervalAmount" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "intervalAmountType" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "depositDueDate" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "heatingAmount" },
                },
                { kind: "Field", name: { kind: "Name", value: "waterAmount" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "electricityAmount" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "user" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      { kind: "Field", name: { kind: "Name", value: "phone" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "protected" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "expectedMovingInCases" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "icon" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "expectedMovingOutCases" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "icon" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "movingInCase" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "icon" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attention" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "children" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "shortName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "icon" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "attention" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "movingOutCase" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "icon" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attention" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "children" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "shortName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "icon" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "attention" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "contractCase" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attention" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "awaiting" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "stateText" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "economic" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "debtor" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "externalId" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rentAmount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "parkingAmount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rentReductionAmount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "depositAmount" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "regulationDepositAmount",
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "regulationPrepaidRentAmount",
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "advanceWasteWaterAmount",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "advanceWaterAmount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "advanceHeatingAmount" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "advanceElectricityAmount",
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "settlementWasteWaterAmount",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "settlementWaterAmount" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "settlementHeatingAmount",
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "settlementElectricityAmount",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "otherAmount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "companySyncedAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "deposit" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "prepaidRent" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "nextTenancy" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "startDate" } },
                { kind: "Field", name: { kind: "Name", value: "endDate" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "requestedEndDate" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "intervalAmount" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "intervalAmountType" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "depositAmount" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "depositDueDate" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "heatingAmount" },
                },
                { kind: "Field", name: { kind: "Name", value: "waterAmount" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "electricityAmount" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "user" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      { kind: "Field", name: { kind: "Name", value: "phone" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "protected" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "expectedMovingInCases" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "icon" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "expectedMovingOutCases" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "icon" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "movingInCase" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "icon" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attention" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "children" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "shortName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "icon" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "attention" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "movingOutCase" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "icon" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attention" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "children" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "type" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "shortName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "icon" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "attention" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "contractCase" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attention" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "awaiting" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "stateText" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "economic" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "debtor" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "externalId" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rentAmount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "parkingAmount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rentReductionAmount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "depositAmount" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "regulationDepositAmount",
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "regulationPrepaidRentAmount",
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "advanceWasteWaterAmount",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "advanceWaterAmount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "advanceHeatingAmount" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "advanceElectricityAmount",
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "settlementWasteWaterAmount",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "settlementWaterAmount" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "settlementHeatingAmount",
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "settlementElectricityAmount",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "otherAmount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "companySyncedAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "deposit" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "prepaidRent" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ApartmentsQuery, ApartmentsQueryVariables>;
export const CasesCountDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "CasesCount" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            alias: { kind: "Name", value: "new" },
            name: { kind: "Name", value: "cases" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "newForCurrentUser" },
                      value: { kind: "BooleanValue", value: true },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "total" } },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "comments" },
            name: { kind: "Name", value: "cases" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "commentsForCurrentUser" },
                      value: { kind: "BooleanValue", value: true },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "total" } },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "flagged" },
            name: { kind: "Name", value: "cases" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "flaggedForCurrentUser" },
                      value: { kind: "BooleanValue", value: true },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "total" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CasesCountQuery, CasesCountQueryVariables>;
export const CasesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Cases" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "where" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CaseWhereInput" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "orderBy" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CaseOrderByEnum" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "offset" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "limit" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "cases" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "where" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "orderBy" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "offset" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "offset" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "limit" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "limit" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "records" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "DataTableCases" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "total" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "DataTableCases" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CaseInterface" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "icon" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "shortName" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
          { kind: "Field", name: { kind: "Name", value: "stateText" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "stateLastChangedAt" },
          },
          { kind: "Field", name: { kind: "Name", value: "attention" } },
          { kind: "Field", name: { kind: "Name", value: "awaiting" } },
          { kind: "Field", name: { kind: "Name", value: "startAfter" } },
          { kind: "Field", name: { kind: "Name", value: "completeBefore" } },
          { kind: "Field", name: { kind: "Name", value: "scheduledDate" } },
          { kind: "Field", name: { kind: "Name", value: "scheduledTime" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "requester" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
                { kind: "Field", name: { kind: "Name", value: "protected" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "responsible" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
                { kind: "Field", name: { kind: "Name", value: "protected" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "property" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "apartment" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "publicId" } },
                { kind: "Field", name: { kind: "Name", value: "address" } },
                { kind: "Field", name: { kind: "Name", value: "postalCode" } },
                { kind: "Field", name: { kind: "Name", value: "city" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "tenancy" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "startDate" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "requestedEndDate" },
                },
                { kind: "Field", name: { kind: "Name", value: "endDate" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "user" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      { kind: "Field", name: { kind: "Name", value: "phone" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "protected" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CasesQuery, CasesQueryVariables>;
export const CompaniesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Companies" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "companies" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: { kind: "EnumValue", value: "name_ASC" },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "records" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isOwner" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isAdministrator" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CompaniesQuery, CompaniesQueryVariables>;
export const PropertiesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Properties" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "where" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "PropertyWhereInput" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "orderBy" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "PropertyOrderByEnum" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "offset" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "limit" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "properties" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "where" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "orderBy" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "offset" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "offset" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "limit" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "limit" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "records" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "DataTableProperties" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "total" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "DataTableProperties" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Property" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "activeServiceCasesCount" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "administrator" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "owner" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PropertiesQuery, PropertiesQueryVariables>;
export const TenanciesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Tenancies" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "where" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "TenancyWhereInput" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "orderBy" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "TenancyOrderByEnum" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "offset" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "limit" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "tenancies" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "where" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "orderBy" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "offset" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "offset" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "limit" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "limit" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "records" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "DataTableTenancies" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "total" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "DataTableTenancies" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Tenancy" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "startDate" } },
          { kind: "Field", name: { kind: "Name", value: "endDate" } },
          { kind: "Field", name: { kind: "Name", value: "requestedEndDate" } },
          { kind: "Field", name: { kind: "Name", value: "depositAmount" } },
          { kind: "Field", name: { kind: "Name", value: "depositDueDate" } },
          { kind: "Field", name: { kind: "Name", value: "intervalAmount" } },
          { kind: "Field", name: { kind: "Name", value: "heatingAmount" } },
          { kind: "Field", name: { kind: "Name", value: "waterAmount" } },
          { kind: "Field", name: { kind: "Name", value: "electricityAmount" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "expectedMovingInCases" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "icon" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "expectedMovingOutCases" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "icon" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "movingInCase" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "icon" } },
                { kind: "Field", name: { kind: "Name", value: "attention" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "children" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shortName" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "icon" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attention" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "movingOutCase" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "icon" } },
                { kind: "Field", name: { kind: "Name", value: "attention" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "children" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "shortName" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "icon" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "attention" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "contractCase" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "icon" } },
                { kind: "Field", name: { kind: "Name", value: "attention" } },
                { kind: "Field", name: { kind: "Name", value: "stateText" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "protected" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "apartment" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "publicId" } },
                { kind: "Field", name: { kind: "Name", value: "address" } },
                { kind: "Field", name: { kind: "Name", value: "city" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "economic" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "debtor" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "externalId" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "deposit" } },
                { kind: "Field", name: { kind: "Name", value: "prepaidRent" } },
                { kind: "Field", name: { kind: "Name", value: "rentAmount" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "parkingAmount" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "rentReductionAmount" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "depositAmount" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "regulationDepositAmount" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "regulationPrepaidRentAmount" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "advanceWasteWaterAmount" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "advanceWaterAmount" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "advanceHeatingAmount" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "advanceElectricityAmount" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "settlementWasteWaterAmount" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "settlementWaterAmount" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "settlementHeatingAmount" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "settlementElectricityAmount" },
                },
                { kind: "Field", name: { kind: "Name", value: "otherAmount" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "companySyncedAt" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TenanciesQuery, TenanciesQueryVariables>;
export const ScreenTenancyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ScreenTenancy" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "tenancyId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "tenancy" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "tenancyId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "apartment" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ScreenTenancyQuery, ScreenTenancyQueryVariables>;
export const CurrentUserDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "CurrentUser" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "currentUser" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CurrentUserFragment" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CurrentUserFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "User" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "isSystemAdministrator" },
          },
          { kind: "Field", name: { kind: "Name", value: "isAdministrator" } },
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CurrentUserQuery, CurrentUserQueryVariables>;
export const ShortcutDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Shortcut" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shortcutId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "currentUser" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shortcut" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "shortcutId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "shortcutId" },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "ShortcutFragment" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ShortcutFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Shortcut" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "state" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShortcutQuery, ShortcutQueryVariables>;
