import { useCallback, useEffect, useRef } from "react";
export const useKeyPress = (key: string, callback: () => void, isShortcutCommand?: boolean) => {
  const callbackRef = useRef(callback);
  useEffect(() => {
    callbackRef.current = callback;
  });
  const handleKeyPress = useCallback((e: KeyboardEvent) => {
    const isRequiredKeyPressed = e.key === key;
    const isShortcutKeyPressed = e.ctrlKey || e.metaKey;
    const isNavKeyPressed = e.key === "ArrowUp" || e.key === "ArrowDown";
    if (isRequiredKeyPressed) {
      if (isShortcutCommand && isShortcutKeyPressed || isNavKeyPressed) {
        e.preventDefault();
        callbackRef.current();
        return;
      }
      !isShortcutCommand && callbackRef.current();
    }
  }, [key, isShortcutCommand]);
  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => document.removeEventListener("keydown", handleKeyPress);
  }, [handleKeyPress]);
};