import { useDisclosure, useTheme } from "@chakra-ui/react";
import { useKeyPress } from "admin/lib/useKeyPress";
import { ToolButton } from "../ToolButton";
import { GlobalSearchModal } from "./GlobalSearchModal";
export const GlobalSearch = () => {
  const {
    isOpen,
    onOpen,
    onClose
  } = useDisclosure();
  const {
    icons: {
      IconSearch
    }
  } = useTheme();
  const handleModalClose = () => {
    onClose();
  };
  useKeyPress("k", () => {
    onOpen();
  }, true);
  return <>
      <ToolButton icon={IconSearch} label="Search" onClick={onOpen} data-sentry-element="ToolButton" data-sentry-source-file="GlobalSearch.tsx" />
      {isOpen && <GlobalSearchModal onClose={handleModalClose} />}
    </>;
};