import { ComponentWithAs, HStack, IconProps, Text } from "@chakra-ui/react";
interface FooterPopoverTitleProps {
  icon?: ComponentWithAs<"svg", IconProps>;
  title: string;
}
export const FooterPopoverTitle = ({
  title,
  icon: Icon
}: FooterPopoverTitleProps) => {
  return <HStack data-sentry-element="HStack" data-sentry-component="FooterPopoverTitle" data-sentry-source-file="FooterPopoverTitle.tsx">
      {Icon && <Icon fontSize={28} />}
      <Text textStyle="sub-md-strong" color="primary.800" data-sentry-element="Text" data-sentry-source-file="FooterPopoverTitle.tsx">
        {title}
      </Text>
    </HStack>;
};