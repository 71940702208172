import { Box, BoxProps, Collapse, Portal, useOutsideClick } from "@chakra-ui/react";
import { RefObject, useRef } from "react";
interface PopoverAnimatedProps extends BoxProps {
  isOpen: boolean;
  onClose: any;
  portalContainerRef?: RefObject<HTMLElement>;
  openerRef?: RefObject<HTMLElement>;
}
export const PopoverAnimated = ({
  isOpen,
  onClose,
  portalContainerRef,
  openerRef,
  ...props
}: PopoverAnimatedProps) => {
  const popoverRef = useRef(null);
  useOutsideClick({
    ref: popoverRef,
    handler: ev => {
      if (openerRef?.current && openerRef.current.contains(ev.target as Node)) {
        return;
      }
      onClose();
    }
  });
  if (!isOpen) return null;
  return <Portal containerRef={portalContainerRef} data-sentry-element="Portal" data-sentry-component="PopoverAnimated" data-sentry-source-file="PopoverAnimated.tsx">
      <Collapse in={isOpen} transition={{
      enter: {
        duration: 0.1
      },
      exit: {
        duration: 0.1
      }
    }} animateOpacity data-sentry-element="Collapse" data-sentry-source-file="PopoverAnimated.tsx">
        <Box ref={popoverRef} p={2} bg="white" rounded="md" pos="absolute" shadow="lg" zIndex={120} {...props} data-sentry-element="Box" data-sentry-source-file="PopoverAnimated.tsx" />
      </Collapse>
    </Portal>;
};