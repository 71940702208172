import { Box, useTheme } from "@chakra-ui/react";
import { FooterPopoverNotificationItem, FooterPopoverTitle, CaseDefinitionCaseFragmentType } from ".";
import { IconCase } from "admin/components/common/IconCase";
import { useNavigationBetweenPopoverItems } from "admin/lib/useNavigationBetweenPopoverItems";
import { useAdminTranslation } from "admin/lib/useAdminTranslation";
interface FooterNavigationMessagesProps {
  records: CaseDefinitionCaseFragmentType[];
}
export const FooterPopoverMessages = ({
  records
}: FooterNavigationMessagesProps) => {
  const {
    icons: {
      IconMessages
    }
  } = useTheme();
  const {
    activeIdx
  } = useNavigationBetweenPopoverItems(records);
  const {
    t
  } = useAdminTranslation();
  return <Box data-sentry-element="Box" data-sentry-component="FooterPopoverMessages" data-sentry-source-file="FooterPopoverMessages.tsx">
      <FooterPopoverTitle icon={IconMessages} title={t("concepts.comments")} data-sentry-element="FooterPopoverTitle" data-sentry-source-file="FooterPopoverMessages.tsx" />

      <Box mt={4} data-sentry-element="Box" data-sentry-source-file="FooterPopoverMessages.tsx">
        {records.map((item, idx) => <FooterPopoverNotificationItem key={item.id} icon={<IconCase case={item} />} title={item.shortName} description={item.name} isActive={activeIdx === idx} href={`/next/admin/cases/${item.id}`} />)}
      </Box>
    </Box>;
};