import { useRouter } from "next/router";
import { Box, BoxProps, Button, Drawer, DrawerCloseButton, DrawerContent, DrawerOverlay, Flex, IconButton, Img, Link, Menu, MenuButton, MenuButtonProps, MenuItem, MenuList, Portal, Stack, Text, useBreakpointValue, useDisclosure } from "@chakra-ui/react";
import React, { ElementType, ReactElement, ReactNode, useEffect, useRef, useState } from "react";
import { ChevronLeftIcon, HamburgerIcon } from "@chakra-ui/icons";
import NextLink from "next/link";
export interface LayoutDocsProps {
  children: ReactNode;
  user?: any;
}
export const LayoutDocs = ({
  children,
  user
}: LayoutDocsProps) => {
  const router = useRouter();
  const isLarge = useBreakpointValue({
    base: false,
    lg: true
  });

  // close navigation when on mobile and navigating to a new page
  const {
    isOpen,
    onOpen,
    onClose
  } = useDisclosure();
  useEffect(() => {
    const handleRouteChange = () => isOpen ? onClose() : null;
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [isOpen, onClose, router.events]);
  if (isLarge) {
    return <Box>
        <Box position="fixed" top="0" left="0" h="100vh" w="250px" bg="white" overflow="auto" borderRight="1px solid" borderColor="gray.200">
          <MainMenu user={user} currentPath={router.asPath} />
        </Box>
        <Flex ml="250px" position="relative" minH="100vh" flexDir="column">
          {children}
        </Flex>
      </Box>;
  } else {
    return <>
        <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <MainMenu user={user} currentPath={router.asPath} />
          </DrawerContent>
        </Drawer>
        <Box position="relative">
          <Box py="1" px="3" position="absolute" top="0" left="0">
            <Button variant="link" size="sm" onClick={onOpen} iconSpacing="1" leftIcon={<ChevronLeftIcon />}>
              Navigation
            </Button>
          </Box>
          {children}
        </Box>
      </>;
  }
};
interface MainMenuSectionItemsProps {
  currentPath: string;
  basePath: string;
  items: any[];
  level?: number;
  [key: string]: any;
}
function MainMenuSectionItems({
  currentPath,
  basePath,
  items,
  level = 0,
  ...rest
}: MainMenuSectionItemsProps) {
  const [isOpen, setIsOpen] = useState<boolean | number>(false);
  if (!items?.length) return null;
  return <Stack spacing="0" mb="4" {...rest} data-sentry-element="Stack" data-sentry-component="MainMenuSectionItems" data-sentry-source-file="LayoutDocs.tsx">
      {items.map((item, i) => {
      const fullPath = `${basePath}${item.path}`;
      const isActive = currentPath.startsWith(fullPath);
      return <MainMenuSectionItem key={i} level={level} isOpen={i === isOpen} setIsOpen={bool => setIsOpen(bool ? i : false)} currentPath={currentPath} fullPath={fullPath} isActive={isActive} {...item} />;
    })}
    </Stack>;
}
interface MainMenuSectionItemProps {
  currentPath: string;
  fullPath: string;
  title: string;
  Icon?: ElementType | undefined;
  children: any[];
  level: number;
  render: ReactNode;
  isOpen: boolean;
  setIsOpen: (bool: boolean) => void;
  isActive: boolean;
}
function MainMenuSectionItem({
  currentPath,
  fullPath,
  title,
  Icon,
  children,
  render,
  level,
  isOpen,
  setIsOpen,
  isActive
}: MainMenuSectionItemProps) {
  let styles: any = {
    color: "black"
  };
  if (level === 1) styles = {
    fontWeight: "normal",
    color: "gray.600"
  };
  if (level === 2) styles = {
    fontWeight: "normal",
    color: "black"
  };
  if (render) {
    return <Box>{render}</Box>;
  } else if (children?.length) {
    return <Box>
        <Button variant="ghost" w="full" justifyContent="flex-start" leftIcon={Icon ? <Icon boxSize="4" color="gray.600" /> : undefined} onClick={() => setIsOpen(!isOpen)} px="3" {...styles}>
          {title}
        </Button>
        {isOpen ? <MainMenuSectionItems level={level + 1} basePath={fullPath} currentPath={currentPath} items={children} ml="4" /> : null}
      </Box>;
  } else {
    return <NextLink href={fullPath} passHref legacyBehavior>
        <Button as="a" variant="ghost" leftIcon={Icon ? <Icon boxSize="4" color="gray.600" /> : null} justifyContent="flex-start" px="3" {...styles} color={isActive ? "primary.600" : styles.color}>
          {title}
        </Button>
      </NextLink>;
  }
}
interface MainMenuSectionLinkGroupProps extends BoxProps {
  currentPath: string;
  title: string;
  links: any[];
}
function MainMenuSectionLinkGroup({
  currentPath,
  title,
  links,
  ...rest
}: MainMenuSectionLinkGroupProps) {
  return <Box {...rest} data-sentry-element="Box" data-sentry-component="MainMenuSectionLinkGroup" data-sentry-source-file="LayoutDocs.tsx">
      <Text textStyle="body-sm-strong" color="gray.400" mb="2" data-sentry-element="Text" data-sentry-source-file="LayoutDocs.tsx">
        {title}
      </Text>
      <Box data-sentry-element="Box" data-sentry-source-file="LayoutDocs.tsx">
        <Stack data-sentry-element="Stack" data-sentry-source-file="LayoutDocs.tsx">
          {links.map((l, i) => <Flex align="center" key={i} role="group">
              <NextLink href={l.path} passHref legacyBehavior>
                <Link color={currentPath.split("?")[0] == l.path ? "primary.600" : "black"} textStyle="body-md" flexGrow="1">
                  {l.title}
                </Link>
              </NextLink>
              {l.menu?.length ? <SimpleMenu variant="link" colorScheme="primary" visibility="hidden" _groupHover={{
            visibility: "visible"
          }} minW="auto" icon={<HamburgerIcon boxSize="5" />} items={l.menu} placement="right" /> : null}
              {l.badge}
            </Flex>)}
        </Stack>
      </Box>
    </Box>;
}
interface MainMenuProps {
  user: any;
  currentPath: string;
}
function MainMenu({
  user,
  currentPath
}: MainMenuProps) {
  const formRef = useRef<HTMLFormElement>(null);
  const graphqlLinks = [{
    title: "Introduction",
    path: `/docs/graphql/introduction`
  }, {
    title: "Authentication",
    path: `/docs/graphql/authentication`
  }, {
    title: "Explorer",
    path: `/docs/graphql/explorer`
  }];
  return <Flex direction="column" justifyContent="space-between" h="full" data-sentry-element="Flex" data-sentry-component="MainMenu" data-sentry-source-file="LayoutDocs.tsx">
      <Box p="4" flex="0 0 auto" data-sentry-element="Box" data-sentry-source-file="LayoutDocs.tsx">
        <NextLink href="/" passHref legacyBehavior data-sentry-element="NextLink" data-sentry-source-file="LayoutDocs.tsx">
          <a>
            <Img src="/images/logo-dark.svg" data-sentry-element="Img" data-sentry-source-file="LayoutDocs.tsx" />
          </a>
        </NextLink>
      </Box>

      <Box borderTop="1px solid" borderColor="gray.200" p="6" flex="1 1 0" overflow="auto" sx={{
      scrollbarWidth: "none",
      msOverflowStyle: "none",
      "::-webkit-scrollbar": {
        display: "none"
      }
    }} data-sentry-element="Box" data-sentry-source-file="LayoutDocs.tsx">
        <MainMenuSectionLinkGroup title="GraphQL API" links={graphqlLinks} currentPath={currentPath} mb="6" data-sentry-element="MainMenuSectionLinkGroup" data-sentry-source-file="LayoutDocs.tsx" />
      </Box>

      <Box borderTop="1px solid" borderColor="gray.200" p="6" data-sentry-element="Box" data-sentry-source-file="LayoutDocs.tsx">
        {user ? <>
            <Text textStyle="overline" color="gray.400" mb="1">
              {user.name}
            </Text>
            <Link color="black" onClick={() => {
          formRef.current?.submit?.();
        }}>
              Sign out
            </Link>
            <form ref={formRef} action={"/api/auth/destroy"} method={"post"}></form>
          </> : <NextLink href={`/authorize?returnTo=${currentPath}`} passHref legacyBehavior>
            <Link color="black">Sign in</Link>
          </NextLink>}
      </Box>
    </Flex>;
}
interface SimpleMenuProps extends MenuButtonProps {
  placement: "bottom" | "top" | "right" | "left";
  items: any[];
  icon?: ReactElement;
  children?: ReactNode;
  variant: string;
  colorScheme: string;
}
export default function SimpleMenu({
  placement,
  items = [],
  icon,
  children,
  ...rest
}: SimpleMenuProps) {
  const buttonProps = icon && !children ? {
    icon: icon,
    as: IconButton
  } : {
    leftIcon: icon,
    as: Button
  };
  return <Menu placement={placement} data-sentry-element="Menu" data-sentry-component="SimpleMenu" data-sentry-source-file="LayoutDocs.tsx">
      <MenuButton {...buttonProps} {...rest} data-sentry-element="MenuButton" data-sentry-source-file="LayoutDocs.tsx">
        {children}
      </MenuButton>
      <Portal data-sentry-element="Portal" data-sentry-source-file="LayoutDocs.tsx">
        <MenuList data-sentry-element="MenuList" data-sentry-source-file="LayoutDocs.tsx">
          {items.map(({
          label,
          ...item
        }, idx) => <MenuItem textStyle="bodyNormal" key={idx} {...item}>
              {label}
            </MenuItem>)}
        </MenuList>
      </Portal>
    </Menu>;
}