import { Input, InputElementProps, InputGroup, InputGroupProps, InputLeftElement, InputProps, InputRightElement, useTheme } from "@chakra-ui/react";
import { useAdminTranslation } from "admin/lib/useAdminTranslation";
import { ReactNode } from "react";
export const InputSearch = ({
  inputProps = {},
  leftElement,
  rightElement,
  rightElementProps,
  ...props
}: {
  leftElement?: ReactNode;
  rightElement?: ReactNode;
  rightElementProps?: InputElementProps;
  inputProps?: InputProps;
} & InputGroupProps) => {
  const {
    icons: {
      IconSearch
    }
  } = useTheme();
  const {
    t
  } = useAdminTranslation();
  return <InputGroup {...props} data-sentry-element="InputGroup" data-sentry-component="InputSearch" data-sentry-source-file="InputSearch.tsx">
      <InputLeftElement data-sentry-element="InputLeftElement" data-sentry-source-file="InputSearch.tsx">
        {leftElement || <IconSearch fontSize={props.size === "sm" ? 20 : 24} color="gray.500" />}
      </InputLeftElement>

      <Input placeholder={t("placeholders.search_in_concept", {
      concept: "ejsingholm"
    })} {...inputProps} data-sentry-element="Input" data-sentry-source-file="InputSearch.tsx" />

      {rightElement && <InputRightElement {...rightElementProps}>
          {rightElement}
        </InputRightElement>}
    </InputGroup>;
};