import { Modal, ModalContent, ModalOverlay } from "@chakra-ui/react";
interface ShortcutsModalProps {
  children: React.ReactNode;
  onClose: () => void;
}
export const ShortcutsModal = ({
  children,
  onClose
}: ShortcutsModalProps) => {
  return <Modal isOpen onClose={onClose} data-sentry-element="Modal" data-sentry-component="ShortcutsModal" data-sentry-source-file="ShortcutsModal.tsx">
      <ModalOverlay data-sentry-element="ModalOverlay" data-sentry-source-file="ShortcutsModal.tsx" />
      <ModalContent overflow="hidden" maxH={"80%"} padding={6} data-sentry-element="ModalContent" data-sentry-source-file="ShortcutsModal.tsx">
        {children}
      </ModalContent>
    </Modal>;
};