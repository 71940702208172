import { CurrentUserFragmentType } from "admin/lib/useCurrentUser";
import { ReactNode } from "react";
import { Navigation } from "./navigation/Navigation";
import { App, AppBody } from "made-this-ui";
interface LayoutAdminProps {
  children: ReactNode;
  user: CurrentUserFragmentType;
}
export const LayoutAdmin = ({
  children,
  user
}: LayoutAdminProps) => {
  return <App colorScheme="primary" data-sentry-element="App" data-sentry-component="LayoutAdmin" data-sentry-source-file="LayoutAdmin.tsx">
      <Navigation user={user} data-sentry-element="Navigation" data-sentry-source-file="LayoutAdmin.tsx" />
      <AppBody data-sentry-element="AppBody" data-sentry-source-file="LayoutAdmin.tsx">{children}</AppBody>
    </App>;
};