import { Box, Flex, Modal, ModalBody, ModalContent, ModalOverlay, useControllableState, useTheme } from "@chakra-ui/react";
import { EmptyContent } from "admin/components/common/EmptyContent";
import { GlobalSearchGroup } from "./GlobalSearchGroup";
import { GlobalSearchInput } from "./GlobalSearchInput";
import { useGlobalSearch } from "./useGlobalSearch";
import { RefObject, useEffect, useRef, useState } from "react";
import { KbdNavigation } from "admin/components/common/KbdNavigation";
import { useKeyPress } from "admin/lib/useKeyPress";
import { useAdminTranslation } from "admin/lib/useAdminTranslation";
import { useRouter } from "next/router";
export interface SearchNavigatorItem {
  id: any;
  type: "case" | "tenancy";
}
interface UseSearchNavigatorProps {
  items: SearchNavigatorItem[];
  onItemSelect: (item: SearchNavigatorItem) => void;
  scrollContainerRef: RefObject<HTMLDivElement>;
}
const useSearchNavigator = (props: UseSearchNavigatorProps) => {
  const [currentItemIndex, setCurrentItemIndex] = useState<number>(0);
  const currentItem = props.items[currentItemIndex];
  useKeyPress("ArrowDown", () => {
    if (currentItemIndex < props.items.length - 1) {
      setCurrentItemIndex(currentItemIndex + 1);
    }
  });
  useKeyPress("ArrowUp", () => {
    if (currentItemIndex > 0) {
      setCurrentItemIndex(currentItemIndex - 1);
    }
  });
  useKeyPress("Enter", () => {
    props.onItemSelect(currentItem);
  });
  const reset = () => {
    setCurrentItemIndex(0);
    return;
  };
  useEffect(() => {
    if (!currentItem) {
      return;
    }

    // Scroll Container
    const scrollContainer = props.scrollContainerRef.current;
    if (!scrollContainer) {
      return;
    }
    const querySelector = `#search-item-${currentItem.type}-${currentItem.id}`;

    // Current Item
    const currentItemElement = scrollContainer.querySelector(querySelector);
    if (!currentItemElement) {
      return;
    }
    currentItemElement.scrollIntoView(false);
  }, [currentItem, currentItemIndex, props.scrollContainerRef]);
  return {
    currentItem,
    reset
  };
};
export interface GlobalSearchModalProps {
  onClose: () => void;
}
export const GlobalSearchModal = ({
  onClose
}: GlobalSearchModalProps) => {
  const router = useRouter();
  const scrollContainer = useRef<HTMLDivElement>(null);
  const [value, setValue] = useControllableState({
    defaultValue: ""
  });
  const searchResult = useGlobalSearch(value);
  const {
    t,
    model
  } = useAdminTranslation();
  const {
    icons: {
      IconSearch
    }
  } = useTheme();
  const navigateToSearchItem = (item: SearchNavigatorItem) => {
    if (item.type === "case") {
      router.push(`/next/admin/cases/${item.id}`);
    } else if (item.type === "tenancy") {
      router.push(`/next/admin/tenancies/${item.id}`);
    }
  };
  const searchNavigator = useSearchNavigator({
    items: ([] as SearchNavigatorItem[]).concat(searchResult.tenants.records.map(record => ({
      type: "tenancy",
      id: record.id
    })), searchResult.cases.records.map(record => ({
      type: "case",
      id: record.id
    }))),
    onItemSelect: navigateToSearchItem,
    scrollContainerRef: scrollContainer
  });
  return <Modal isOpen onClose={onClose} data-sentry-element="Modal" data-sentry-component="GlobalSearchModal" data-sentry-source-file="GlobalSearchModal.tsx">
      <ModalOverlay data-sentry-element="ModalOverlay" data-sentry-source-file="GlobalSearchModal.tsx" />
      <ModalContent overflow="hidden" maxH={"80%"} data-sentry-element="ModalContent" data-sentry-source-file="GlobalSearchModal.tsx">
        <ModalBody as={Flex} flexDirection="column" p={0} overflow={"auto"} data-sentry-element="ModalBody" data-sentry-source-file="GlobalSearchModal.tsx">
          <GlobalSearchInput value={value} onChange={setValue} data-sentry-element="GlobalSearchInput" data-sentry-source-file="GlobalSearchModal.tsx" />
          <Box overflow={"auto"} h="100%" data-sentry-element="Box" data-sentry-source-file="GlobalSearchModal.tsx">
            {searchResult.totalCount === 0 && <EmptyContent icon={IconSearch} title={t("rails:application.no_results")} description={t("texts.try_other_search")} />}
            <Box py={6} px={1} overflow={"auto"} h="100%" ref={scrollContainer} data-sentry-element="Box" data-sentry-source-file="GlobalSearchModal.tsx">
              <GlobalSearchGroup type="tenancy" selectedItemId={searchNavigator.currentItem?.id} records={searchResult.tenants.records} title={model("tenancy.other")} navigateToSearchItem={navigateToSearchItem} data-sentry-element="GlobalSearchGroup" data-sentry-source-file="GlobalSearchModal.tsx" />
              <GlobalSearchGroup type="case" selectedItemId={searchNavigator.currentItem?.id} records={searchResult.cases.records} title={model("case.other")} navigateToSearchItem={navigateToSearchItem} data-sentry-element="GlobalSearchGroup" data-sentry-source-file="GlobalSearchModal.tsx" />
            </Box>
          </Box>
          {searchResult.totalCount > 0 && <Box>
              <KbdNavigation mt={4} pb={8} pr={3} />
            </Box>}
        </ModalBody>
      </ModalContent>
    </Modal>;
};