import { HStack, Kbd as ChakraKbd, KbdProps, Text, TextProps } from "@chakra-ui/react";
export const Kbd = ({
  label,
  textProps,
  ...props
}: {
  label?: string;
  textProps?: TextProps;
} & KbdProps) => {
  return <HStack spacing={1} data-sentry-element="HStack" data-sentry-component="Kbd" data-sentry-source-file="Kbd.tsx">
      <ChakraKbd {...props} data-sentry-element="ChakraKbd" data-sentry-source-file="Kbd.tsx" />
      {label && <Text color="gray.400" {...textProps}>
          {label}
        </Text>}
    </HStack>;
};