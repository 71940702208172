import { ShortcutsListShortcut } from "./useShortcutsList";
import { Box, HStack, Icon, IconButton, ListItem, Menu, MenuButton, MenuItem, MenuList, Stack, Text, useTheme } from "@chakra-ui/react";
import { useAdminTranslation } from "admin/lib/useAdminTranslation";
export interface ListItemShortcutProps {
  item: ShortcutsListShortcut;
  onItemClick?: (item: ShortcutsListShortcut) => void;
  onItemRemoveClick?: (item: ShortcutsListShortcut) => void;
  isActive?: boolean;
}
export const ListItemShortcut = ({
  onItemClick,
  onItemRemoveClick,
  item,
  isActive
}: ListItemShortcutProps) => {
  const {
    name,
    type
  } = item;
  const {
    icons: {
      IconMoreHorizontal,
      IconRemove
    },
    colors: {
      danger
    }
  } = useTheme();
  const {
    model,
    t
  } = useAdminTranslation();
  const handleItemCLicked = () => {
    onItemClick?.(item);
  };
  return <ListItem id={`shortcut-item-${item.id}`} as={HStack} px={3} py={2} cursor={"pointer"} borderRadius="sm" _hover={{
    bg: "primaryAlpha.200"
  }} bg={isActive ? "primaryAlpha.200" : "transparent"} role={"group"} onClick={handleItemCLicked} data-sentry-element="ListItem" data-sentry-component="ListItemShortcut" data-sentry-source-file="ListItemShortcut.tsx">
      <Stack flexGrow={1} spacing={"2px"} data-sentry-element="Stack" data-sentry-source-file="ListItemShortcut.tsx">
        <HStack data-sentry-element="HStack" data-sentry-source-file="ListItemShortcut.tsx">
          <Text textStyle={"body-md"} data-sentry-element="Text" data-sentry-source-file="ListItemShortcut.tsx">{name}</Text>
        </HStack>
        <Text textStyle={"body-xs"} color={"gray.400"} data-sentry-element="Text" data-sentry-source-file="ListItemShortcut.tsx">
          {model(type.slice(0), {
          count: 2
        })}
        </Text>
      </Stack>
      <Box onClick={e => e.stopPropagation()} data-sentry-element="Box" data-sentry-source-file="ListItemShortcut.tsx">
        <Menu data-sentry-element="Menu" data-sentry-source-file="ListItemShortcut.tsx">
          <MenuButton as={IconButton} variant={"unstyled"} icon={<IconMoreHorizontal />} data-sentry-element="MenuButton" data-sentry-source-file="ListItemShortcut.tsx" />
          <MenuList onClick={e => e.stopPropagation()} data-sentry-element="MenuList" data-sentry-source-file="ListItemShortcut.tsx">
            <MenuItem color={"danger"} as={HStack} spacing={2} w={"177px"} onClick={() => onItemRemoveClick?.(item)} data-sentry-element="MenuItem" data-sentry-source-file="ListItemShortcut.tsx">
              <Icon as={IconRemove} color={danger[600]} data-sentry-element="Icon" data-sentry-source-file="ListItemShortcut.tsx" />
              <Text color={danger[600]} data-sentry-element="Text" data-sentry-source-file="ListItemShortcut.tsx">
                {t("texts.shortcuts.remove_title")}
              </Text>
            </MenuItem>
          </MenuList>
        </Menu>
      </Box>
    </ListItem>;
};