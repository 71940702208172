import { Button, Flex, Spinner, Stack, Text, useToast } from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";
import { useAdminTranslation } from "admin/lib/useAdminTranslation";
import { FormButtonSubmit, FormFieldText } from "made-this-ui";
import { useEffect } from "react";
import { useShortcutPathMap } from "../useShortcutPathMap";
import { useShortcutControlQuery, useShortcutUpdateMutation } from ".";
export interface ShortcutUpdateProps {
  shortcutId: string;
  onShowNew: () => void;
  onComplete: () => void;
}
export const ShortcutUpdate = ({
  shortcutId,
  onShowNew,
  onComplete
}: ShortcutUpdateProps) => {
  const form = useForm({
    defaultValues: {
      name: "",
      id: null
    } as {
      name: string;
      id?: string | null;
    }
  });
  const {
    handleSubmit,
    setFocus,
    watch
  } = form;
  const toast = useToast();
  const {
    t
  } = useAdminTranslation();
  const {
    pathIdentifier,
    getState
  } = useShortcutPathMap();
  const {
    isLoading,
    isSuccess
  } = useShortcutControlQuery(shortcutId, {
    onComplete: data => {
      form.reset({
        id: data?.currentUser?.shortcut?.id || null,
        name: data?.currentUser?.shortcut?.name || ""
      });
    }
  });
  const id = watch("id");
  const {
    mutateAsync: shortcutUpdate
  } = useShortcutUpdateMutation();
  const onSubmit = async (data: {
    name: string;
  }) => {
    try {
      const res = await shortcutUpdate({
        shortcutId,
        attributes: {
          name: data.name,
          state: getState(),
          type: pathIdentifier
        }
      });
      if (res?.shortcutUpdate?.errors?.length) {
        console.error(res.shortcutUpdate.errors);
        toast({
          position: "top",
          status: "error",
          title: t("texts.shortcuts.save_failed_message")
        });
        return;
      }
      toast({
        position: "top",
        status: "success",
        title: t("texts.shortcuts.updated")
      });
      onComplete();
    } catch (e) {
      console.error(e);
      toast({
        position: "top",
        status: "error",
        title: t("texts.shortcuts.save_failed_message")
      });
    }
  };
  useEffect(() => {
    setFocus("name");
  }, [setFocus, isSuccess]);
  return <FormProvider {...form} data-sentry-element="FormProvider" data-sentry-component="ShortcutUpdate" data-sentry-source-file="ShortcutUpdate.tsx">
      <Stack as="form" onSubmit={handleSubmit(onSubmit)} spacing={4} data-sentry-element="Stack" data-sentry-source-file="ShortcutUpdate.tsx">
        <Text as="h1" textStyle="sub-md-strong" color="primary.800" data-sentry-element="Text" data-sentry-source-file="ShortcutUpdate.tsx">
          {t("texts.shortcuts.update_title")}
        </Text>
        {isLoading || !id || id.length == 0 ? <Flex justifyContent={"center"} alignItems={"center"} py={8}>
            <Spinner size={"xl"} />
          </Flex> : <>
            <FormFieldText isDisabled={isLoading} name="name" autoComplete="off" placeholder={t("texts.shortcuts.field_name")} />
            <FormButtonSubmit isDisabled={isLoading}>
              {t("texts.shortcuts.update_button")}
            </FormButtonSubmit>
            <Button onClick={onShowNew} isDisabled={isLoading} color={"primary.600"} variant={"link"}>
              {t("texts.shortcuts.update_button_create_new")}
            </Button>
          </>}
      </Stack>
    </FormProvider>;
};