import { Box, ComponentWithAs, IconProps, Popover, PopoverBody, PopoverContent, PopoverTrigger, Portal } from "@chakra-ui/react";
import { ReactNode } from "react";
import { KbdNavigation } from "admin/components/common/KbdNavigation";
import { useAppContext } from "made-this-ui";
import { ToolButton } from "../ToolButton";
export interface FooterMenuProps {
  icon: ComponentWithAs<"svg", IconProps>;
  label: string;
  notificationCount?: number;
  content: ReactNode;
}
export const FooterMenu = ({
  icon: Icon,
  label,
  content,
  notificationCount = 0
}: FooterMenuProps) => {
  const {
    isCollapsed
  } = useAppContext();
  if (isCollapsed && !notificationCount) return null;
  return <Popover isOpen={notificationCount === 0 ? false : undefined} placement="bottom-end" returnFocusOnClose={false} data-sentry-element="Popover" data-sentry-component="FooterMenu" data-sentry-source-file="FooterMenu.tsx">
      <PopoverTrigger data-sentry-element="PopoverTrigger" data-sentry-source-file="FooterMenu.tsx">
        <Box position="relative" data-sentry-element="Box" data-sentry-source-file="FooterMenu.tsx">
          <ToolButton icon={Icon} label={label} count={notificationCount ? notificationCount : undefined} data-sentry-element="ToolButton" data-sentry-source-file="FooterMenu.tsx" />
        </Box>
      </PopoverTrigger>
      <Portal data-sentry-element="Portal" data-sentry-source-file="FooterMenu.tsx">
        <PopoverContent w="500px" maxW="calc(100vw - 48px)" data-sentry-element="PopoverContent" data-sentry-source-file="FooterMenu.tsx">
          <PopoverBody p={6} bg="white" data-sentry-element="PopoverBody" data-sentry-source-file="FooterMenu.tsx">
            {content}
            <KbdNavigation mt={4} data-sentry-element="KbdNavigation" data-sentry-source-file="FooterMenu.tsx" />
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>;
};