import { Stack, Text } from "@chakra-ui/react";
export type GlobalSearchGroupItemItemType = {
  id: any;
  title: string;
  subTitle: string;
  helperText: string;
};
export interface GlobalSearchGroupItemProps {
  handleClick: () => void;
  isSelected?: boolean;
  item: GlobalSearchGroupItemItemType;
  id: string;
}
export const GlobalSearchGroupItem = ({
  handleClick,
  isSelected,
  item: {
    title,
    subTitle,
    helperText
  },
  id
}: GlobalSearchGroupItemProps) => {
  const bgColor = isSelected ? "primaryAlpha.100" : "transparent";
  return <Stack id={id} cursor={"pointer"} spacing={1} bg={bgColor} _hover={{
    bg: "primaryAlpha.100"
  }} borderRadius={4} py={2} px={3} onClick={handleClick} data-sentry-element="Stack" data-sentry-component="GlobalSearchGroupItem" data-sentry-source-file="GlobalSearchGroupItem.tsx">
      <Text textStyle={"body-sm-strong"} color={"gray.900"} data-sentry-element="Text" data-sentry-source-file="GlobalSearchGroupItem.tsx">
        {title}
      </Text>
      <Text textStyle={"body-sm"} color={"gray.900"} data-sentry-element="Text" data-sentry-source-file="GlobalSearchGroupItem.tsx">
        {subTitle}
      </Text>
      <Text textStyle={"body-xs"} color={"gray.400"} data-sentry-element="Text" data-sentry-source-file="GlobalSearchGroupItem.tsx">
        {helperText}
      </Text>
    </Stack>;
};