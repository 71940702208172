import { Avatar, Box, ComponentWithAs, Flex, FlexProps, IconProps, useTheme } from "@chakra-ui/react";
import { useAdminTranslation } from "admin/lib/useAdminTranslation";
import { CurrentUserFragmentType } from "admin/lib/useCurrentUser";
import { AppNavigation, AppNavigationBody, AppNavigationFooter, AppNavigationHeader, AppNavigationItem, AppNavigationSubItem, useAppContext } from "made-this-ui";
import { useRouter } from "next/router";
import { useMemo } from "react";
import { FooterActions } from "./FooterActions";
import { GlobalSearch } from "./GlobalSearch";
import { Shortcuts } from "./Shortcuts";
const getSidebarMenu = (icons: {
  [key: string]: ComponentWithAs<"svg", IconProps>;
}) => [{
  id: 0,
  href: "/next/admin/cases",
  title: "case",
  icon: icons.IconCases
}, {
  id: 1,
  href: "/next/admin/tenancies",
  title: "tenancy",
  icon: icons.IconTenant
}, {
  id: 2,
  href: "/next/admin/apartments",
  title: "apartment",
  icon: icons.IconApartment
}, {
  id: 3,
  href: "/next/admin/properties",
  title: "property",
  icon: icons.IconProperty
}];
const moreMenu = [{
  id: 0,
  href: "/admin/cases/my",
  title: "rails:cases.shared.calendar"
}, {
  id: 1,
  href: "/admin/interested",
  model: "interest_list_entry"
}, {
  id: 2,
  href: "/admin/timelogs",
  model: "timelog"
}, {
  id: 3,
  href: "/admin/messages",
  model: "message"
}, {
  id: 4,
  href: "/admin/questions",
  model: "question"
}, {
  id: 5,
  href: "/admin/documents",
  model: "document"
}, {
  id: 6,
  href: "/admin/users",
  model: "user"
}, {
  id: 7,
  href: "/admin/reports/leased",
  title: "rails:reports.title.other"
}, {
  id: 8,
  href: "/admin/accounting",
  title: "rails:accounting.title"
}, {
  id: 9,
  href: "/admin/configuration/case_message_rules",
  title: "rails:application.configuration"
}, {
  id: 10,
  href: "/home/profile",
  title: "rails:users.profile"
}];
interface NavigationProps extends FlexProps {
  user: CurrentUserFragmentType;
}
export const Navigation = ({
  user
}: NavigationProps) => {
  const {
    icons: {
      IconCases,
      IconTenant,
      IconApartment,
      IconProperty,
      IconLogoFull,
      IconMoreHorizontal
    }
  } = useTheme();
  const {
    isCollapsed
  } = useAppContext();
  const {
    t,
    model
  } = useAdminTranslation();
  const router = useRouter();
  const isPath = (path: string) => router.pathname.startsWith(path);
  const sidebarMenu = useMemo(() => {
    return getSidebarMenu({
      IconCases,
      IconTenant,
      IconApartment,
      IconProperty
    });
  }, [IconProperty, IconCases, IconTenant, IconApartment]);
  return <AppNavigation backgroundColor={"primary.500"} data-sentry-element="AppNavigation" data-sentry-component="Navigation" data-sentry-source-file="Navigation.tsx">
      <AppNavigationHeader title={user.name} avatar={<Avatar name={user.name} backgroundColor="gray.800" color="white" w={9} h={9} />} data-sentry-element="AppNavigationHeader" data-sentry-source-file="Navigation.tsx">
        <Flex px={isCollapsed ? 0 : 3} pb={3} flexDirection={isCollapsed ? "column" : "row"} justifyContent="space-between" alignItems={"center"} borderBottomWidth={1} borderBottomColor={"primary.600"} data-sentry-element="Flex" data-sentry-source-file="Navigation.tsx">
          <FooterActions data-sentry-element="FooterActions" data-sentry-source-file="Navigation.tsx" />
          <GlobalSearch data-sentry-element="GlobalSearch" data-sentry-source-file="Navigation.tsx" />
          <Shortcuts data-sentry-element="Shortcuts" data-sentry-source-file="Navigation.tsx" />
        </Flex>
      </AppNavigationHeader>
      <AppNavigationBody data-sentry-element="AppNavigationBody" data-sentry-source-file="Navigation.tsx">
        <Box h="2" data-sentry-element="Box" data-sentry-source-file="Navigation.tsx" />

        {sidebarMenu.map(({
        id,
        href,
        title,
        icon
      }) => {
        return <AppNavigationItem key={id} label={model(title, {
          count: 2
        })} isActive={isPath(href)} href={href} icon={icon} />;
      })}

        <AppNavigationItem label={t("concepts.more")} icon={IconMoreHorizontal} data-sentry-element="AppNavigationItem" data-sentry-source-file="Navigation.tsx">
          {moreMenu.map(({
          id,
          href,
          ...item
        }) => <AppNavigationSubItem key={id} onClick={() => {
          window.location.href = href;
        }}>
              {item.model ? model(item.model, {
            count: 2
          }) : item.title ? t(item.title) : ""}
            </AppNavigationSubItem>)}
        </AppNavigationItem>
      </AppNavigationBody>
      <AppNavigationFooter logo={<IconLogoFull color="gray.800" h={30} w={"auto"} />} data-sentry-element="AppNavigationFooter" data-sentry-source-file="Navigation.tsx" />
    </AppNavigation>;
};