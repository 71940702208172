import { Box, BoxProps, Center, Icon, IconButton } from "@chakra-ui/react";
export type ToolButtonProps = BoxProps & {
  label: string;
  icon: typeof Icon;
  count?: number;
};
export function ToolButton({
  label,
  icon: Icon,
  count,
  ...rest
}: ToolButtonProps) {
  return <Box role="group" {...rest} position="relative" data-sentry-element="Box" data-sentry-component="ToolButton" data-sentry-source-file="ToolButton.tsx">
      <IconButton aria-label={label} icon={<Icon boxSize="5" color="white" />} variant="ghost" _groupHover={{
      bg: "primary.400"
    }} data-sentry-element="IconButton" data-sentry-source-file="ToolButton.tsx" />

      {count !== undefined && <Center rounded="sm" color="white" bg="warning.500" fontSize="10.5px" border="1px solid" borderColor="primary.50" cursor="pointer" pos="absolute" bottom={0} right={0} w="18px" h="18px" borderRadius="50%">
          {count}
        </Center>}
    </Box>;
}