import { Box, useTheme } from "@chakra-ui/react";
import { FooterPopoverNotificationItem } from "./FooterPopoverNotificationItem";
import { FooterPopoverTitle } from "./FooterPopoverTitle";
import { CaseDefinitionCaseFragmentType } from "./useFooterActionsData";
import { IconCase } from "admin/components/common/IconCase";
import { useNavigationBetweenPopoverItems } from "admin/lib/useNavigationBetweenPopoverItems";
import { useAdminTranslation } from "admin/lib/useAdminTranslation";
interface HeaderPopoverInboxProps {
  records: CaseDefinitionCaseFragmentType[];
}
export const FooterPopoverInbox = ({
  records
}: HeaderPopoverInboxProps) => {
  const {
    icons: {
      IconInbox
    }
  } = useTheme();
  const {
    activeIdx
  } = useNavigationBetweenPopoverItems(records);
  const {
    t
  } = useAdminTranslation();
  return <Box data-sentry-element="Box" data-sentry-component="FooterPopoverInbox" data-sentry-source-file="FooterPopoverInbox.tsx">
      <FooterPopoverTitle icon={IconInbox} title={t("concepts.inbox")} data-sentry-element="FooterPopoverTitle" data-sentry-source-file="FooterPopoverInbox.tsx" />

      <Box mt={4} data-sentry-element="Box" data-sentry-source-file="FooterPopoverInbox.tsx">
        {records.map((item, idx) => <FooterPopoverNotificationItem key={item.id} icon={<IconCase case={item} />} title={item.shortName} description={item.name} isActive={activeIdx === idx} href={`/next/admin/cases/${item.id}`} />)}
      </Box>
    </Box>;
};