import { Center, CircularProgress } from "@chakra-ui/react";
import { Bootstrap, MadeThisNextPage } from "made-this-ui";
import { AppProps } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import * as Sentry from "@sentry/nextjs";
import themeHome from "home/lib/theme";
import themeAdmin from "admin/lib/theme";
import { LayoutAdmin } from "./layouts/LayoutAdmin";
import { getFragmentData } from "admin/graphql/generated";
import { CurrentUserFragment, CurrentUserFragmentType, useCurrentUserQuery } from "admin/lib/useCurrentUser";
import { IframeModalManagerProvider } from "./common/IframeModalManager";
function AdminApp({
  Component,
  pageProps
}: AppProps) {
  const {
    pathname
  } = useRouter();
  const isAuthorizePath = pathname.startsWith("/authorize");
  const theme = isAuthorizePath ? themeHome : themeAdmin;
  return <Bootstrap theme={theme} graphqlOptions={{
    endpoint: "/admin/graphql/admin"
  }} data-sentry-element="Bootstrap" data-sentry-component="AdminApp" data-sentry-source-file="AdminApp.tsx">
      <Head data-sentry-element="Head" data-sentry-source-file="AdminApp.tsx">
        <title>Ejsingholm</title>
      </Head>
      <IframeModalManagerProvider data-sentry-element="IframeModalManagerProvider" data-sentry-source-file="AdminApp.tsx">
        {isAuthorizePath ? <Component {...pageProps} /> : <DataLoader Component={Component} pageProps={pageProps} />}
      </IframeModalManagerProvider>
    </Bootstrap>;
}
interface DataLoaderProps {
  Component: MadeThisNextPage<{
    user?: CurrentUserFragmentType;
  }>;
  pageProps: any;
}
function DataLoader({
  Component,
  pageProps
}: DataLoaderProps) {
  const currentUserQuery = useCurrentUserQuery();
  const currentUser = getFragmentData(CurrentUserFragment, currentUserQuery.data?.currentUser);
  if (currentUserQuery.isLoading) {
    return <Center h="100vh">
        <CircularProgress color="primary.400" isIndeterminate />
      </Center>;
  }
  if (!currentUser) {
    return <AppRedirect path="/authorize" includeReturnTo />;
  }
  Sentry.setUser({
    id: currentUser.id
  });
  return <LayoutAdmin user={currentUser} data-sentry-element="LayoutAdmin" data-sentry-component="DataLoader" data-sentry-source-file="AdminApp.tsx">
      <Component {...pageProps} user={currentUser} data-sentry-element="Component" data-sentry-source-file="AdminApp.tsx" />
    </LayoutAdmin>;
}
interface AppRedirectProps {
  path: string;
  includeReturnTo?: boolean;
}
function AppRedirect(props: AppRedirectProps) {
  const router = useRouter();
  let path = props.path;
  if (props.includeReturnTo) {
    const returnTo = router.asPath;
    path = `${path}?returnTo=${encodeURIComponent(returnTo)}`;
  }
  router.push(path);
  return null;
}
export default AdminApp;